import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import CommonText from './CommonText'
import ColorButton from './ColorButton'
import Modal from './Modal'
import { rgba } from 'polished'
import color from '../../style/color'
import { useAppContext } from '../../context/AppContext'
import DateInput from './DateInput'
import { AGE_REQUIREMENT_MS } from '../../constants'
import CheckBox from './CheckBox'
import ReactHtmlParser from 'react-html-parser'

const ConsentModal = ({ handleConsentAccept, handleConsentDeny }) => {
  const { t } = useAppContext()

  return (
    <Container>
      <CommonText color="white" fontSize="md" lowercase>
        {ReactHtmlParser(t('consent_agree'))}
      </CommonText>
      <ColorButton delay={1.2} onSubmit={handleConsentAccept} label="consent_accept" />
      <ColorButton delay={1.2} onSubmit={handleConsentDeny} label="consent_deny" buttonColor={color.black} />
    </Container>
  )
}

ConsentModal.propTypes = {
  handleConsentAccept: PropTypes.func,
  handleConsentDeny: PropTypes.func,
}

export default ConsentModal

const Container = styled(Modal)`
  position: absolute;
  width: 90%;
  height: calc(100% - 140px);
  padding: 20px;
  z-index: 99999999;
  background: ${rgba(color.darkGray, 0.9)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: auto;

  ${CommonText} {
    a {
      display: inline;
      color: ${color.blue};
      text-decoration: underline;
      white-space: nowrap;
    }
  }

  > *:last-child {
    width: 80%;
    height: 40px;
  }

  > * {
    margin-bottom: 25px;
  }
`
