export const TRANSLATIONS_JP = {
  browser_change: 'この体験はSafariでの使用が最適です',
  your_journey: '読み込み中',
  is_loading: '',
  consent: 'この体験には、保護者の方の \n サポートが必要となりますので、\n 年齢を教えてください。',
  consent_accept_age: '確認する',
  consent_accept: '同意する',
  consent_deny: '同意しない',
  consent_agree:
    '自分と子供の映像が記録され、\n ニンジャゴークエストを紹介する \n 映像を作成するために使用することに \n 同意します。この映像に子供が映っている \n 場合、私はそのこどもの親権者であることに \n 同意します。\n <a href="/legal-jp.html" target="_blank">詳細はこちらをご覧ください。</a>',
  consent_deny_age_page: '申し訳ありませんが、保護者の方の \n サポートが必要です。',
  consent_deny_consent_page:
    'この体験では、お客様のデジタルコンテンツを作成するために、映像を録画・保存する必要があります。<a href="https://www.ninjago.com" target="_blank">Ninjago.com</a>にアクセスしてください。',
  terms_service:
    'クリックすると、<br/> <a href="https://www.lego.com/ja-jp/page/terms-and-conditions" target="_blank">「利用規約」</a> <a href="https://www.lego.com/ja-jp/legal/notices-and-policies/privacy-policy/" target="_blank">「プライバシーポリシー」</a><br/><a href="https://www.lego.com/ja-jp/cookie-policy" target="_blank">「クッキーポリシー」</a><br/>に同意したことになります。',
  rotate_mobile: '端末を回転させてください',
  intro: {
    header: {
      ninjago: 'ニンジャゴークエスト',
      title: 'ウー先生\nゴールデンアミュレット',
    },
    content: [
      '邪悪なガーマドンはウー先生のゴールデンアミュレットを6つに砕き、世界中の色々な場所に隠してしまった！',
      'この砕かれた黄金のかけらを取りもどし、ゴールデンアミュレットを作り直して、ニンジャゴーレガシーを守るために、ウー先生はキミの助けを必要としています',
      'これがきみのクエストだ',
    ],
  },
  runtime_error: 'エラー',
  no_virtue_text: '別の背景に移動し、最後の3つの「黄金のかけら」を見つける。',
  retake: 'もう一度試す',
  done: '続く',
  finish_early: 'いいえ結構です。終了します。',
  finish_early_drag: 'ドラッグして終了',
  success: '成功！',
  quest_complete: 'クエスト完了！',
  success_text: '次の黄金のかけらを探しに行こう！',
  quest_complete_text:
    'おめでとう！きみは黄金のアミュレットのかけらをすべて集めて、クエストをみごとに完了したよ！<br/><br/>動画を受け取るには下のボタンを押してダウンロードしてね。',
  play_again: 'もう一度プレイしますか？',
  start_over: '最初に戻って、プレイヤーを変えたり、\n 新しいポーズを考えてからスタートしよう！',
  processing: 'アップロード',
  processing_video: 'きみの動画を作っています',
  skip_sure: '本当に終了しますか？',
  skip_text: 'まだ黄金のかけらを全部見つけていません！ウー先生はまだきみの助けを必要としているよ！',
  cancel: 'キャンセル',
  tap: 'タップ ',
  download_video: ' して動画をダウンロード',
  home_small_title: 'ニンジャゴークエスト',
  home_main_title: 'ウー先生 \n ゴールデンアミュレット',
  desktop_small_title:
    'ニンジャゴーのクエストのバーチャル体験は、モバイル向けにデザインされています。お近くのレゴランド®・ディスカバリー・センターでご参加ください。',
  desktop_main_title: '',
  intro_title: 'ウー先生ゴールデンアミュレット',
  guide_title: '黄金のかけらを見つけよう！',
  guide_text:
    '保護者の方やお友達、仲間のニンジャと一緒に、スクリーンに現れるニンジャと写真をとって、それぞれの能力の黄金のかけらを見つけよう！',
  guide_luck: '',
  camera_access: '次に進むにはカメラへのアクセスを許可してください！',
  ar_instruction: 'カメラを各シーンに向けて、黄金のかけらを見つけよう！',
  tap_start: '長押ししてスタートする',
  virtue_texts: {
    kai: ['キミはいつも勇敢だ。カイの勇気に刺激を与えて、あらゆる邪悪な者たちと戦おう！'],
    lloyd: ['左を見て、右を見て、さあよく考えて――レッツゴー！'],
    nya: ['上を見て、下を見て、さあ \n ダンスを楽しもう！'],
    jay: ['良いニンジャは正直者。\n 「正しいことをすることを誓います！」と言いながら \n おじぎをしよう！'],
    cole: ['コールの技を身に付けるには、柱のようにまっすぐ立つんだ。黄金のかけらがゴールだよ'],
    zane: ['友達をダンスに誘って \n 踊ろう！一緒に参加しないと \n 前に進めないよ！'],
  },
  endlink: '<a href="https://www.ninjago.com" target="_blank">Ninjago.com</a>で修行の旅を続けよう',
  text_direction: 'ltr',
}
