import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppContext } from '../../context/AppContext'
import { HexButtons } from '../common/HexagonButton'
import FooterLabelButton from '../common/FooterLabelButton'
import {
  AR_VIEW,
  CAPTURE_PROGRESS,
  HOME,
  NAVIGATIONS,
  PLAY_AGAIN,
  PREVIEW,
  FINISH_EARLY,
  PROCESSING,
  MODELS,
  SUBMITTING_SEGMENT,
  GUIDE,
  INTRO,
  IS_LDC,
  CAMERA_ACCESS,
} from '../../constants'
import useCapture from '../../hooks/useCapture'
import { MEDIA_RECORDER_PREVIEW_CLOSED } from '../../utils/AR/mediaRecorder'
import { AnimatePresence, motion } from 'framer-motion'
import useNavigation from '../../hooks/useNavigation'
import HomeLockup from './HomeLockup'
import { fadeIn, footer, hexPosition } from '../../style/variants'
import assets from '../../constants/assets'
import { ACTION_HEX_BUTTON_FOOTER, ACTION_LABEL_BUTTON_FOOTER, CATEGORY_PREFIX } from '../../constants/analytics'
import analytics from '../../utils/ga'
import { ReactComponent as Logo8ThWall } from '../../assets/images/8thwall.svg'

const { footerBG, ldcLogo, legolandLogo, legolandDeLogo } = assets.images

const Footer = () => {
  const [buttonDelay, setButtonDelay] = useState(false)
  const { t, currentLanguage, navBtnVisible, customNavBtn, footerLabelBtnVisible, userConsent } = useAppContext()
  const { curViewID, goToNext, goTo } = useNavigation()
  const { currentSegment, totalProgress, resetCapture, userFinalVideoSrc, deleteLocalState } = useCapture()
  const location = useLocation()
  const history = useHistory()
  const enableReCapture = useCallback(() => {
    resetCapture(true)
  }, [resetCapture])

  useEffect(() => {
    window.addEventListener(MEDIA_RECORDER_PREVIEW_CLOSED, enableReCapture)
    return () => {
      window.removeEventListener(MEDIA_RECORDER_PREVIEW_CLOSED, enableReCapture)
    }
  }, [enableReCapture])

  useEffect(() => {
    setButtonDelay(false) //Have to set this to false so framer doesn't re-use the previous delay for the button
  }, [location])

  // Listen whenever view changes
  useEffect(() => {
    if (curViewID === null || curViewID === -1) return
    switch (curViewID) {
      case HOME:
        setButtonDelay(2.5)
        break
      case INTRO:
        setButtonDelay(0)
        break
      case CAPTURE_PROGRESS:
      case PLAY_AGAIN:
        setButtonDelay(2)
        break
      case GUIDE:
        setButtonDelay(1.5)
        break
      default:
        setButtonDelay(0)
    }
  }, [curViewID, userConsent])

  // Listener for hexagon button click

  const handleHexagonBtnClick = useCallback(() => {
    if (!userConsent) return
    if (userConsent === 1 && curViewID >= CAMERA_ACCESS) return
    const trackingEvent = {
      action: ACTION_HEX_BUTTON_FOOTER,
      eventCategory: `${NAVIGATIONS[curViewID].ga_category}`,
    }
    switch (curViewID) {
      case HOME:
      case INTRO:
      case GUIDE:
      case CAMERA_ACCESS:
        setButtonDelay(false)
        goToNext(history)
        if (curViewID === CAMERA_ACCESS) trackingEvent.eventLabel = 'Allow Camera'
        break
      case AR_VIEW:
        if (currentSegment) {
          setButtonDelay(false)
          goTo(history, SUBMITTING_SEGMENT)
        }
        break
      case CAPTURE_PROGRESS:
        setButtonDelay(false)
        trackingEvent.eventLabel = `${totalProgress} / ${MODELS.length} Captured`
        if (totalProgress === MODELS.length) goTo(history, PROCESSING)
        else goTo(history, AR_VIEW)
        break

      case PLAY_AGAIN:
        setButtonDelay(false)
        goTo(history, HOME)
        deleteLocalState()
        trackingEvent.eventLabel = 'Restart Experience'
        break
      case PREVIEW:
        trackingEvent.eventLabel = 'Download Video'
        break
    }

    analytics.trackEvent(trackingEvent)
  }, [curViewID, currentSegment, deleteLocalState, goTo, goToNext, history, totalProgress, userConsent])

  const handleFooterLabelButtonClick = useCallback(() => {
    //Any other scenarios here?

    const trackingEvent = {
      action: ACTION_LABEL_BUTTON_FOOTER,
      eventCategory: NAVIGATIONS[curViewID].ga_category,
    }
    analytics.trackEvent(trackingEvent)

    switch (curViewID) {
      case AR_VIEW:
        enableReCapture()
        break
      case CAPTURE_PROGRESS:
        goTo(history, FINISH_EARLY)
        setButtonDelay(false)
        break
      case PREVIEW:
        goTo(history, PLAY_AGAIN)
        setButtonDelay(false)
        break
    }
  }, [curViewID, goTo, history, enableReCapture])

  const hexagonButtonProps = useMemo(() => {
    return {
      ...(curViewID === PREVIEW && { buttonAs: 'a' }),
      ...(curViewID === PREVIEW &&
        userFinalVideoSrc && {
          href: userFinalVideoSrc,
          download: `ninjago-ar-video-${parseInt(Math.random() * 10000)}.mp4`,
        }),
      onClick: customNavBtn?.onClick
        ? customNavBtn.onClick
        : curViewID === CAMERA_ACCESS
        ? null
        : handleHexagonBtnClick,
    }
  }, [curViewID, handleHexagonBtnClick, customNavBtn, userFinalVideoSrc])

  const labelButtonProps = {
    onClick: handleFooterLabelButtonClick,
  }

  const { footer_visible, footer_btn, footer_label_btn, footer_label_btn_size } = useMemo(() => {
    return NAVIGATIONS[curViewID] || {}
  }, [curViewID])

  const HexButton = useMemo(() => {
    if (customNavBtn) return HexButtons[customNavBtn.type]
    return HexButtons[footer_btn]
  }, [footer_btn, customNavBtn])

  const hexButtonVariant = useMemo(() => {
    let variant = footer_visible
    if (curViewID === HOME) variant = 'home'
    return variant
  }, [curViewID, footer_visible])

  // Listener for anywhere tap
  const handleAnywhereClick = useCallback(() => {
    if (curViewID === CAMERA_ACCESS) handleHexagonBtnClick()
  }, [curViewID, handleHexagonBtnClick])

  // Add anywhere tap listener
  useEffect(() => {
    window.addEventListener('click', handleAnywhereClick)

    return () => {
      window.removeEventListener('click', handleAnywhereClick)
    }
  }, [handleAnywhereClick])

  return (
    <Container>
      <FooterBackgroundContainer variants={footer} initial="hidden" animate={footer_visible}>
        <FooterBackground src={footerBG} />
      </FooterBackgroundContainer>
      <AnimatePresence>{curViewID === HOME && <HomeLockup key="homeLockup" />}</AnimatePresence>
      <AnimatePresence>
        {navBtnVisible && HexButton && (curViewID === PREVIEW || buttonDelay !== false) && (
          <HexButtonContainer variants={hexPosition} initial="full" animate={hexButtonVariant}>
            <HexButton
              key={`${footer_btn}-${curViewID}`}
              {...hexagonButtonProps}
              delay={buttonDelay ? buttonDelay : 0}
              exitDuration={0.15}
            />
          </HexButtonContainer>
        )}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        {footerLabelBtnVisible && footer_label_btn && (curViewID === PREVIEW || buttonDelay !== false) && (
          <FooterLabelButton
            key={footer_label_btn}
            {...labelButtonProps}
            size={footer_label_btn_size}
            fontSize={curViewID === PREVIEW ? 'sm' : 'xxs'}
            delay={buttonDelay ? buttonDelay : 0 + 0.1}
          >
            {t(footer_label_btn)}
          </FooterLabelButton>
        )}
      </AnimatePresence>
      <LegoLand
        src={IS_LDC ? ldcLogo : legolandLogo}
        variants={fadeIn}
        initial="initial"
        animate={curViewID !== AR_VIEW ? 'animate' : 'initial'}
      />
      {curViewID === HOME && <StyledLogo8ThWall />}
    </Container>
  )
}

export default memo(Footer)

const LegoLand = styled(motion.img)(
  () => css`
    position: absolute;
    width: 20%;
    height: auto;
    bottom: 15px;
    z-index: 1001;
    left: 10px;
  `,
)

const StyledLogo8ThWall = styled(Logo8ThWall)(
  () => css`
    position: absolute;
    width: 20%;
    height: auto;
    bottom: 5px;
    z-index: 1001;
    right: 10px;
  `,
)

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--footer-height);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1006;
  width: 100%;
`

const HexButtonContainer = styled(motion.div)(
  ({ short }) => css`
    position: absolute;
    z-index: 1005;
  `,
)

const FooterBackgroundContainer = styled(motion.div)(
  () => css`
    position: absolute;
    top: 0;
    left: 0;
    right: -1px;
    z-index: 1000;
  `,
)
const FooterBackground = styled.img`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  z-index: 1000;
`
