import * as THREE from 'three'
import times from 'lodash/times'
import { loadTexture } from '../loadAssetManager'

class snowParticleSystem {
  constructor() {
    this.pointMaterials = []
    this.pointMeshes = []
    this.particlePaths = [
      './assets/textures/snowflake1.png',
      './assets/textures/snowflake2.png',
      './assets/textures/snowflake3.png',
      './assets/textures/snowflake4.png',
      './assets/textures/snowflake5.png',
    ]
    this.particleCountPerTexture = 300
  }

  /**
   * Load assets
   * @returns loading promise
   */
  loadAssets = () => {
    return this.particlePaths.map((path) => loadTexture(path))
  }

  /**
   * Initialize
   */
  init = ({ scene, camera, character }) => {
    this.scene = scene
    this.camera = camera
    this.character = character
    this.parameters = [
      [[1.0, 1.0, 1.0], this.particleTextures[1], 1],
      [[1.0, 1.0, 1.0], this.particleTextures[2], 0.5],
      [[1.0, 1.0, 1.0], this.particleTextures[0], 0.8],
      [[1.0, 1.0, 1.0], this.particleTextures[3], 0.5],
      [[1.0, 1.0, 1.0], this.particleTextures[4], 0.6],
    ]

    this.pointGeometry = new THREE.BufferGeometry()
    this.vertices = []

    times(this.particleCountPerTexture, (i) => {
      const x = Math.random() * 20 - 10
      const y = Math.random() * 20 - 10
      const z = Math.random() * 20 - 10

      this.vertices.push(x, y, z)
    })

    this.pointGeometry.setAttribute('position', new THREE.Float32BufferAttribute(this.vertices, 3))

    this.parameters.forEach((parameter, i) => {
      const color = parameter[0]
      const sprite = parameter[1]
      const size = parameter[2]

      this.pointMaterials[i] = new THREE.PointsMaterial({
        size: size,
        map: sprite,
        blending: THREE.CustomBlending,
        depthTest: false,
        transparent: true,
      })
      this.pointMaterials[i].color.setHSL(color[0], color[1], color[2])

      this.pointMeshes[i] = new THREE.Points(this.pointGeometry, this.pointMaterials[i])

      this.pointMeshes[i].rotation.x = Math.random() * 6
      this.pointMeshes[i].rotation.y = Math.random() * 6
      this.pointMeshes[i].rotation.z = Math.random() * 6

      this.character.add(this.pointMeshes[i])
    })
  }

  /**
   * Update particles
   * @param timeElapsed time consumed for frame rendering
   */
  updateParticles = (timeElapsed) => {
    const time = Date.now() * 0.00005

    this.pointMeshes.forEach((pointMesh, i) => {
      pointMesh.rotation.y = time * (i < 4 ? i + 1 : -(i + 1))
    })

    this.pointMaterials.forEach((pointMaterial, i) => {
      const color = this.parameters[i][0]
      const h = ((360 * (color[0] + time)) % 360) / 360
      pointMaterial.color.setHSL(h, color[1], color[2])
    })
  }

  /**
   * Update
   * @param timeElapsed time consumed for frame rendering
   */
  update = (timeElapsed) => {
    this.updateParticles(timeElapsed)
  }

  /**
   * Dispose
   */
  dispose = () => {
    if (!this.pointMeshes.length) return

    this.pointGeometry.dispose()
    times(this.particlePaths.length, (i) => {
      this.pointMaterials[i].dispose()
      this.particleTextures[i].dispose()
      this.character.remove(this.pointMeshes[i])
    })

    this.pointMaterials = []
    this.pointMeshes = []
  }
}

export default snowParticleSystem
