import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import CommonText from '../common/CommonText'
import Container from '../common/ContentContainer'
import { HOME } from '../../constants'
import Modal from '../common/Modal'
import { downTop } from '../../style/variants'
import { AnimatePresence } from 'framer-motion'
import { KUNOICHI } from '../../style/type'
import color from '../../style/color'

const Home = () => {
  const { t, setFooterLabelBtnVisible } = useAppContext()


  useEffect(() => {
    setFooterLabelBtnVisible(true)
  }, [setFooterLabelBtnVisible])


  return (
    <Content key={`section_${HOME}`}>

    </Content>
  )
}

export default memo(Home)

const Content = styled(Container)`
  justify-content: flex-start;
`
