import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactFlagsSelect from 'react-flags-select'
import i18n from 'i18next'
import { LANG_OPTIONS, LANG_COUNTRIES, LANG_LABELS } from '../../constants'
import { CHALET_NEWYORK_SIXTY_NAME } from '../../style/type'

const LanguageSelector = ({ className, ...rest }) => {
  const [country, setCountry] = useState(LANG_COUNTRIES.find((key) => LANG_OPTIONS[key].lang_code === i18n.language))
  // Listener for language option selection
  const handleOnSelect = (code) => {
    setCountry(code)
    const landCode = LANG_OPTIONS[code].lang_code
    i18n.changeLanguage(landCode)
    sessionStorage.setItem('userLang', JSON.stringify(landCode))
  }

  return (
    <Container className={className} {...rest}>
      <StyledReactFlagsSelect
        countries={LANG_COUNTRIES}
        customLabels={LANG_LABELS}
        selected={country}
        onSelect={handleOnSelect}
      />
    </Container>
  )
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
}

export default memo(LanguageSelector)

const Container = styled.div`
  position: relative;
  z-index: 9999999999;
`

const StyledReactFlagsSelect = styled(ReactFlagsSelect)`
  width: 120px;
  font-family: ${CHALET_NEWYORK_SIXTY_NAME}, sans-serif;

  ul {
    max-height: 215px;
  }
  ul li span {
    font-size: 12px;
  }
  & > button {
    padding: 0;
    border: none;
    color: white;

    > span {
      padding-right: 0;
    }
    span {
      font-size: 14px;
    }
    ::after {
      border-top-color: white !important;
      border-bottom-color: white !important;
    }
  }
`
