import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import Footer from './footer/Footer'
import Header from './header/Header'
import { GUIDE, HOME, INTRO, NAVIGATIONS } from '../constants'
import useNavigation from '../hooks/useNavigation'
import assets from '../constants/assets'
import Background from './common/Background'
import { motion } from 'framer-motion'
import { backgrounds } from '../style/variants'

const { mainBg, paperBg, homeBg } = assets.images

const Layout = ({ children }) => {
  const location = useLocation()
  const history = useHistory()
  const { initView, goTo, curViewID } = useNavigation()
  const [paperBG, setPaperBG] = useState(false)
  const [homeBG, setHomeBG] = useState(false)

  useEffect(() => {
    const initialViewID = NAVIGATIONS.findIndex((nav) => location.pathname === nav.path)
    if (initialViewID === -1) {
      goTo(history, 0)
    } else {
      initView(initialViewID)
    }
  }, [location, initView, goTo, history])

  useEffect(() => {
    switch (curViewID) {
      case HOME:
        setHomeBG(true)
        setPaperBG(false)

        break
      case INTRO:
      case GUIDE:
        setPaperBG(true)
        setHomeBG(false)
        break
      default:
        setPaperBG(false)
        setHomeBG(false)
    }
  }, [curViewID])

  return (
    <LayoutContainer>
      <Content>
        <Background preset="fadeIn" duration={0.6} background={mainBg} />
        <Background
          as={motion.div}
          background={paperBg}
          variants={backgrounds}
          initial="initial"
          animate={paperBG ? 'enter' : 'initial'}
        />
        <Background
          as={motion.div}
          background={homeBg}
          variants={backgrounds}
          initial="initial"
          animate={homeBG ? 'enter' : 'initial'}
        />
        <Header />
        <Main>{children}</Main>
        <Footer />
      </Content>
    </LayoutContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(Layout)

const LayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
`

const Content = styled(LayoutContainer)(
  () => css`
    max-width: 480px;
    overflow: hidden;
    position: relative;
  `,
)

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
