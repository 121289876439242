const IS_LDC = window.location.origin.includes('ldc') || process.env.REACT_APP_USE_LDC === 'true'

export const ACTION_HEX_BUTTON_FOOTER = 'Primary'
export const ACTION_LABEL_BUTTON_FOOTER = 'Secondary'

export const ACTION_SCAN_TARGET = 'Scan Target'
export const ACTION_CONFIRM_SLIDER = 'Confirm'

export const CATEGORY_PREFIX = IS_LDC ? 'LDC' : 'LLP'
export const CATEGORY_MODAL_CONSENT = 'Consent Modal'
