import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PulseArrow from './PulseArrow'
import CommonText from './CommonText'
import * as variants from '../../style/variants'
import { ARROW_DOWN } from '../../constants/index'

const DownloadIndicator = ({ children, direction = ARROW_DOWN, ...rest }) => {
  return (
    <Container {...rest}>
      <StyledCommonText
        key="download_text"
        variants={variants.headline}
        initial="initial"
        animate={{ ...variants.headline.animate, transition: { type: 'tween', duration: 0.5, delay: 0.2 } }}
        exit="initial"
        fontSize="xs"
        shadow="simple-shadow"
        lowercase
      >
        {children}
      </StyledCommonText>
      <StyledPulseArrow direction={direction} />
    </Container>
  )
}

DownloadIndicator.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.number,
}

export default memo(DownloadIndicator)

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledCommonText = styled(CommonText)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledPulseArrow = styled(PulseArrow)`
  position: relative;
`
