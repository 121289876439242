export const TRANSLATIONS_EN = {
  browser_change: 'This experience is best used on Safari',
  your_journey: 'Your journey',
  is_loading: 'is loading',
  consent: 'You want your kids to be safe online, and so do we. Please tell us what year you were born.',
  consent_accept_age: 'CONFIRM',
  consent_accept: 'I CONSENT',
  consent_deny: 'I DO NOT CONSENT',
  consent_agree:
    'I consent to videos of me and/or my child being recorded and used to create a video showing the Ninjago Quest to collect all the golden fragments.  I understand that I can withdraw my consent at any time.  If a child appears in this/these videos, I confirm that I am the parent or guardian of that child. For more information, <a href="/legal-us.html" target="_blank">click here.</a>',
  consent_deny_age_page: 'SORRY, YOU NEED TO HAVE A PARENT OR GUARDIAN HELP YOU PARTICIPATE IN THIS EXPERIENCE',
  consent_deny_consent_page:
    "We're sorry, but this Experience requires us to record and save videos to create your digital takeaway. Please visit Ninjago.com to continue your journey.",
  terms_service:
    'By clicking, you agree to \n the <a href="https://www.lego.com/en-us/page/terms-and-conditions" target="_blank">Terms of Use</a>, <a href="https://www.lego.com/en-us/legal/notices-and-policies/privacy-policy/" target="_blank">Privacy Policy</a> \n and <a href="https://www.lego.com/en-us/cookie-policy" target="_blank">Cookie Policy</a>',
  rotate_mobile: 'rotate your device',
  intro: {
    header: {
      ninjago: 'Ninjago Quest',
      title: "Master wu's\ngolden amulet",
    },
    content: [
      "Evil Lord Garmadon smashed Master Wu's Golden Amulet",
      'Into 6 pieces',
      'And hid them in various places around the world!',
      'Master Wu needs your help',
      'To retrieve these Golden Fragments',
      'And restore the Golden Amulet',
      'To protect the Ninjago Legacy.',
      'This is your quest.',
      'Good luck!',
    ],
  },
  runtime_error: 'Something went wrong',
  no_virtue_text: 'Go to the other background to find the last 3 Golden Fragments!',
  retake: 'Retake',
  done: 'Continue',
  finish_early: "No thanks, I'm done",
  finish_early_drag: 'Drag across to end',
  success: 'Success',
  quest_complete: 'Quest Complete',
  success_text: 'Continue on to unlock \n the next Golden Fragment!',
  quest_complete_text:
    'Congrats! You’ve collected all the Golden Fragments and successfully completed your quest!<br/><br/>Tap the button below to receive your video and finalize your next step in the Journey To Mastery.',
  play_again: 'Play Again?',
  start_over: 'Start over from the beginning to switch roles or capture a new set of poses!',
  processing: 'Uploading',
  processing_video: 'Finishing your video',
  skip_sure: 'Are you sure?',
  skip_text:
    'You have yet to collect all the Golden Fragments! Master Wu still needs your help! You will still receive your Ninjago Quest video, but it won’t be complete.',
  cancel: 'Cancel',
  tap: 'Tap ',
  download_video: ' to download your video',
  home_small_title: 'ninjago quest',
  home_main_title: "master wu's golden amulet",
  desktop_small_title:
    'The Ninjago Quest virtual experience was designed for mobile devices. \n Visit your nearest Legoland Park or Legoland Discovery Center to participate.',
  desktop_main_title: 'As always, be Ninja!',
  intro_title: "master wu's golden amulet",
  guide_title: 'find the golden fragments!',
  guide_text:
    'Unlock the Golden Fragment for each virtue by taking a photo with the ninja that appears on your screen!',
  guide_luck: 'Good luck',
  camera_access: 'please allow access to your camera to proceed!',
  ar_instruction: 'point your camera\n at each scene to unlock\n the golden fragments!',
  tap_start: 'Tap and hold to start',
  virtue_texts: {
    kai: ['your bravery will\n never tire,\n let the courage of\n kai inspire,\n fight all evil with\n your fire!'],
    lloyd: ['you’ve looked left,\n you’ve looked right,\n now meditate — and take flight!'],
    nya: ['you’ve looked up,\n you’ve looked down,\n have some fun\n and dance around!'],
    jay: [
      'stand beneath this mighty tree,\n for this fragment you need honesty.\n bow as you recite,\n “i vow to do what’s right.”',
    ],
    cole: ['to master the skills of cole,\n stand up straight as a pole.\n the golden fragment is the goal!'],
    zane: ['give someone else a chance.\n ask your friend to do a dance.\n only by sharing can you advance!'],
  },
  endlink: 'Continue your journey\nto mastery at <a href="https://www.ninjago.com" target="_blank">ninjago.com</a>',
  text_direction: 'ltr',
}
