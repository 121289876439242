import React, { memo } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import CommonText from '../common/CommonText'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import Container from '../common/ContentContainer'
import assets from '../../constants/assets'

const { cameraIcon } = assets.images

const CameraAccess = () => {
  const { t } = useAppContext()

  return (
    <Content>
      <CommonText color={color.gold} fontFamily={KUNOICHI} fontSize="lg" shadow="deep-shadow">
        {t('camera_access')}
      </CommonText>
      <CameraIcon src={cameraIcon} />
    </Content>
  )
}

export default memo(CameraAccess)

const Content = styled(Container)`
  justify-content: center;
`

const CameraIcon = styled.img`
  width: 150px;
  margin: 20px 0 0 0;
`
