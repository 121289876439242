import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import color from '../../style/color'
import CommonText from './CommonText'
import TransitionContainer from './TransitionContainer'
import { SANS_SERIF } from '../../style/type'
import { useAppContext } from '../../context/AppContext'
import ReactHtmlParser from 'react-html-parser'

const textVariants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
}

const CheckBox = ({ onChange, label, checked, ...props }) => {
  const { t } = useAppContext()

  return (
    <Container {...props}>
      <CheckboxContainer>
        <label>
          <HiddenCheckbox checked={checked} onChange={onChange} />
          <StyledCheckbox checked={checked}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
          <span>{ReactHtmlParser(t(label))}</span>
        </label>
      </CheckboxContainer>
    </Container>
  )
}

CheckBox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
}

export default CheckBox

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;

  span {
    position: relative;
    margin-left: 10px;
    color: white;
    top: -4px;
    font-size: 11px;
    a {
      display: inline;
      color: ${color.blue};
      font-weight: bold;
    }
  }
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? color.darkRed : color.white)};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 1px ${color.red};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`

const Container = styled(TransitionContainer)(
  () => css`
    width: 100%;
    position: relative;
  `,
)
