import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'

const loadGLTF = (url) => {
  return new Promise((resolve, reject) => {
    new GLTFLoader().load(url, resolve, null, reject)
  })
}

const loadHDR = (url, pmremGenerator) => {
  return new Promise((resolve, reject) => {
    new RGBELoader().setDataType(THREE.UnsignedByteType).load(
      url,
      (hdr) => {
        const envMap = pmremGenerator.fromEquirectangular(hdr).texture
        pmremGenerator.dispose()

        resolve(envMap)
      },
      null,
      reject,
    )
  })
}

const loadTexture = (url) => {
  return new Promise((resolve, reject) => {
    new THREE.TextureLoader().load(
      url,
      (texture) => {
        texture.encoding = THREE.sRGBEncoding

        resolve(texture)
      },
      null,
      reject,
    )
  })
}

export { loadGLTF, loadHDR, loadTexture }
