import mainBg from '../assets/images/Background1.png'
import paperBg from '../assets/images/Background2.png'
import homeBg from '../assets/images/home/backdrop.jpg'
import footerBG from '../assets/images/Footer_bg.png'
import headerBG from '../assets/images/Header_bg.png'
import logoNinjago from '../assets/images/ninjago_logo_rf.svg'
import coin from '../assets/images/coin.png'
import kaiBg from '../assets/images/capture-progress/kai.jpg'
import lloydBg from '../assets/images/capture-progress/lloyd.jpg'
import nyaBg from '../assets/images/capture-progress/nya.jpg'
import zaneBg from '../assets/images/capture-progress/zane.jpg'
import coleBg from '../assets/images/capture-progress/cole.jpg'
import jayBg from '../assets/images/capture-progress/jay.jpg'
import posKai from '../assets/images/Pos_kai.png'
import posLloyd from '../assets/images/Pos_lloyd.png'
import posNya from '../assets/images/Pos_nya.png'
import posJay from '../assets/images/Pos_jay.png'
import posCole from '../assets/images/Pos_cole.png'
import posZane from '../assets/images/Pos_zane.png'
import courageIcon from '../assets/images/Courage_icon.png'
import wisdomIcon from '../assets/images/Wisdom_icon.png'
import curiosityIcon from '../assets/images/Curiosity_icon.png'
import honestyIcon from '../assets/images/Honesty_icon.png'
import balanceIcon from '../assets/images/Balance_icon.png'
import generosityIcon from '../assets/images/Generosity_icon.png'
import beginHexagonBtn from '../assets/images/Begin_hexagon.png'
import replayHexagonBtn from '../assets/images/Replay_hexagon.png'
import downloadHexagonBtn from '../assets/images/Download_hexagon.png'
import allowHexagonBtn from '../assets/images/Allow_hexagon.png'
import nextHexagonBtn from '../assets/images/Next_hexagon.png'
import scrollDown from '../assets/images/scroll-down.png'
import legolandLogo from '../assets/images/legoland.png'
import ldcLogo from '../assets/images/Logo_ldc.png'
import labelBG1 from '../assets/images/Label_bg1.png'
import labelBG2 from '../assets/images/Label_bg2.png'
import playIcon from '../assets/images/Play_icon.png'
import scrollSurfaceSrc from '../assets/images/intro/scroll-surface.png'
import amuletBg from '../assets/images/amulet/amulet_bg.png'
import amuletFull from '../assets/images/amulet/amulet_full.png'
import amulet1 from '../assets/images/amulet/1.png'
import amulet2 from '../assets/images/amulet/2.png'
import amulet3 from '../assets/images/amulet/3.png'
import amulet4 from '../assets/images/amulet/4.png'
import amulet5 from '../assets/images/amulet/5.png'
import amulet6 from '../assets/images/amulet/6.png'
import closeIcon from '../assets/images/Close_icon.png'
import rotateBg from '../assets/images/Rotate_bg.png'
import masterWu from '../assets/images/home/masterwu.png'
import brushStroke from '../assets/images/home/stroke.png'
import scanBar from '../assets/images/Scan_bar.png'
import cameraIcon from '../assets/images/Camera_icon.png'
import desktopPaper from '../assets/images/desktop/paper.png'
import desktopHeaderBG from '../assets/images/desktop/Header_bg.png'
import desktopBrushStroke from '../assets/images/desktop/stroke.png'
import infographic from '../assets/images/guide/setup.png'
import rotateIcon from '../assets/images/Rotate_icon.png'
import soundOnIcon from '../assets/images/sound_on.png'
import soundOffIcon from '../assets/images/sound_off.png'
import arrowDownloadIcon from '../assets/images/arrow-download.svg'
import arrowHintIcon from '../assets/images/arrow-hint.svg'

import sfxKai from '../assets/sfxs/SFX_kai.mp3'
import sfxLloyd from '../assets/sfxs/SFX_lloyd.mp3'
import sfxNya from '../assets/sfxs/SFX_nya.mp3'
import sfxJay from '../assets/sfxs/SFX_jay.mp3'
import sfxCole from '../assets/sfxs/SFX_cole.mp3'
import sfxZane from '../assets/sfxs/SFX_zane.mp3'
import voiceKai from '../assets/sfxs/VO-KAI.mp3'
import voiceLloyd from '../assets/sfxs/VO-LLOYD.mp3'
import voiceNya from '../assets/sfxs/VO-NYA.mp3'
import voiceJay from '../assets/sfxs/VO-JAY.mp3'
import voiceCole from '../assets/sfxs/VO-COLE.mp3'
import voiceZane from '../assets/sfxs/VO-ZANE.mp3'

export default {
  images: {
    mainBg,
    paperBg,
    homeBg,
    footerBG,
    headerBG,
    logoNinjago,
    coin,
    posKai,
    posLloyd,
    posNya,
    posJay,
    posCole,
    posZane,
    courageIcon,
    wisdomIcon,
    curiosityIcon,
    honestyIcon,
    balanceIcon,
    generosityIcon,
    beginHexagonBtn,
    replayHexagonBtn,
    downloadHexagonBtn,
    allowHexagonBtn,
    nextHexagonBtn,
    legolandLogo,
    ldcLogo,
    scrollDown,
    kaiBg,
    lloydBg,
    nyaBg,
    zaneBg,
    coleBg,
    jayBg,
    labelBG1,
    labelBG2,
    playIcon,
    scrollSurfaceSrc,
    amuletBg,
    amuletFull,
    amulet1,
    amulet2,
    amulet3,
    amulet4,
    amulet5,
    amulet6,
    closeIcon,
    rotateBg,
    masterWu,
    brushStroke,
    scanBar,
    cameraIcon,
    desktopPaper,
    desktopHeaderBG,
    desktopBrushStroke,
    infographic,
    rotateIcon,
    soundOnIcon,
    soundOffIcon,
    arrowDownloadIcon,
    arrowHintIcon,
  },
  audios: {
    sfxKai,
    sfxLloyd,
    sfxNya,
    sfxJay,
    sfxCole,
    sfxZane,
    voiceKai,
    voiceLloyd,
    voiceNya,
    voiceJay,
    voiceCole,
    voiceZane,
  },
}
