import React from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import CommonText from '../common/CommonText'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import assets from '../../constants/assets'

const { rotateBg } = assets.images

const RuntimeError = () => {
  const { t } = useAppContext()

  return (
    <Container>
      <CommonText color={color.gold} fontFamily={KUNOICHI} fontSize="lg" shadow="deep-shadow">
        {t('runtime_error')}
      </CommonText>
    </Container>
  )
}

export default RuntimeError

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: center / cover no-repeat url(${rotateBg});
  z-index: 999;
`
