export const TRANSLATIONS_TR = {
  browser_change: "Bu deneyim en iyi Safari'de yaşanır.",
  your_journey: 'Yolculuğunuz',
  is_loading: 'yükleniyor',
  consent:
    'Biz de sizin gibi çocuklarınızın sanal ortamda güvende olmasını istiyoruz. Lütfen bize hangi yılda doğduğunuzu söyleyin. ',
  consent_accept_age: 'Onayla',
  consent_accept: 'Onaylıyorum',
  consent_deny: 'Onaylamıyorum',
  consent_agree:
    'Benim ve/veya çocuğumun videolarının kaydedilmesine ve Ninjago Quest\'de tüm altınları toplamayı gösteren bir video oluşturmak için kullanılmasına izin veriyorum. Onayımı istediğim zaman geri çekebileceğimi anlıyorum. Bu videoda/videolarda bir çocuğun görünmesi durumunda o çocuğun ebeveyni veya velisi olduğumu onaylıyorum. Daha fazla bilgi için <a href="/legal-tr.html" target="_blank">buraya tıklayın.</a>',
  consent_deny_age_page: 'ÜZGÜNÜZ, BU DENEYİME KATILMANIZ İÇİN BİR EBEVEYNİN VEYA VELİNİN YARDIMCI OLMASI GEREKİYOR',
  consent_deny_consent_page:
    'Üzgünüz ancak bu deneyim, dijital paketinizi oluşturmak için videoları kaydetmemizi ve saklamamızı gerektiriyor. Yolculuğunuza devam etmek için lütfen <a href="https://www.ninjago.com" target="_blank">Ninjago.com</a>\'u ziyaret edin.',
  terms_service:
    'Bu bağlantıya tıklayarak<br/><a href="https://www.lego.com/tr-tr/page/terms-and-conditions" target="_blank">Kullanım Koşullarını</a>, <a href="https://www.lego.com/tr-tr/legal/notices-and-policies/privacy-policy/" target="_blank">Gizlilik Politikasını</a><br/>ve <a href="https://www.lego.com/tr-tr/cookie-policy" target="_blank">Çerez Politikasını</a><br/>kabul etmiş olursunuz.',
  rotate_mobile: 'Cihazınızı döndürün',
  intro: {
    header: {
      ninjago: 'NINJAGO Görevi',
      title: "Usta Wu'nun\nAltın Tılsımı",
    },
    content: [
      "Kötü Lord Garmadon, Usta Wu'nun Altın Tılsımını 6 parçaya ayırdı ve bunları dünyanın farklı yerlerine sakladı!",
      "Usta Wu'nun, Altın Parçalarını almak ve NINJAGO Mirasını korumak için senin yardımına ihtiyacı var.",
      'Görevin budur.',
    ],
  },
  runtime_error: 'Bir şeyler yanlış gitti',
  no_virtue_text: 'Son 3 Altın Parçayı bulmak için diğer arka plana gidin',
  retake: 'Tekrar çek',
  done: 'Devam et',
  finish_early: 'Hayır, bu kadarı yeterli.',
  finish_early_drag: 'Sonlandırmak<br/>için sürükle',
  success: 'Başarılı oldu!',
  quest_complete: 'Görev Tamamlandı!',
  success_text: 'Bir sonraki Altın Parçasının kilidini açmak için devam et!',
  quest_complete_text:
    'Tebrikler! Tüm Altın Tılsım Parçalarını topladın ve görevini başarıyla tamamladın!<br/><br/>Videonu almak için aşağıdaki indirme düğmesini tıkla!',
  play_again: 'Tekrar oynamak ister misin?',
  start_over: 'Rolleri değiştirmek veya yeni bir poz seti yakalamak için baştan başla!',
  processing: 'yükleniyor',
  processing_video: 'Videonu Tamamlama',
  skip_sure: 'Emin misin?',
  skip_text:
    "Henüz tüm Altın Tılsım Parçalarını toplamadın! Usta Wu'nun hala yardımına ihtiyacı var! NINJAGO Görevi videonu yine de alacaksın, ancak tamamlanmış olmayacak.",
  cancel: 'İptal et',
  tap: 'Videonuzu  ',
  download_video: ' indirmek için dokunun',
  home_small_title: 'NINJAGO Görevi',
  home_main_title: "Usta Wu'nun Altın Tılsımı",
  desktop_small_title:
    "Sanal Ninjago Quest deneyimi, mobil cihazlar için tasarlanmıştır. Bu deneyime katılmak için size en yakın Legoland Parkı'nı veya Legoland Discovery Center'i ziyaret edin.",
  desktop_main_title: 'Her zamanki gibi, bir Ninja olun!',
  intro_title: "Usta Wu'nun Altın Tılsımı",
  guide_title: 'Altın Parçaları bul!',
  guide_text: 'Ekranında görünen ninja ile bir fotoğraf çekerek her yetenek için Altın Parçaların kilidini aç!',
  guide_luck: 'İyi şanslar!',
  camera_access: 'Lütfen devam etmek için kamera erişimine izin ver!',
  ar_instruction: 'Altın Parçaların kilidini açmak için kameranı tüm sahnelere doğrult!',
  tap_start: 'Başlamak için basılı tut',
  virtue_texts: {
    kai: ["Cesaretin asla bitmesin, Kai'nin cesareti sana ilham versin, Ateşinle tüm kötülerle savaş! "],
    lloyd: ['Sola baktın, Sağa baktın, Şimdi meditasyon yap ve havaya kalk!'],
    nya: ['Yukarı baktın, Aşağı baktın, Şimdi biraz dans et ve keyfine bak!'],
    jay: ['İyi bir ninja dürüst olmalıdır. Tekrar ederken başını eğ, "Doğru olan şeyi yapacağıma söz veriyorum!"'],
    cole: ["Cole'un becerilerini geliştirmek için Direk gibi dik dur. Altın Parçaya ulaşmak için koştur!"],
    zane: ['Bir başkasına şans ver. Arkadaşını dansa kaldır. Yalnızca paylaşarak ilerleyebilirsin!'],
  },
  endlink: 'Ustalık yolculuğunu<br/><a href="https://www.ninjago.com" target="_blank">Ninjago.com</a>\'da sürdür  ',
  text_direction: 'ltr',
}
