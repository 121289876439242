import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Background from '../common/Background'
import CommonText from '../common/CommonText'
import { useAppContext } from '../../context/AppContext'
import EllipsesText from '../common/EllipsesText'
import * as variants from '../../style/variants'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import assets from '../../constants/assets'
import ReactHtmlParser from 'react-html-parser'

const { mainBg, logoNinjago } = assets.images

const AssetLoading = ({ browserChange }) => {
  const { t } = useAppContext()

  return (
    <Container>
      <StyledBackground preset="fadeIn" duration={0.6} background={mainBg} />
      <Logo
        src={logoNinjago}
        key="logo"
        variants={variants.headline}
        initial="initial"
        animate={{ ...variants.headline.animate, transition: { type: 'tween', duration: 0.75, delay: 0.2 } }}
        exit="initial"
      />
      <StyledCommonText
        color={color.gold}
        fontFamily={KUNOICHI}
        fontSize="xl"
        shadow="deep-shadow"
        as={motion.h2}
        key="p1"
        variants={variants.headline}
        initial="initial"
        animate={{ ...variants.headline.animate, transition: { type: 'tween', duration: 0.75, delay: 0.2 } }}
        exit="initial"
        textAlign="left"
      >
        {ReactHtmlParser(browserChange ? t('browser_change') : t('your_journey'))}
      </StyledCommonText>
      {!browserChange && <EllipsesText>{ReactHtmlParser(t('is_loading'))}</EllipsesText>}
    </Container>
  )
}

AssetLoading.propTypes = {
  browserChange: PropTypes.bool,
}

export default AssetLoading

const Container = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const StyledBackground = styled(Background)`
  transform: translateY(50px) scale(1.2);
`

const Logo = styled(motion.img)`
  width: 130px;
  margin: 0 0 20px 0;
  z-index: 1;
`
const StyledCommonText = styled(CommonText)`
  max-width: 80vw;
  text-align: center;
`
