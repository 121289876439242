import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { motion } from 'framer-motion'
import styled, { css } from 'styled-components/macro'

import * as variants from '../../style/variants'

const presetKeys = Object.keys(variants)

const TransitionContainer = forwardRef(
  ({ children, delay, duration, ease, fill, preset, type, exitDuration, exitDelay, ...props }, ref) => {
    const MotionContainer = motion[type]
    const { animate, exit, initial } = variants[preset]

    const exitDur = exitDuration || duration
    const exitDel = exitDelay || delay
    return (
      <MotionContainer
        ref={ref}
        key={`${type}-${preset}`}
        className="trans"
        initial={initial}
        animate={{
          ...animate,
          transition: {
            ...animate.transition,
            ...(delay !== false && { delay }),
            ...(duration && { duration }),
            ...(type && { type }),
            ...(ease && { ease }),
          },
        }}
        exit={{
          ...exit,
          transition: {
            ...exit.transition,
            ...(exitDur && { duration: exitDur }),
            ...(exitDel && { delay: exitDelay }),
            ...(type && { type }),
            ...(ease && { ease }),
          },
        }}
        fill={fill.toString()}
        {...props}
      >
        {children}
      </MotionContainer>
    )
  },
)

TransitionContainer.propTypes = {
  delay: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  exitDelay: PropTypes.number,
  duration: PropTypes.number,
  exitDuration: PropTypes.number,
  ease: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
  fill: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
  preset: PropTypes.oneOf(presetKeys),
  test: PropTypes.bool,
}

TransitionContainer.defaultProps = {
  delay: false,
  disabled: false,
  ease: 'easeOut',
  fill: false,
  type: 'div',
  preset: 'fadeIn',
}

const StyledTransitionContainer = styled(TransitionContainer)`
  ${({ disabled, fill, test }) => css`
    ${disabled &&
    css`
      pointer-events: none;
    `};

    ${test &&
    css`
      border: 1px solid red;
    `};

    ${fill === 'true' &&
    css`
      width: 100%;
      flex: 1;
    `};
  `}
`

export default StyledTransitionContainer
