import { GA_ID } from '../constants'
import { CATEGORY_PREFIX } from '../constants/analytics'

const analytics = {
  trackEvent: ({ action, value, eventCategory, eventLabel }) => {
    if (GA_ID && window.gtag) {
      window.gtag('event', action, {
        event_category: `${CATEGORY_PREFIX} ${eventCategory}`,
        event_label: eventLabel,
        ...(value && { value }),
      })
    }
  },

  trackPageView: ({ title, location, path }) => {
    if (GA_ID && window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: title,
        page_location: location,
        page_path: path,
        send_to: GA_ID,
      })
    }
  },
}

export default analytics
