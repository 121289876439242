import { useCallback, useEffect, useReducer, useState } from 'react'
import { useAppContext } from '../context/AppContext'
import { IS_LDC, MODELS } from '../constants'
import { VENUE_LLP, VENUE_LDC } from '@lego-ninjago-ar/common/lib/config/venue'
import {
  submitSegment as apiSubmitSegment,
  createVideo as apiCreateVideo,
  pollVideoStatus as apiPollVideoStatus,
} from '../utils/apiClient'
import i18n from 'i18next'

export const CAPTURE_COMPLETE = 'captureComplete'
export const CAPTURE_STATE_RESET = 'captureStateReset'

export const modelsReducer = (state, { type, payload }) => {
  switch (type) {
    case CAPTURE_COMPLETE:
      return [...state, payload]
    case CAPTURE_STATE_RESET:
      return []
  }
}

const useCapture = () => {
  const {
    captureState: {
      models,
      dispatch,
      retake,
      setRetake,
      currentSegment,
      setCurrentSegment,
      userFinalVideoSrc,
      setUserFinalVideoSrc,
    },
  } = useAppContext()

  const [error, setError] = useState(false)
  const [complete, setComplete] = useState(false)
  const [loading, setLoading] = useState(false)

  const name = currentSegment?.name
  const videoBlob = currentSegment?.videoBlob

  const setRequestState = useCallback(
    (loading, complete, error) => {
      setError(error)
      setComplete(complete)
      setLoading(loading)
    },
    [setComplete, setError, setLoading],
  )

  const completeCapture = useCallback(
    (payload) => {
      dispatch({ type: CAPTURE_COMPLETE, payload })
      setCurrentSegment(null)
    },
    [dispatch, setCurrentSegment],
  )

  const resetCapture = useCallback(
    (reset) => {
      setRetake(reset)
    },
    [setRetake],
  )

  const submitSegment = useCallback(async () => {
    try {
      setRequestState(true, false, false)
      const segmentData = await apiSubmitSegment({
        blob: videoBlob,
        order: models.length,
      })
      setRequestState(false, true, false)
      completeCapture({
        ...segmentData,
        name,
      })
      setComplete(false)
    } catch (e) {
      console.error('submitSegment:', e)
      setRequestState(false, false, true)
    }
  }, [completeCapture, models.length, name, setRequestState, videoBlob])

  const createVideo = useCallback(async () => {
    try {
      setRequestState(true, false, false)
      const { id } = await apiCreateVideo({
        segments: models.map(({ segmentId }) => segmentId),
        lang: i18n.language,
        venue: IS_LDC ? VENUE_LDC : VENUE_LLP,
      })
      const videoSrc = await apiPollVideoStatus({
        id,
      })
      setRequestState(false, true, false)
      setUserFinalVideoSrc(videoSrc)
    } catch (e) {
      console.error('createVideo:', e)
      setRequestState(false, false, true)
    }
  }, [models, setRequestState, setUserFinalVideoSrc])

  const deleteLocalState = useCallback(() => {
    dispatch({ type: CAPTURE_STATE_RESET })
  }, [dispatch])

  return {
    requiresReset: retake,
    resetCapture,
    submitSegment,
    createVideo,
    completeCapture,
    setCurrentSegment,
    currentSegment,
    totalProgress: models.length,
    models,
    userFinalVideoSrc,
    deleteLocalState,
    requestState: {
      error,
      complete,
      loading,
    },
  }
}

export default useCapture
