import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import CommonText from './CommonText'
import ColorButton from './ColorButton'
import Modal from './Modal'
import { rgba } from 'polished'
import color from '../../style/color'
import { useAppContext } from '../../context/AppContext'
import DateInput from './DateInput'
import { AGE_REQUIREMENT_MS } from '../../constants'
import CheckBox from './CheckBox'

const AgeGateModal = ({ handleConsentAccept, handleConsentDeny }) => {
  const { t } = useAppContext()

  const [dobValid, setDobValid] = useState(false)
  const [validDate, setValidDate] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const handleInputChange = useCallback((e) => {
    const userYear = e.target.value
    if (userYear > 1900 && !isNaN(userYear)) {
      setValidDate(true)
      const currentYear = new Date().getFullYear()
      setDobValid(currentYear - userYear >= 16)
    } else {
      setDobValid(false)
      setValidDate(false)
    }
  }, [])

  const handleConsentDate = useCallback(() => {
    if (dobValid) handleConsentAccept()
    else handleConsentDeny()
  }, [dobValid, handleConsentAccept, handleConsentDeny])

  useEffect(() => {
    if (validDate) setButtonDisabled(false)
    else setButtonDisabled(true)
  }, [validDate])

  return (
    <Container>
      <CommonText color="white" fontSize="md" lowercase>
        {t('consent')}
      </CommonText>
      <DateInput delay={1.1} onChange={handleInputChange} />
      <ColorButton delay={1.2} onSubmit={handleConsentDate} label="consent_accept_age" disabled={buttonDisabled} />
    </Container>
  )
}

AgeGateModal.propTypes = {
  handleConsentAccept: PropTypes.func,
  handleConsentDeny: PropTypes.func,
}

export default AgeGateModal

const Container = styled(Modal)`
  position: absolute;
  width: 90%;
  height: calc(100% - 140px);
  padding: 20px;
  z-index: 99999999;
  background: ${rgba(color.darkGray, 0.9)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: auto;

  > * {
    margin-bottom: 15px;
  }
`
