
const SUBMISSION_STATUS_COMPLETE = 'COMPLETE'
const SUBMISSION_STATUS_ERROR = 'ERROR'
const SUBMISSION_STATUS_PENDING = 'PENDING'
const SUBMISSION_STATUS_RUNNING = 'RUNNING'


module.exports = {
  SUBMISSION_STATUS_COMPLETE,
  SUBMISSION_STATUS_ERROR,
  SUBMISSION_STATUS_PENDING,
  SUBMISSION_STATUS_RUNNING,
}
