import * as THREE from 'three'

export const rand = (min, max) => {
  return Math.random() * (max - min) + min
}

export const randVector = (minVector, maxVector) => {
  const x = rand(minVector.x, maxVector.x)
  const y = rand(minVector.y, maxVector.y)
  const z = rand(minVector.z, maxVector.z)
  return new THREE.Vector3(x, y, z)
}
