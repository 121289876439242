import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Background from '../common/Background'
import CommonText from '../common/CommonText'
import { useAppContext } from '../../context/AppContext'
import * as variants from '../../style/variants'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import assets from '../../constants/assets'
import ReactHtmlParser from 'react-html-parser'

const { mainBg, logoNinjago } = assets.images

const DeniedConsent = () => {
  const { t } = useAppContext()

  return (
    <Container>
      <StyledBackground preset="fadeIn" duration={0.6} background={mainBg} />
      <Logo
        src={logoNinjago}
        key="logo"
        variants={variants.headline}
        initial="initial"
        animate={{ ...variants.headline.animate, transition: { type: 'tween', duration: 0.75, delay: 0.2 } }}
        exit="initial"
      />
      <StyledCommonText
        color={color.gold}
        fontFamily={KUNOICHI}
        fontSize="md"
        shadow="deep-shadow"
        as={motion.h2}
        key="p1"
        variants={variants.headline}
        initial="initial"
        animate={{ ...variants.headline.animate, transition: { type: 'tween', duration: 0.75, delay: 0.2 } }}
        exit="initial"
        textAlign="left"
      >
        {ReactHtmlParser(t('consent_deny_consent_page'))}
      </StyledCommonText>
    </Container>
  )
}

export default DeniedConsent

const Container = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Logo = styled(motion.img)`
  width: 130px;
  margin: 0 0 20px 0;
  z-index: 1;
`

const StyledBackground = styled(Background)`
  transform: translateY(50px) scale(1.2);
`

const StyledCommonText = styled(CommonText)`
  text-align: center;
  width: 80%;

  a {
    display: inline;
    color: ${color.blue};
    text-decoration: underline;
    white-space: nowrap;
  }
`
