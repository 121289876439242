import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { TRANSLATIONS_EN } from './en/translations'
import { TRANSLATIONS_JP } from './jp/translations'
import { TRANSLATIONS_AE } from './ae/translations'
import { TRANSLATIONS_TR } from './tr/translations'
import { TRANSLATIONS_DK } from './dk/translations'
import { TRANSLATIONS_DE } from './de/translations'
import { TRANSLATIONS_CN } from './cn/translations'

const resources = {
  en: {
    translation: TRANSLATIONS_EN,
  },
  jp: {
    translation: TRANSLATIONS_JP,
  },
  ae: {
    translation: TRANSLATIONS_AE,
  },
  tr: {
    translation: TRANSLATIONS_TR,
  },
  dk: {
    translation: TRANSLATIONS_DK,
  },
  de: {
    translation: TRANSLATIONS_DE,
  },
  cn: {
    translation: TRANSLATIONS_CN,
  },
}

i18n.use(initReactI18next).init({
  resources,
})

const urlLocations = {
  de: ['gunzburg', 'berlin', 'oberhausen'],
  dk: ['billund'],
  ae: ['dubai'],
  jp: ['tokyo', 'osaka'],
  cn: ['shanghai', 'shenyang', 'beijing'],
  tr: ['istanbul'],
}

const urlParams = new URLSearchParams(window.location.search)
const urlLocationParam = urlParams.get('_loc')
const browserLanguage = Object.keys(resources).find((lang) => navigator.language.includes(lang))
let startLanguage = browserLanguage

Object.keys(urlLocations).forEach((key) => {
  if (urlLocations[key].includes(urlLocationParam)) startLanguage = key
})

try {
  let sessionLanguage = JSON.parse(sessionStorage.getItem('userLang'))
  startLanguage = sessionLanguage || startLanguage
} catch (e) {}

i18n.changeLanguage(startLanguage || 'en')
