import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { KUNOICHI } from '../../style/type'
import TransitionContainer from './TransitionContainer'
import assets from '../../constants/assets'

const { beginHexagonBtn, replayHexagonBtn, downloadHexagonBtn, allowHexagonBtn, nextHexagonBtn } = assets.images

const HexagonButton = ({ className, onClick, children, buttonAs = 'button', delay = 0, ...rest }) => {
  const [presentFinished, setPresentFinished] = useState(false)

  useEffect(() => {
    setPresentFinished(false)
    const timeOut = setTimeout(() => setPresentFinished(true), delay * 1000 + 500)

    return () => {
      if (timeOut) clearTimeout(timeOut)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      type={buttonAs}
      className={className}
      onClick={onClick}
      hexagonBtn={children}
      preset={presentFinished ? 'pulseScale' : 'hexagonButton'}
      ease={presentFinished ? 'linear' : 'easeOut'}
      duration={presentFinished ? 0.35 : 0.25}
      delay={presentFinished ? 0 : delay}
      {...rest}
    />
  )
}

HexagonButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  buttonAs: PropTypes.string,
  delay: PropTypes.number,
}

export default memo(HexagonButton)

const HexagonButtonBegin = (props) => {
  return <HexagonButton {...props} children={beginHexagonBtn} />
}

const HexagonButtonReplay = (props) => {
  return <HexagonButton {...props} children={replayHexagonBtn} />
}

const HexagonButtonDownload = (props) => {
  return <HexagonButton {...props} children={downloadHexagonBtn} />
}

const HexagonButtonAllow = (props) => {
  return <HexagonButton {...props} children={allowHexagonBtn} />
}

const HexagonButtonNext = (props) => {
  return <HexagonButton {...props} children={nextHexagonBtn} />
}

export const HexButtons = {
  beginHexagonBtn: HexagonButtonBegin,
  replayHexagonBtn: HexagonButtonReplay,
  downloadHexagonBtn: HexagonButtonDownload,
  allowHexagonBtn: HexagonButtonAllow,
  nextHexagonBtn: HexagonButtonNext,
}

const Container = styled(TransitionContainer)`
  position: relative;
  width: 72px;
  height: 80px;
  padding: 0;
  border: none;
  outline: none;
  font-family: ${KUNOICHI};
  background: center/100% 100% no-repeat url(${({ hexagonBtn }) => (hexagonBtn ? hexagonBtn : '')});
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  transform: translate(0, 5px);
`
