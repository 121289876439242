import { API_ROUTES, API_URL } from '../constants'
import { SUBMISSION_STATUS_COMPLETE, SUBMISSION_STATUS_ERROR } from '@lego-ninjago-ar/common/lib/config/status'
import wait from './wait'
export const submitSegment = async ({ blob, order }) => {
  try {
    const formData = new FormData()
    formData.append('segment', blob)
    formData.append('order', order)

    const response = await fetch(API_URL + API_ROUTES.videoSegment, {
      method: 'POST',
      body: formData,
      mode: 'cors',
    })
    if (response.ok) {
      return await response.json()
    } else {
      throw new Error(await response.text())
    }
  } catch (e) {
    console.error('Error sendVideo', e)
    throw e
  }
}

export const createVideo = async ({ segments, lang, venue }) => {
  try {
    const response = await fetch(API_URL + API_ROUTES.video, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        segments,
        lang,
        venue,
      }),
      mode: 'cors',
    })

    if (response.ok) {
      return await response.json()
    } else {
      throw new Error(await response.text())
    }
  } catch (e) {
    console.error('Error createVideo', e)
    throw e
  }
}

export const getVideoStatus = async ({ id }) => {
  try {
    const response = await fetch(API_URL + API_ROUTES.video + `/${id}`, {
      method: 'GET',
      mode: 'cors',
    })
    if (response.ok) {
      return await response.json()
    } else {
      throw new Error(await response.text())
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

export const pollVideoStatus = async ({ id }) => {
  let videoSrc, error
  while (!videoSrc && !error) {
    const { status, path } = await getVideoStatus({ id })
    if (status === SUBMISSION_STATUS_COMPLETE) {
      videoSrc = path
    } else if (status === SUBMISSION_STATUS_ERROR) {
      throw new Error(`Video Processing: ${status}`)
    } else {
      await wait(1000)
    }
  }

  return videoSrc
}
