import * as THREE from 'three'
import times from 'lodash/times'
import { loadTexture } from '../loadAssetManager'

class LightningParticleSystem {
  constructor() {
    this.particlePath = './assets/textures/lightning/'
    this.particleCount = 30
    this.particleTextures = []
    this.particleIndex = 0
  }

  /**
   * Load assets
   * @returns loading promise
   */
  loadAssets = () => {
    return times(this.particleCount, (index) =>
      loadTexture(`${this.particlePath}${index.toString().padStart(3, '0')}.png`),
    )
  }

  /**
   * Initialize
   */
  init = ({ scene, camera, modelContainer, character, source }) => {
    this.scene = scene
    this.camera = camera
    this.modelContainer = modelContainer
    this.character = character
    this.source = source

    this.lightningGeometry = new THREE.PlaneGeometry(5, 5, 1)
    this.lightningMaterial = new THREE.MeshBasicMaterial({
      map: this.particleTextures[0],
      transparent: true,
    })
    this.lightningMesh = new THREE.Mesh(this.lightningGeometry, this.lightningMaterial)
    this.lightningMesh.scale.set(0.75, 0.75, 0.75)
    this.lightningMesh.position.set(
      this.source.position.x,
      this.source.position.y + 0.15,
      this.source.position.z + 0.15,
    )

    this.character.add(this.lightningMesh)
  }

  /**
   * Update
   * @param timeElapsed time consumed for frame rendering
   */
  update = (timeElapsed) => {
    this.lightningMaterial.map = this.particleTextures[this.particleIndex++]
    if (this.particleIndex === this.particleTextures.length) this.particleIndex = 0
  }

  /**
   * Dispose
   */
  dispose = () => {
    if (!this.lightningMesh) return

    this.lightningGeometry.dispose()
    this.lightningMaterial.dispose()
    this.particleTextures.forEach((particleTexture) => {
      particleTexture.dispose()
    })

    this.character.remove(this.lightningMesh)
  }
}

export default LightningParticleSystem
