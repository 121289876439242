import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { isMobile, isIOS, isSafari } from 'react-device-detect'
import Container from '../common/ContentContainer'
import DownloadIndicator from '../common/DownloadIndicator'
import { useAppContext } from '../../context/AppContext'
import useCapture from '../../hooks/useCapture'
import assets from '../../constants/assets'
import { ARROW_UP } from '../../constants/index'

const { soundOffIcon, soundOnIcon, playIcon, arrowDownloadIcon } = assets.images

const Preview = () => {
  const video = useRef()
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [downloadStart, setDownloadStart] = useState(false)
  const { userFinalVideoSrc, deleteLocalState } = useCapture()
  const { t, setCustomNavBtn } = useAppContext()

  const handleToggleVolume = useCallback(() => {
    setIsMuted((currentState) => {
      video.current.muted = !currentState
      return !currentState
    })
  }, [])

  const handleTogglePlay = useCallback(() => {
    if (isPlaying) video.current.pause()
    else video.current.play()
  }, [isPlaying])

  // Listener for download button click
  const handleDownloadClick = useCallback(() => {
    setDownloadStart(true)
  }, [])

  useEffect(() => {
    deleteLocalState()
  }, [deleteLocalState])

  useEffect(() => {
    setCustomNavBtn({ type: 'downloadHexagonBtn', onClick: handleDownloadClick })

    return () => {
      setCustomNavBtn(null)
    }
  }, [handleDownloadClick, setCustomNavBtn])

  useEffect(() => {
    video.current.addEventListener('playing', () => setIsPlaying(true))
    video.current.addEventListener('play', () => setIsPlaying(true))
    video.current.addEventListener('pause', () => setIsPlaying(false))
  }, [])

  return (
    <Content>
      {isMobile && isIOS && isSafari && downloadStart && (
        <StyledDownloadIndicator direction={ARROW_UP}>
          {t('tap')} <ArrowDownloadIcon src={arrowDownloadIcon} /> {t('download_video')}
        </StyledDownloadIndicator>
      )}
      <Video onClick={handleTogglePlay} ref={video} playsInline src={userFinalVideoSrc} />
      {!isPlaying && <PlayIcon src={playIcon} />}
      <SoundIcon src={!isMuted ? soundOnIcon : soundOffIcon} onClick={handleToggleVolume} />
    </Content>
  )
}

export default memo(Preview)

const Content = styled(Container)`
  justify-content: space-around;
  padding: 100px 0;
  position: relative;
`

const StyledDownloadIndicator = styled(DownloadIndicator)`
  top: 120px;
  width: 100%;
  height: auto;
  justify-content: flex-end;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  background: #000;
`

const SoundIcon = styled.img`
  width: 40px;
  position: absolute;
  bottom: 125px;
  left: 40px;
  transform: translate(-50%, -50%);
`

const PlayIcon = styled.img`
  width: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`

const ArrowDownloadIcon = styled.img`
  width: 20px;
  margin: 1px 6px 0 6px;
`
