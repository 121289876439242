import * as THREE from 'three'
import times from 'lodash/times'
import { rand, randVector } from '../../mathUtils'
import { loadGLTF } from '../loadAssetManager'

class RockParticleSystem {
  constructor() {
    this.particlePaths = [
      './assets/models/rock1.glb',
      './assets/models/rock2.glb',
      './assets/models/rock3.glb',
      './assets/models/rock4.glb',
      './assets/models/rock5.glb',
    ]
    this.particleGLTFs = []
    this.particleModels = []
    this.commonMaterial = null
    this.particleCount = 150
    this.particles = []
    this.maxScale = new THREE.Vector3(1.5, 1.5, 1.5)
    this.minScale = new THREE.Vector3(0.5, 0.5, 0.5)
    this.maxVelocity = new THREE.Vector3(-0.2, 2, 0.02)
    this.minVelocity = new THREE.Vector3(-0.5, 1, 0.01)
    this.maxRotSpeed = new THREE.Vector3(Math.PI / 8, Math.PI / 8, Math.PI / 8)
    this.minRotSpeed = new THREE.Vector3(Math.PI / 15, Math.PI / 15, Math.PI / 15)
  }

  /**
   * Load assets
   * @returns loading promise
   */
  loadAssets = () => {
    return this.particlePaths.map((path) => loadGLTF(path))
  }

  /**
   * Initialize
   */
  init = ({ scene, camera, modelContainer, character }) => {
    this.scene = scene
    this.camera = camera
    this.modelContainer = modelContainer
    this.character = character

    this.particleGLTFs.forEach((gltf, index) => {
      const model = gltf.scene

      // Assign textures
      model.traverse((child) => {
        if (child.isMesh) {
          if (index === 0) {
            this.commonMaterial = child.material
            this.commonMaterial.color = new THREE.Color(0x555555)
          } else {
            child.material = this.commonMaterial
          }
        }
      })

      this.particleModels = [...this.particleModels, model]
    })

    this.addParticles()
  }

  /**
   * Randomize particle's transformation/velocity/rotation speed
   * @param particle particle model
   */
  randomizeParticle = (particle) => {
    const { position: characterPos } = this.character
    this.boundaryMaxPos = new THREE.Vector3(characterPos.x + 1, characterPos.y + 1, characterPos.z + 2)
    this.boundaryMinPos = new THREE.Vector3(characterPos.x - 2, characterPos.y - 1, characterPos.z - 0.5)

    const randomPosX = rand(this.boundaryMinPos.x, this.boundaryMaxPos.x)
    const randomPosY = this.boundaryMaxPos.y + Math.random() * 10
    const randomPosZ = rand(this.boundaryMinPos.z, this.boundaryMaxPos.z)
    const randomScale = randVector(this.minScale, this.maxScale)
    const randomVelocity = randVector(this.minVelocity, this.maxVelocity)
    const randomRotSpeed = randVector(this.minRotSpeed, this.maxRotSpeed)

    particle.position.set(randomPosX, randomPosY, randomPosZ)
    particle.scale.set(randomScale.x, randomScale.y, randomScale.z)
    particle.userData = {
      ...particle.userData,
      velocity: randomVelocity,
      rotSpeed: randomRotSpeed,
    }
  }

  /**
   * Add particles
   */
  addParticles = () => {
    times(this.particleCount, (index) => {
      const randomIndex = Math.floor(Math.random() * this.particleModels.length)
      const particle = this.particleModels[randomIndex].clone()

      this.randomizeParticle(particle)

      this.particles = [...this.particles, particle]
      this.character.add(particle)
    })
  }

  /**
   * Update particles
   * @param timeElapsed time consumed for frame rendering
   */
  updateParticles = (timeElapsed) => {
    this.particles.forEach((particle) => {
      const { velocity, rotSpeed } = particle.userData
      particle.position.x -= velocity.x * timeElapsed
      particle.position.y -= velocity.y * timeElapsed
      particle.position.z -= velocity.z * timeElapsed
      particle.rotateX(rotSpeed.x)
      particle.rotateY(rotSpeed.y)
      particle.rotateZ(rotSpeed.z)

      if (particle.position.y < this.boundaryMinPos.y) {
        this.randomizeParticle(particle)
      }
    })
  }

  /**
   * Update
   * @param timeElapsed time consumed for frame rendering
   */
  update = (timeElapsed) => {
    this.updateParticles(timeElapsed)
  }

  /**
   * Dispose
   */
  dispose = () => {
    this.particleModels.forEach((particleModel) => {
      particleModel.traverse((child) => {
        if (child.isMesh) {
          child.geometry.dispose()
          child.material.dispose()
        }
      })
    })

    this.particles.forEach((particle, index) => {
      this.character.remove(particle)
    })

    this.particles = []
  }
}

export default RockParticleSystem
