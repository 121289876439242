import { IDLE_CLIP, SIGNATURE_CLIP } from '../../constants'

export const MEDIA_RECORDER_RECORD_COMPLETE = 'mediarecorder-recordcomplete'
export const MEDIA_RECORDER_RECORD_START = 'mediarecorder-recordstart'
export const MEDIA_RECORDER_PREVIEW_CLOSED = 'mediarecorder-previewclosed'
export const MEDIA_RECORDER_PROGRESS = 'mediarecorder-progress'
export const MEDIA_RECORDER_LOADING = 'mediarecorder-loading'
export const MEDIA_RECORDER_RESET = 'mediarecorder-reset'

class MediaRecorder {
  constructor(ARCanvas) {
    this.ARCanvas = ARCanvas
    this.XR8 = ARCanvas.XR8
    this.status = 'waiting'
    this.init()
  }

  init = () => {
    this.XR8.MediaRecorder.configure({ requestMic: false })
    this.XR8.addCameraPipelineModule(this.XR8.MediaRecorder.pipelineModule())
  }

  // Record button clicked
  clickRecordBtn = () => {
    if (this.status !== 'waiting') {
      return
    }

    this.status = 'active'

    this.recordBtnActiveTimeout = setTimeout(() => {
      this.ARCanvas.addParticles()
      this.ARCanvas.playSFX(true)
      this.ARCanvas.playAnimation(SIGNATURE_CLIP)
      this.startRecording()
    }, 300)
  }

  // Record button released
  releaseRecordBtn = () => {
    if (this.status === 'active') {
      this.cancelActive()
    }

    if (this.status === 'recording') {
      this.endRecording()
      this.ARCanvas.disposeParticles()
      this.ARCanvas.playSFX(false)
      this.ARCanvas.playAnimation(IDLE_CLIP)
      this.XR8.pause()
    }
  }

  // Start recording
  startRecording = () => {
    if (this.status !== 'active') {
      return
    }

    this.status = 'recording'
    window.dispatchEvent(new CustomEvent(MEDIA_RECORDER_RECORD_START))

    this.XR8.MediaRecorder.recordVideo({
      onVideoReady: (result) => {
        //setTimeout(() => (this.recordBtnContainer.style.display = 'none'), 1000)
        window.dispatchEvent(new CustomEvent(MEDIA_RECORDER_RECORD_COMPLETE, { detail: result }))
        this.clearState()
      },
      onStop: () => this.ARCanvas.releaseRecordBtn(),
      onError: () => this.clearState(),
      onProcessFrame: ({ elapsedTimeMs, maxRecordingMs, ctx }) => {
        const timeLeft = 1 - elapsedTimeMs / maxRecordingMs
        window.dispatchEvent(new CustomEvent(MEDIA_RECORDER_PROGRESS, { detail: `${100 * timeLeft}` }))
      },
    })
  }

  // Show loading on the record button
  showLoading = () => {
    if (this.status !== 'recording') {
      return
    }

    window.dispatchEvent(new CustomEvent(MEDIA_RECORDER_LOADING))

    this.status = 'loading'
  }

  // Clear all status of record button
  clearState = () => {
    window.dispatchEvent(new CustomEvent(MEDIA_RECORDER_RESET))

    clearTimeout(this.recordBtnActiveTimeout)
    this.status = 'waiting'
  }

  // Cancel active status of record button
  cancelActive = () => {
    if (this.status !== 'active') {
      return
    }

    clearTimeout(this.recordBtnActiveTimeout)
    this.status = 'waiting'
  }

  // Stop recording
  endRecording = () => {
    if (this.status !== 'recording') {
      return
    }
    this.XR8.MediaRecorder.stopRecording()
    this.showLoading()
  }

  closePreview = () => {
    const previewContainer = document.getElementById('previewContainer')
    const videoPreviewContainer = document.querySelector('#videoPreview')

    videoPreviewContainer.pause()
    videoPreviewContainer.removeAttribute('src')
    previewContainer.style.removeProperty('pointer-events')
    previewContainer.classList.remove('fade-in')
    previewContainer.classList.remove('image-preview')
    previewContainer.classList.remove('video-preview')
    previewContainer.classList.remove('downloaded')
  }
}

export default MediaRecorder
