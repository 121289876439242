import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import PulseArrow from '../common/PulseArrow'
import color from '../../style/color'
import CommonText from './CommonText'
import TransitionContainer from './TransitionContainer'
import { useAppContext } from '../../context/AppContext'
import { ARROW_RIGHT } from '../../constants/index'
import i18next from 'i18next'
import ReactHtmlParser from 'react-html-parser'

const textVariants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
}

const DragButton = ({ onSubmit, label, ...props }) => {
  const dragTrack = useRef()
  const [showDragText, setShowDragText] = useState(true)
  const { t } = useAppContext()

  const handleDrag = useCallback(
    (e, info) => {
      if (info.offset.x >= dragTrack.current.offsetWidth - 60) {
        onSubmit()
      } else {
        if (info.offset.x > 0) setShowDragText(false)
        else setShowDragText(true)
      }
    },
    [onSubmit],
  )

  const handleDragEnd = useCallback((e, info) => {
    if (info.offset.x < dragTrack.current.offsetWidth - 60) setShowDragText(true)
  }, [])

  return (
    <Container preset="slideDownUp" duration={0.3} {...props}>
      <Track ref={dragTrack}>
        <DragText
          as={motion.div}
          fontSize="xs"
          variants={textVariants}
          initial="show"
          animate={showDragText ? 'show' : 'hide'}
        >
          {ReactHtmlParser(t(label))}
          <WhitePulseArrow direction={ARROW_RIGHT} stroke={false} colorful={false} />
        </DragText>
        <Handle
          dragTransition={{
            timeConstant: 200,
            modifyTarget: (v) => {
              if (v >= dragTrack.current.offsetWidth - 60) {
                return v
              } else return 0
            },
          }}
          drag
          dragElastic={false}
          dragMomentum={false}
          dragConstraints={dragTrack}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
        >
          <RedPulseArrow direction={ARROW_RIGHT} stroke={false} />
        </Handle>
      </Track>
    </Container>
  )
}

DragButton.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}

export default DragButton

const DragText = styled(CommonText)(
  ({ nowrap }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 8;
    margin-left: 20px;
    white-space: nowrap;

    span {
      white-space: normal;
    }
  `,
)

const Container = styled(TransitionContainer)(
  () => css`
    max-width: 300px;
    width: 100%;
    height: 60px;
    position: relative;
  `,
)
const Track = styled(motion.div)(
  () => css`
    width: 100%;
    height: 100%;
    position: relative;
    background: ${color.red};
    border-radius: 4px;
  `,
)

const Handle = styled(motion.div)(
  () => css`
    width: 60px;
    height: 60px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    top: 0;
    left: 0;
    z-index: 10;
    &:after {
      position: absolute;
      content: '';
      background: ${color.white};
      border-radius: 80px;
      width: 50px;
      height: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
    }
  `,
)

const WhitePulseArrow = styled(PulseArrow)`
  position: relative;
  justify-content: flex-start;
  margin: 0.5px 0 0 0;
  transform: scale(0.5);
`

const RedPulseArrow = styled(PulseArrow)`
  position: relative;
  z-index: 1;

  & * {
    margin: 1px;
  }
`
