import * as THREE from 'three'
import { TweenMax, Expo } from 'gsap'
import { rand, randVector } from '../../mathUtils'
import { loadGLTF } from '../loadAssetManager'

class IceParticleSystem {
  constructor() {
    this.particlePaths = [
      './assets/models/crystal1.glb',
      './assets/models/crystal2.glb',
      './assets/models/crystal3.glb',
    ]
    this.particleGLTFs = []
    this.particleModels = []
    this.commonMaterial = null
    this.particles = []
    this.initialScale = new THREE.Vector3(1, 1, 1)
    this.maxScale = new THREE.Vector3(40, 40, 40)
    this.minScale = new THREE.Vector3(25, 25, 25)
    this.initialRot = new THREE.Vector3(0, 0, 0)
    this.maxRot = new THREE.Vector3(Math.PI / 6, Math.PI / 6, Math.PI / 6)
    this.minRot = new THREE.Vector3(-Math.PI / 6, -Math.PI / 6, -Math.PI / 6)
    this.forwardFactor = 0.5
    this.posTransitionDur = 0.4 // Seconds for ice's position transition
    this.scaleTransitionDur = 0.5 // Seconds for ice's scale transition
    this.rotTransitionDur = rand(2, 5) // Seconds for ice's rotation transition
    this.addDuration = 0.5 // Seconds for next ice generation
    this.currentTime = 0
  }

  /**
   * Load assets
   * @returns loading promise
   */
  loadAssets = () => {
    return this.particlePaths.map((path) => loadGLTF(path))
  }

  /**
   * Initialize
   */
  init = ({ scene, camera, modelContainer, character }) => {
    this.scene = scene
    this.camera = camera
    this.modelContainer = modelContainer
    this.character = character

    this.particleGLTFs.forEach((gltf, index) => {
      const model = gltf.scene

      // Assign textures
      model.traverse((child) => {
        if (child.isMesh) {
          if (index === 0) {
            this.commonMaterial = child.material
          } else {
            child.material = this.commonMaterial
          }
        }
      })

      this.particleModels = [...this.particleModels, model]
    })
  }

  /**
   * Add particle
   */
  addParticle = () => {
    if (this.particles.length > 30) return

    const randomIndex = Math.floor(Math.random() * this.particleModels.length)
    const particle = this.particleModels[randomIndex].clone()
    particle.scale.set(this.initialScale.x, this.initialScale.y, this.initialScale.z)
    particle.position.set(rand(-0.4, 0.4), -1.5, this.currentTime * this.forwardFactor)

    const targetScale = randVector(this.minScale, this.maxScale)
    const targetPos = new THREE.Vector3(particle.position.x, 0, particle.position.z)
    const targetRot = randVector(this.minRot, this.maxRot)
    this.updateParticle({ object: particle, targetPos, targetScale, targetRot })

    this.particles = [...this.particles, particle]
    this.character.add(particle)
  }

  /**
   * Update particle
   * @param parameter containing object and animation properties
   */
  updateParticle = ({ object, targetPos = null, targetScale = null, targetRot = null }) => {
    if (targetPos)
      TweenMax.to(object.position, this.posTransitionDur, {
        ...targetPos,
        ease: Expo.easeOut,
      })

    if (targetScale)
      TweenMax.to(object.scale, this.scaleTransitionDur, {
        ...targetScale,
        ease: Expo.easeOut,
      })

    if (targetRot)
      TweenMax.to(object.rotation, this.rotTransitionDur, {
        ...targetRot,
        ease: Expo.easeOut,
      })
  }

  /**
   * Update
   * @param timeElapsed time consumed for frame rendering
   */
  update = (timeElapsed) => {
    this.currentTime += timeElapsed

    // Clipping plance update
    if (this.commonMaterial) {
      const localPlane = new THREE.Plane(new THREE.Vector3(0, 0, -1), 1.5)
      this.commonMaterial.clippingPlanes = [localPlane]
    }

    if (this.currentTime > this.particles.length * this.addDuration) this.addParticle()
  }

  /**
   * Dispose
   */
  dispose = () => {
    this.particleModels.forEach((particleModel) => {
      particleModel.traverse((child) => {
        if (child.isMesh) {
          child.geometry.dispose()
          child.material.dispose()
        }
      })
    })

    this.particles.forEach((particle, index) => {
      this.character.remove(particle)
    })

    this.particles = []
    this.currentTime = 0
  }
}

export default IceParticleSystem
