import React, { memo, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import CommonText from '../common/CommonText'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import Container from '../common/ContentContainer'
import Amulet from '../common/Amulet'
import { motion } from 'framer-motion'
import * as variants from '../../style/variants'

const PlayAgain = () => {
  const { t, setNavBtnVisible, setFooterLabelBtnVisible } = useAppContext()

  useEffect(() => {
    setNavBtnVisible(true)
    setFooterLabelBtnVisible(true)
  }, [setFooterLabelBtnVisible, setNavBtnVisible])

  return (
    <StyledContainer>
      <CommonText
        color={color.gold}
        fontFamily={KUNOICHI}
        fontSize="xl"
        shadow="deep-shadow"
        as={motion.h2}
        variants={variants.headline}
        initial="initial"
        animate="animate"
        exit="initial"
      >
        {t('play_again')}
      </CommonText>
      <Amulet showProgress={false} />
      <CommonText
        fontSize="sm"
        shadow="deep-shadow"
        lowercase
        variants={variants.text}
        initial="initial"
        animate="animate"
        exit="initial"
      >
        {t('start_over')}
      </CommonText>
    </StyledContainer>
  )
}

export default memo(PlayAgain)

const StyledContainer = styled(Container)(
  ({ theme }) => css`
    ${CommonText} {
      max-width: 80%;
    }
  `,
)
