import React, { memo, useMemo } from 'react'
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'
import { motion } from 'framer-motion'
import CommonText from '../common/CommonText'
import TransitionContainer from '../common/TransitionContainer'
import { useAppContext } from '../../context/AppContext'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import { stroke } from '../../style/variants'
import PromoVideo from '../../assets/videos/LEGO_ninjagoAR_Promo_16x9.mp4'
import assets from '../../constants/assets'

const { desktopPaper, desktopHeaderBG, desktopBrushStroke, logoNinjago } = assets.images

const DesktopLandingPage = () => {
  const { t } = useAppContext()

  const titleSize = useMemo(() => {
    let mainTitleSize = 'xl'
    let smallTitleSize = 'lg'

    if (window.innerHeight <= 1050) {
      mainTitleSize = 'lg'
      smallTitleSize = 'md'
    }
    if (window.innerHeight <= 750) {
      mainTitleSize = 'md'
      smallTitleSize = 'sm'
    }
    if (window.innerHeight <= 650) {
      mainTitleSize = 'sm'
      smallTitleSize = 'xs'
    }

    return { mainTitleSize, smallTitleSize }
  }, [])

  return (
    <Container>
      <Header>
        <HeaderBackground src={desktopHeaderBG} />
        <LogoNinjago src={logoNinjago} />
      </Header>
      <Main>
        <PlayerContainer>
          <ReactPlayer url={PromoVideo} width="100%" height="100%" loop controls />
        </PlayerContainer>
        <TitleContainer>
          <TitleBrushStroke src={desktopBrushStroke} variants={stroke} initial="initial" animate="enter" exit="exit" />
          <CommonText
            as={TransitionContainer}
            color={color.white}
            fontFamily={KUNOICHI}
            fontSize={titleSize.smallTitleSize}
            fontWeight="100"
            shadow
            preset="headline"
            duration={0.4}
            delay={2}
          >
            {t('desktop_small_title')}
          </CommonText>
          <CommonText
            as={TransitionContainer}
            color={color.darkRed}
            fontFamily={KUNOICHI}
            fontSize={titleSize.mainTitleSize}
            shadow
            preset="headline"
            duration={0.4}
            delay={2.1}
          >
            {t('desktop_main_title')}
          </CommonText>
        </TitleContainer>
      </Main>
    </Container>
  )
}

export default memo(DesktopLandingPage)

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: center / 100% 100% no-repeat url(${desktopPaper});
`

const Header = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 1000;

  @media (min-width: 577px) {
    top: -20px;
  }

  @media (min-width: 993px) {
    top: -50px;
  }

  @media (min-width: 1200px) {
    top: -70px;
  }

  @media (min-width: 1580px) {
    top: -100px;
  }
`

const HeaderBackground = styled.img`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
`

const LogoNinjago = styled.img`
  position: absolute;
  top: 50%;
  left: 0;
  width: auto;
  height: 32px;
  margin: 0 0 0 30px;
  transform: translate(0, -70%);

  @media (min-width: 577px) {
    height: 34px;
    top: calc(50% + 10px);
  }

  @media (min-width: 993px) {
    height: 40px;
    top: calc(50% + 25px);
  }

  @media (min-width: 1200px) {
    height: 46px;
    top: calc(50% + 35px);
  }

  @media (min-width: 1580px) {
    height: 52px;
    top: calc(50% + 50px);
  }
`

const Main = styled.div`
  position: relative;
  width: 90%;
  max-width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 120px 0 20px 0;

  @media (min-width: 577px) {
    padding: 130px 0 20px 0;
    width: ${((((window.innerHeight - 150) * 8) / 10) * 16) / 9}px;
  }

  @media (min-width: 993px) {
    padding: 140px 0 20px 0;
    width: ${((((window.innerHeight - 160) * 8) / 10) * 16) / 9}px;
  }

  @media (min-width: 1200px) {
    padding: 180px 0 20px 0;
    width: ${((((window.innerHeight - 200) * 8) / 10) * 16) / 9}px;
  }

  @media (min-width: 1580px) {
    padding: 200px 0 20px 0;
    width: ${((((window.innerHeight - 220) * 8) / 10) * 16) / 9}px;
  }
`

const PlayerContainer = styled.div`
  width: 100%;
  height: 80%;
`

const TitleContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    min-width: 800px;
    height: 20%;
    max-height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0 0 0;

    ${CommonText} {
      width: 80%;
      text-align: center;
      line-height: 1;
      margin: 0;
      &:nth-child(3) {
        margin-top: 5px;
      }
    }
  `,
)

const TitleBrushStroke = styled(motion.img)(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    height: 100%;
  `,
)
