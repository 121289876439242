import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import color from '../../style/color'
import CommonText from './CommonText'
import TransitionContainer from './TransitionContainer'
import { slideDownUp, slideUpDown } from '../../style/variants'
import { useAppContext } from '../../context/AppContext'
import InputMask from 'react-input-mask'
import { KUNOICHI, SANS_SERIF } from '../../style/type'


const textVariants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
}

const DateInput = ({ onChange, ...props }) => {
  return (
    <Container preset="slideDownUp" duration={0.3} {...props}>
      <InputContainer>
        <InputMask mask="9999" maskPlaceholder="YYYY" alwaysShowMask onChange={onChange} />
      </InputContainer>
    </Container>
  )
}

DateInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  buttonColor: PropTypes.string,
}

export default DateInput

const Container = styled(TransitionContainer)(
  () => css`
    max-width: 300px;
    width: 100%;
    position: relative;

    input {
      position: relative;
      outline: none;
      background: transparent;
      font-family: ${SANS_SERIF};
      border: none;
      color: ${color.gold};
      text-align: center;
      font-size: 32px;
      width: 100%;
      top: -5px;
      &:focus {
        outline: none;
      }
    }
  `,
)


const InputContainer = styled(motion.div)(
  () => css`
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px;
  `,
)
