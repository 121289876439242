import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { motion } from 'framer-motion'
import styled, { css } from 'styled-components/macro'

const TransitionParent = forwardRef(
  ({ children, delay, delayExit, delayChildren, fill, staggerChildren, type, ...props }, ref) => {
    const MotionContainer = motion[type]

    return (
      <MotionContainer
        ref={ref}
        variants={{
          animate: {
            opacity: 1,
            transition: {
              duration: 1,
              delay,
              delayChildren,
              staggerChildren,
            },
          },
          exit: {
            transition: {
              delay: delayExit,
              staggerChildren,
            },
          },
          initial: {
            opacity: 1,
          },
        }}
        //fill={fill.toString()}
        {...props}
      >
        {children}
      </MotionContainer>
    )
  },
)

TransitionParent.propTypes = {
  delay: PropTypes.number,
  delayChildren: PropTypes.number,
  delayExit: PropTypes.number,
  fill: PropTypes.bool,
  inherit: PropTypes.bool,
  staggerChildren: PropTypes.number,
  type: PropTypes.string,
  children: PropTypes.node,
  initial: PropTypes.string,
  animate: PropTypes.string,
}

TransitionParent.defaultProps = {
  animate: 'animate',
  delay: 0,
  delayExit: 0,
  delayChildren: 0.6,
  fill: false,
  inherit: false,
  initial: 'initial',
  staggerChildren: 0.1,
  type: 'div',
}
const StyledTransitionContainer = styled(TransitionParent)`
  ${({ fill, test }) => css`
    ${test &&
    css`
      border: 1px solid red;
    `};

    ${fill &&
    css`
      width: 100%;
      flex: 1;
    `};
  `}
`

export default StyledTransitionContainer
