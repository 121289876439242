import * as THREE from 'three'
import { rand } from '../../mathUtils'
import LinearSpline from './linearSpline'
import PointParticleSystem from './pointParticleSystem'

class EnergyBallParticleSystem extends PointParticleSystem {
  constructor() {
    super()
    this.particlePaths = [
      './assets/textures/energy_ball_particle1.png',
      './assets/textures/energy_ball_particle2.png',
      './assets/textures/energy_ball_particle3.png',
      './assets/textures/energy_ball_particle4.png',
      './assets/textures/energy_ball_particle5.png',
      './assets/textures/energy_ball_particle6.png',
    ]
    this.minSplineSize = 3
    this.maxSplineSize = 18.5
    this.splineSize = this.minSplineSize
  }

  /**
   * Initialize splines
   */
  initSplines = () => {
    this.alphaSpline = new LinearSpline((t, a, b) => {
      return a + t * (b - a)
    })
    this.alphaSpline.addPoint(0.0, 1.0)
    this.alphaSpline.addPoint(0.1, 1.0)
    this.alphaSpline.addPoint(0.5, 1.0)
    this.alphaSpline.addPoint(1.0, 0.0)

    this.colourSpline = new LinearSpline((t, a, b) => {
      const c = a.clone()
      return c.lerp(b, t)
    })
    this.colourSpline.addPoint(0.0, new THREE.Color(0xffffff))
    this.colourSpline.addPoint(0.5, new THREE.Color(0xffffff))

    this.updateSizeSpline()
  }

  /**
   * Update size spline
   */
  updateSizeSpline = () => {
    this.sizeSpline = new LinearSpline((t, a, b) => {
      return a + t * (b - a)
    })
    this.sizeSpline.addPoint(0.0, this.splineSize)
    this.sizeSpline.addPoint(1.0, this.splineSize)
  }

  /**
   * Add particles
   * @param timeElapsed time consumed for frame rendering
   */
  addParticles = (timeElapsed) => {
    if (!this.gdfsghk) {
      this.gdfsghk = 30.0
    }
    this.gdfsghk += timeElapsed
    const n = Math.floor(this.gdfsghk * 0.065)
    this.gdfsghk -= n / 75.0

    for (let i = 0; i < n; i++) {
      const life = (Math.random() * 0.2 + 0.2) * 3.0

      this.particles.push({
        index: Math.floor(Math.random(this.particlePaths.length)),
        position: new THREE.Vector3(
          (Math.random() * 1 - 0.5) * 0.01,
          (Math.random() * 1 - 0.5) * 0.01,
          (Math.random() * 1 - 0.5) * 0.01,
        ),
        size: rand(0.09, 0.1),
        colour: new THREE.Color(),
        alpha: 1.0,
        life: life,
        maxLife: life,
        rotation: Math.random() * 2.0 * Math.PI,
        velocity: new THREE.Vector3(Math.random() * 0.02, Math.random() * 0.02, Math.random() * 0.02),
      })
    }
  }

  update(timeElapsed) {
    super.update(timeElapsed)

    this.currentTime += timeElapsed

    if (this.currentTime > 2) {
      this.splineSize = Math.min(this.maxSplineSize, this.splineSize + timeElapsed * 40)
      this.updateSizeSpline()
    }
  }

  dispose() {
    super.dispose()
    this.splineSize = this.minSplineSize
  }
}

export default EnergyBallParticleSystem
