import * as THREE from 'three'
import times from 'lodash/times'
import { loadTexture } from '../loadAssetManager'

const VERTEX_SHADER = `
uniform float pointMultiplier;

attribute float size;
attribute float angle;
attribute vec4 colour;

varying vec4 vColour;
varying vec2 vAngle;

void main() {
  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);

  gl_Position = projectionMatrix * mvPosition;
  gl_PointSize = size * pointMultiplier / gl_Position.w;

  vAngle = vec2(cos(angle), sin(angle));
  vColour = colour;
}`

const FRAG_SHADER = `

uniform sampler2D diffuseTexture;

varying vec4 vColour;
varying vec2 vAngle;

void main() {
  vec2 coords = (gl_PointCoord - 0.5) * mat2(vAngle.x, vAngle.y, -vAngle.y, vAngle.x) + 0.5;
  gl_FragColor = texture2D(diffuseTexture, coords) * vColour;
}`

class PointParticleSystem {
  constructor() {
    this.blending = THREE.NormalBlending
    this.uniforms = []
    this.pointMaterials = []
    this.particleTextures = []
    this.pointMeshes = []
    this.particles = []
    this.currentTime = 0
  }

  /**
   * Load assets
   * @returns loading promise
   */
  loadAssets = () => {
    return [...this.particlePaths.map((path) => loadTexture(path))]
  }

  /**
   * Initialize
   */
  init({ scene, camera, modelContainer, source, deltaPos, destWorldPos = null }) {
    this.scene = scene
    this.camera = camera
    this.modelContainer = modelContainer
    this.source = source
    this.deltaPos = deltaPos
    this.destWorldPos = destWorldPos

    this.pointGeometry = new THREE.BufferGeometry()
    this.pointGeometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3))
    this.pointGeometry.setAttribute('size', new THREE.Float32BufferAttribute([], 1))
    this.pointGeometry.setAttribute('colour', new THREE.Float32BufferAttribute([], 4))
    this.pointGeometry.setAttribute('angle', new THREE.Float32BufferAttribute([], 1))

    this.particleTextures.forEach((particleTexture, index) => {
      this.uniforms[index] = {
        diffuseTexture: {
          value: particleTexture,
        },
        pointMultiplier: {
          value: window.innerHeight / (2.0 * Math.tan((0.5 * 60.0 * Math.PI) / 180.0)),
        },
      }

      this.pointMaterials[index] = new THREE.ShaderMaterial({
        uniforms: this.uniforms[index],
        vertexShader: VERTEX_SHADER,
        fragmentShader: FRAG_SHADER,
        blending: this.blending,
        depthTest: true,
        depthWrite: false,
        transparent: true,
        vertexColors: true,
      })

      this.pointMeshes[index] = new THREE.Points(this.pointGeometry, this.pointMaterials[index])
      this.pointMeshes[index].position.x -= this.deltaPos.x
      this.pointMeshes[index].position.y -= this.deltaPos.y
      this.pointMeshes[index].position.z -= this.deltaPos.z

      this.source.add(this.pointMeshes[index])
    })

    this.initSplines()

    this.updateGeometry()
  }

  /**
   * Initialize splines
   */
  initSplines = () => {
    // TODO
  }

  /**
   * Add particles
   * @param timeElapsed time consumed for frame rendering
   */
  addParticles = (timeElapsed) => {
    // TODO
  }

  /**
   * Update geometries of particles
   */
  updateGeometry = () => {
    let index = 0
    const positions = []
    const sizes = []
    const colours = []
    const angles = []

    for (let p of this.particles) {
      index = p.index
      positions.push(p.position.x, p.position.y, p.position.z)
      colours.push(p.colour.r, p.colour.g, p.colour.b, p.alpha)
      sizes.push(p.currentSize)
      angles.push(p.rotation)
    }

    this.pointMeshes[index].geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3))
    this.pointMeshes[index].geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1))
    this.pointMeshes[index].geometry.setAttribute('colour', new THREE.Float32BufferAttribute(colours, 4))
    this.pointMeshes[index].geometry.setAttribute('angle', new THREE.Float32BufferAttribute(angles, 1))

    this.pointMeshes[index].geometry.attributes.position.needsUpdate = true
    this.pointMeshes[index].geometry.attributes.size.needsUpdate = true
    this.pointMeshes[index].geometry.attributes.colour.needsUpdate = true
    this.pointMeshes[index].geometry.attributes.angle.needsUpdate = true
  }

  /**
   * Update particles
   * @param timeElapsed time consumed for frame rendering
   */
  updateParticles = (timeElapsed) => {
    this.particles = this.particles.filter((p) => {
      p.life -= timeElapsed
      return p.life > 0.0
    })

    for (let p of this.particles) {
      const t = 1.0 - p.life / p.maxLife

      p.rotation += timeElapsed * 0.5
      p.alpha = this.alphaSpline.get(t)
      p.currentSize = p.size * this.sizeSpline.get(t)
      p.colour.copy(this.colourSpline.get(t))

      p.position.add(p.velocity.clone().multiplyScalar(timeElapsed * 1.5))

      const drag = p.velocity.clone()
      drag.multiplyScalar(timeElapsed * 0.1)
      drag.x = Math.sign(p.velocity.x) * Math.min(Math.abs(drag.x), Math.abs(p.velocity.x))
      drag.y = Math.sign(p.velocity.y) * Math.min(Math.abs(drag.y), Math.abs(p.velocity.y))
      drag.z = Math.sign(p.velocity.z) * Math.min(Math.abs(drag.z), Math.abs(p.velocity.z))
      p.velocity.sub(drag)
    }

    this.particles.sort((a, b) => {
      const d1 = this.camera.position.distanceTo(a.position)
      const d2 = this.camera.position.distanceTo(b.position)

      if (d1 > d2) {
        return -1
      }

      if (d1 < d2) {
        return 1
      }

      return 0
    })
  }

  /**
   * Update
   * @param timeElapsed time consumed for frame rendering
   */
  update(timeElapsed) {
    this.addParticles(timeElapsed)
    this.updateParticles(timeElapsed)
    this.updateGeometry()
  }

  /**
   * Dispose
   */
  dispose() {
    if (!this.pointMeshes.length) return

    this.pointGeometry.dispose()
    times(this.particlePaths.length, (i) => {
      this.pointMaterials[i].dispose()
      this.particleTextures[i].dispose()
      this.source.remove(this.pointMeshes[i])
    })

    this.pointMaterials = []
    this.pointMeshes = []
    this.currentTime = 0
  }
}

export default PointParticleSystem
