import { css } from 'styled-components'

import color from './color'
import layout from '../constants/layout'
import { rem } from 'polished'
import fontSizes from './type'
import { map } from 'lodash'

const convertCase = (str) => str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)

export default css`
  :root {
    /* Color */
    ${map(color, (val, i) => {
      return css`--color-${i}: ${val};`
    })}

    /* Layout */
     ${map(layout, (val, i) => {
      return css`--${convertCase(i)}: ${val};`
    })}

    /* Font sizes */
    ${map(fontSizes, (val, i) => {
      return css`--font-size-${i}: ${rem(val)};`
    })}
  }
`
