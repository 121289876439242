import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import times from 'lodash/times'
import { Carousel } from 'react-responsive-carousel'
import { useAppContext } from '../../context/AppContext'
import Modal from '../common/Modal'
import CommonText from '../common/CommonText'
import RecordButton from '../common/RecordButton'
import RuntimeError from '../common/RuntimeError'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import { MODELS, LDC_BACKDROPS, IS_LDC, NAVIGATIONS, AR_VIEW } from '../../constants'
import { AnimatePresence, motion } from 'framer-motion'
import { simple, downTop, topDown } from '../../style/variants'
import { MEDIA_RECORDER_RECORD_COMPLETE } from '../../utils/AR/mediaRecorder'
import useCapture from '../../hooks/useCapture'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import analytics from '../../utils/ga'
import { ACTION_SCAN_TARGET } from '../../constants/analytics' // requires a loader
import assets from '../../constants/assets'

const { scanBar } = assets.images

const ARView = () => {
  const [instructionModalVisible, setInstructionModalVisible] = useState(false)
  const [scanBarVisible, setScanBarVisible] = useState(false)
  const [virtuePosModalVisible, setVirtuePosModalVisible] = useState(false)
  const [recordButtonVisible, setRecordButtonVisible] = useState(false)
  const [activeModel, setActiveModel] = useState(null)
  const [activeBackdrop, setActiveBackdrop] = useState(null)
  const [nextBackdropModelIndex, setNextBackdropModelIndex] = useState(-1)
  const [runtimeError, setRuntimeError] = useState(false)
  const { t, setNavBtnVisible, setCustomNavBtn, setFooterLabelBtnVisible, arViewer } = useAppContext()
  const { setCurrentSegment, requiresReset, resetCapture, models: completedModels } = useCapture()
  const arCanvas = useRef()
  const prevActiveBackdropIndex = useRef(-1)
  const prevActiveModelIndex = useRef(-1)
  const arTimeout = useRef(null)

  const virtueTexts = t('virtue_texts', { returnObjects: true })

  const modelName = activeModel?.name

  const handleResetState = useCallback(() => {
    arViewer.current.reset()
    setInstructionModalVisible(false)
    setScanBarVisible(true)
    setVirtuePosModalVisible(false)
    setRecordButtonVisible(false)
    setNavBtnVisible(false)
    setCustomNavBtn(null)
    setFooterLabelBtnVisible(false)
    setActiveModel(null)
    setActiveBackdrop(null)
    setNextBackdropModelIndex(-1)
    arViewer.current.enableScan(true)
    prevActiveBackdropIndex.current = -1
    prevActiveModelIndex.current = -1
  }, [arViewer, setFooterLabelBtnVisible, setCustomNavBtn, setNavBtnVisible])

  // Listener for 8thwall runtime error
  const handleRuntimeError = useCallback((error) => {
    console.error('[RuntimeError] XR caught an error; stopping.', error)
    setRuntimeError(true)
  }, [])

  // Listener for instruction modal close
  const handleInstructionModalClose = useCallback(() => {
    // Process after closing instruction modal
    setNavBtnVisible(false)
    setCustomNavBtn(null)
    setInstructionModalVisible(false)
    setScanBarVisible(true)
    setVirtuePosModalVisible(false)

    arViewer.current.enableScan(true)
  }, [arViewer, setCustomNavBtn, setNavBtnVisible])

  // Listener for camera ready
  const handleCameraReady = useCallback(() => {
    setInstructionModalVisible(true)
    setNavBtnVisible(true)
    setCustomNavBtn({ type: 'allowHexagonBtn', onClick: handleInstructionModalClose })
  }, [handleInstructionModalClose, setCustomNavBtn, setNavBtnVisible])

  // Listener for virtue carousel change
  const handleVirtueCarouselChange = useCallback(
    (index) => {
      const modelIndex = MODELS.findIndex((model) => model.name === activeBackdrop.models[index].name)

      setActiveModel(MODELS[modelIndex])
      arViewer.current.setActiveModelIndex(modelIndex)
    },
    [activeBackdrop, arViewer],
  )

  // Listener for virtue pos modal close
  const handleVirtuePosModalClose = useCallback(() => {
    // Process after closing virtue pos modal
    setNavBtnVisible(false)
    setCustomNavBtn(null)
    setVirtuePosModalVisible(false)
    setRecordButtonVisible(true)
    if (arTimeout.current) clearTimeout(arTimeout.current)
    arTimeout.current = setTimeout(() => {
      arViewer.current.enableAR(true)
    }, 250)
  }, [arViewer, setCustomNavBtn, setNavBtnVisible])

  // Listener for target scaned
  const handleTargetScan = useCallback(
    (name) => {
      let modelIndex = -1
      let nextBGModelIndex = -1

      if (IS_LDC) {
        const ldcBGIndex = LDC_BACKDROPS.findIndex((ldcBG) => ldcBG.name === name)

        if (ldcBGIndex === -1 || ldcBGIndex === prevActiveBackdropIndex.current) return

        const ldcBG = LDC_BACKDROPS[ldcBGIndex]
        modelIndex = MODELS.findIndex((model) => model.name === LDC_BACKDROPS[ldcBGIndex].models[0].name)

        arViewer.current.enableAR(false)
        setActiveBackdrop(ldcBG)

        // Determine next backdrop model
        for (let i = 0; i < ldcBG.models.length; i++) {
          const completed = completedModels.some((completedModel) => completedModel.name === ldcBG.models[i].name)

          if (!completed) {
            nextBGModelIndex = i
            break
          }
        }
        if (nextBGModelIndex !== -1)
          modelIndex = MODELS.findIndex((model) => model.name === ldcBG.models[nextBGModelIndex].name)

        setNextBackdropModelIndex(nextBGModelIndex)

        prevActiveBackdropIndex.current = ldcBGIndex
      } else {
        modelIndex = MODELS.findIndex((model) => model.name === name)

        if (modelIndex === -1 || modelIndex === prevActiveModelIndex.current) return

        arViewer.current.enableAR(false)
        setActiveBackdrop(null)

        prevActiveModelIndex.current = modelIndex
      }

      if (modelIndex !== -1) {
        setActiveModel(MODELS[modelIndex])
        arViewer.current.setActiveModelIndex(modelIndex)
      }

      analytics.trackEvent({ eventCategory: NAVIGATIONS[AR_VIEW].ga_category, action: ACTION_SCAN_TARGET, label: name })

      setNavBtnVisible(true)
      setScanBarVisible(false)
      setVirtuePosModalVisible(true)
      setRecordButtonVisible(false)
      if (!IS_LDC || (IS_LDC && nextBGModelIndex !== -1)) {
        setCustomNavBtn({ type: 'allowHexagonBtn', onClick: handleVirtuePosModalClose })
        setNavBtnVisible(true)
      } else {
        setNavBtnVisible(false)
      }
    },
    [arViewer, completedModels, handleVirtuePosModalClose, setCustomNavBtn, setNavBtnVisible],
  )

  // Listener for record button down
  const handleRecordBtnDown = useCallback(
    (e) => {
      e.preventDefault()
      arViewer.current.clickRecordBtn()
    },
    [arViewer],
  )

  // Listener for record button up
  const handleRecordBtnUp = useCallback(() => {
    arViewer.current.releaseRecordBtn()
  }, [arViewer])

  // Listener for record completion
  const handleRecordComplete = useCallback(
    async ({ detail: { videoBlob } }) => {
      setNavBtnVisible(true)
      setCustomNavBtn(null)
      setFooterLabelBtnVisible(true)
      setRecordButtonVisible(false)
      setCurrentSegment({
        videoBlob,
        name: modelName,
      })
    },
    [modelName, setCurrentSegment, setCustomNavBtn, setFooterLabelBtnVisible, setNavBtnVisible],
  )

  //Listen for reset
  useEffect(() => {
    if (requiresReset) {
      handleResetState()
      resetCapture(false)
    }
  }, [handleResetState, requiresReset, resetCapture])

  // Initialize ar viewer
  useEffect(() => {
    arViewer.current.onCameraReady = handleCameraReady
    arViewer.current.onTargetDetect = handleTargetScan
    arViewer.current.onRuntimeError = handleRuntimeError
    arViewer.current.init(arCanvas.current)

    setNavBtnVisible(false)
    setCustomNavBtn(null)
    setFooterLabelBtnVisible(false)

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (arViewer.current) arViewer.current.dispose()
    }
    //This should only run once
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    arViewer.current.onCameraReady = handleCameraReady
    arViewer.current.onTargetDetect = handleTargetScan
    window.addEventListener(MEDIA_RECORDER_RECORD_COMPLETE, handleRecordComplete)
    return () => {
      window.removeEventListener(MEDIA_RECORDER_RECORD_COMPLETE, handleRecordComplete)
    }
  }, [arViewer, handleCameraReady, handleRecordComplete, handleTargetScan])

  return (
    <Container>
      {runtimeError && <RuntimeError />}
      {!runtimeError && (
        <AnimatePresence exitBeforeEnter>
          {instructionModalVisible && (
            <InstructionModal key="instructionModal" variants={downTop}>
              <CommonText color={color.gold} fontFamily={KUNOICHI} fontSize="lg" shadow="deep-shadow">
                {t('ar_instruction')}
              </CommonText>
            </InstructionModal>
          )}
        </AnimatePresence>
      )}
      {!runtimeError && (
        <AnimatePresence exitBeforeEnter>
          {scanBarVisible && (
            <ScanBar key="scanBar" src={scanBar} variants={simple} initial="hidden" animate="visible" exit="hidden" />
          )}

          {virtuePosModalVisible && !activeBackdrop && activeModel && (
            <VirtuePosModal
              key="virtuePoseModal"
              onClose={handleVirtuePosModalClose}
              background={activeModel['virtue_gradient']}
              variants={topDown}
            >
              <PosFigure src={activeModel['virtue_pos']} />
              {times(virtueTexts[activeModel['name']].length, (index) => {
                return (
                  <CommonText
                    color={activeModel['virtue_font_color']}
                    fontFamily={KUNOICHI}
                    fontSize="md"
                    key={`virtue-text-${index}`}
                  >
                    {virtueTexts[activeModel['name']][index]}
                  </CommonText>
                )
              })}
              <VirtueIcon src={activeModel['virtue_icon']} />
            </VirtuePosModal>
          )}
          {virtuePosModalVisible && activeBackdrop && nextBackdropModelIndex === -1 && (
            <NoVirtueText
              color={color.gold}
              fontFamily={KUNOICHI}
              fontSize="xl"
              key="no-virtue-left-text"
              shadow="deep-shadow"
            >
              {t('no_virtue_text')}
            </NoVirtueText>
          )}

          {virtuePosModalVisible && activeBackdrop && activeModel && nextBackdropModelIndex !== -1 && (
            <CarouselWrapper>
              <Carousel
                showThumbs={false}
                infiniteLoop
                centerMode
                showStatus={false}
                showIndicators={false}
                swipeable={false}
                centerSlidePercentage={60}
                selectedItem={nextBackdropModelIndex}
                renderArrowPrev={(onClickHandler, hasPrev, label) => (
                  <PrevArrowButton type="button" onClick={onClickHandler} title={label}>
                    <CommonText shadow="simple-shadow" fontSize="xxl">
                      {'<'}
                    </CommonText>
                  </PrevArrowButton>
                )}
                renderArrowNext={(onClickHandler, hasNext, label) => (
                  <NextArrowButton type="button" onClick={onClickHandler} title={label}>
                    <CommonText shadow="simple-shadow" fontSize="xxl">
                      {'>'}
                    </CommonText>
                  </NextArrowButton>
                )}
                onChange={handleVirtueCarouselChange}
              >
                {activeBackdrop.models.map((model, index) => {
                  return (
                    <VirtuePosSlideModal
                      key={`virtuePoseSlideModal-${index}`}
                      onClose={handleVirtuePosModalClose}
                      background={model['virtue_gradient']}
                      disableAnywhereClick
                      variants={topDown}
                    >
                      <PosFigure src={model['virtue_pos']} />
                      {times(virtueTexts[model['name']].length, (index) => {
                        return (
                          <CommonText
                            color={model['virtue_font_color']}
                            fontFamily={KUNOICHI}
                            fontSize="md"
                            key={`virtue-text-${index}`}
                          >
                            {virtueTexts[model['name']][index]}
                          </CommonText>
                        )
                      })}
                      <VirtueIcon src={model['virtue_icon']} />
                    </VirtuePosSlideModal>
                  )
                })}
              </Carousel>
            </CarouselWrapper>
          )}
          {recordButtonVisible && (
            <StyledRecordButton
              key="recordButton"
              onMouseDown={handleRecordBtnDown}
              onMouseUp={handleRecordBtnUp}
              variants={simple}
              initial="hidden"
              animate="visible"
              exit="hidden"
            />
          )}
        </AnimatePresence>
      )}
      <CanvasContainer>
        <Canvas ref={arCanvas} />
      </CanvasContainer>
    </Container>
  )
}

export default memo(ARView)

const Container = styled.div`
  position: absolute;
  top: 60px;
  width: 100%;
  height: ${window.innerHeight - ((window.innerWidth / 4) * 5 + 160) <= 50
    ? window.innerHeight - 165
    : (window.innerWidth / 4) * 5}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const InstructionModal = styled(Modal)`
  position: absolute;
  width: 90%;
  height: calc(100% - 140px);
  padding: 20px;
  z-index: 999;
`

const ScanBar = styled(motion.img)`
  position: fixed;
  top: 0;
  left: -10px;
  width: 10px;
  height: 100%;
  z-index: 999;
  animation: sweep 6s infinite 2s;

  @keyframes sweep {
    0% {
      left: -10px;
      transform: scaleX(1);
    }
    49.99% {
      left: ${window.innerWidth}px;
      transform: scaleX(1);
    }
    50% {
      left: ${window.innerWidth}px;
      transform: scaleX(-1);
    }
    99.99% {
      left: -10px;
      transform: scaleX(-1);
    }
    100% {
      left: -10px;
      transform: scaleX(1);
    }
  }
`

const NoVirtueText = styled(CommonText)`
  z-index: 1005;
`

const VirtuePosModal = styled(Modal)`
  position: absolute;
  width: 70%;
  max-width: 240px;
  height: 80%;
  max-height: 320px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 70px 20px;
  background: ${(props) => (props.background ? props.background : 'none')};
  box-shadow: inset 0px 0px 32px 8px rgba(0, 0, 0, 0.75);
  z-index: 999;
`

const VirtuePosSlideModal = styled(Modal)`
  width: 60vw;
  max-width: 240px;
  height: 75%;
  max-height: 320px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 70px 20px;
  background: ${(props) => (props.background ? props.background : 'none')};
  box-shadow: inset 0px 0px 32px 8px rgba(0, 0, 0, 0.75);
  transition: width 0.5s, height 0.5s, max-width 0.5s, max-height 0.5s;
  z-index: 999;
  opacity: 0.4;

  .selected > & {
    width: 70vw;
    max-width: 270px;
    height: 85%;
    max-height: 360px;
    z-index: 1000;
    opacity: 1;
  }
`

const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1005;
  direction: ltr;

  .carousel-root {
    width: 100%;
  }

  .carousel.carousel-slider,
  .slider-wrapper {
    overflow: visible;
  }

  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const PrevArrowButton = styled.button`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 0;
  width: auto;
  height: auto;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  transform: translate(0, -50%);
  direction: ltr;

  & > p {
    font-stretch: condensed;
  }
`

const NextArrowButton = styled.button`
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 0;
  width: auto;
  height: auto;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  transform: translate(0, -50%);
  direction: ltr;

  & > p {
    font-stretch: condensed;
  }
`

const PosFigure = styled.img`
  width: auto !important;
  height: 50%;
  max-height: 150px;
`

const VirtueIcon = styled.img`
  position: absolute;
  bottom: 0;
  width: auto !important;
  height: 100px;
  transform: translate(0, 35px);
`

const StyledRecordButton = styled(RecordButton)`
  z-index: 1100;
`

const CanvasContainer = styled.div`
  position: absolute !important;
  width: 100%;
  height: 100%;
`

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`
