import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import PulseArrow from '../../common/PulseArrow'
import { ReactComponent as ScrollEnd } from '../../../assets/images/intro/scroll-end.svg'
import { motion } from 'framer-motion'
import TransitionContainer from '../../common/TransitionContainer'
import CommonText from '../../common/CommonText'

import assets from '../../../constants/assets'

const { scrollSurfaceSrc } = assets.images

const transitionEnter = {
  duration: 1,
  type: 'tween',
  ease: 'anticipate',
  delay: 0.2,
}
const transitionExit = {
  ...transitionEnter,
  duration: 0.5,
}

export const scrollOutUp = {
  initial: {
    top: 'calc(50% - 50px)',
  },
  enter: {
    top: -20,
    transition: transitionEnter,
  },
  exit: {
    top: 'calc(50% - 50px)',
    transition: transitionExit,
  },
}

export const scrollOutDown = {
  initial: {
    top: 'calc(50% + 50px)',
  },
  enter: {
    top: 'calc(100% + 20px)',
    transition: transitionEnter,
  },
  exit: {
    top: 'calc(50% + 50px)',
    transition: transitionExit,
  },
}

const topClip = 47
const bottomClip = 52
const clipPathInit = `polygon(0% ${topClip}%, 100% ${topClip}%, 100% ${bottomClip}%, 0% ${bottomClip}%)`
export const surface = {
  initial: {
    clipPath: clipPathInit,
  },
  enter: {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    transition: transitionEnter,
  },
  exit: {
    clipPath: clipPathInit,
    transition: transitionExit,
  },
}

const Scroll = ({ children }) => {
  const scrollContentRef = useRef()
  const scrollTicker = useRef()
  const [showScrollIndicator, setShowScrollIndicator] = useState(true)
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false)

  useEffect(() => {
    if (scrollTicker.current) clearInterval(scrollTicker.current)
    if (showScrollIndicator) {
      scrollTicker.current = setInterval(() => {
        if (scrollContentRef.current?.scrollTop > 0) {
          setShowScrollIndicator(false)
        }
      }, 250)
    }
  }, [showScrollIndicator])

  useEffect(() => {
    const hasVerticalScrollbar = scrollContentRef.current.scrollHeight > scrollContentRef.current.clientHeight

    setHasVerticalScrollbar(hasVerticalScrollbar)
  }, [])

  return (
    <Container preset="scale" duration={0.2} exitDelay={transitionExit.duration}>
      <ScrollSurface variants={surface} initial="initial" animate="enter" exit="exit">
        <ScrollContainer ref={scrollContentRef}>
          <div>{children}</div>
        </ScrollContainer>
      </ScrollSurface>
      <StyledScrollEnd variants={scrollOutUp} initial="initial" animate="enter" exit="exit">
        <ScrollEnd />
      </StyledScrollEnd>
      <StyledScrollEnd flip variants={scrollOutDown} initial="initial" animate="enter" exit="exit">
        <ScrollEnd />
      </StyledScrollEnd>
      <StyledPulseArrow show={showScrollIndicator && hasVerticalScrollbar} />
    </Container>
  )
}

Scroll.propTypes = {
  children: PropTypes.node,
}

export default Scroll

const Container = styled(TransitionContainer)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    position: relative;
  `,
)

const StyledScrollEnd = styled(motion.div)(
  ({ flip }) => css`
    position: absolute;
    left: 50%;
    width: 110%;
    transform: translate(-50%, -50%);

    ${
      flip
        ? css`
            top: '${scrollOutDown.initial.top}';
          `
        : css`
            top: '${scrollOutUp.initial.top}';
          `
    }
    
    svg {
      width: 100%;
      height: 100%;
      ${
        flip &&
        css`
          transform: rotate(180deg);
        `
      }
  `,
)

//get it?
const ScrollContainer = styled.div(
  ({ theme }) => css`
    overflow-x: scroll;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 15% 0 10%;
    height: 100%;
    width: 95%;
    ${CommonText} {
      display: block;
      margin-bottom: 5px;

      &:nth-child(1) {
        margin-bottom: 0;
      }
      &:nth-child(2) {
        line-height: 0.95;
        margin-bottom: 10px;
      }
    }
  `,
)

const ScrollSurface = styled(motion.div)(
  () => css`
    position: absolute;
    left: 5px;
    right: 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url('${scrollSurfaceSrc}');
    background-repeat: no-repeat;
    clip-path: ${clipPathInit};
  `,
)

const StyledPulseArrow = styled(PulseArrow)`
  bottom: -15px;
  left: 4px;
`
