import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import i18n from 'i18next'
import CommonText from '../common/CommonText'
import { useAppContext } from '../../context/AppContext'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import TransitionContainer from '../common/TransitionContainer'
import { wu, stroke } from '../../style/variants'
import assets from '../../constants/assets'

const { masterWu, brushStroke } = assets.images

const HomeLockup = ({}) => {
  const { t } = useAppContext()

  return (
    <Container>
      <TitleContainer>
        <TitleBrushStroke src={brushStroke} variants={stroke} initial="initial" animate="enter" exit="exit" />
        <CommonText
          as={TransitionContainer}
          color={color.white}
          fontFamily={KUNOICHI}
          fontSize={i18n.language === 'jp' ? 'md' : 'lg'}
          shadow
          preset="headline"
          duration={0.4}
          delay={2}
        >
          {t('home_small_title')}
        </CommonText>
        <CommonText
          as={TransitionContainer}
          color={color.gold}
          fontFamily={KUNOICHI}
          fontSize={i18n.language === 'jp' ? 'lg' : 'xl'}
          shadow
          preset="headline"
          duration={0.4}
          delay={2.1}
        >
          {t('home_main_title')}
        </CommonText>
      </TitleContainer>
      <WuContainer variants={wu} initial="initial" animate="enter" exit="exit">
        <WuImage src={masterWu} />
      </WuContainer>
    </Container>
  )
}

HomeLockup.propTypes = {}

export default HomeLockup

const Container = styled(motion.div)(
  ({ theme }) => css`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    width: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
  `,
)

const TitleContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    right: 0;
    bottom: 150px;
    width: 100%;
    height: 100%;
    max-height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${CommonText} {
      width: 80%;
      text-align: center;
      line-height: 1;
      margin: 0;
      &:nth-child(3) {
        margin-top: 5px;
      }
    }
  `,
)

const TitleBrushStroke = styled(motion.img)(
  ({ theme }) => css`
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    height: 100%;
    position: absolute;
  `,
)

const WuContainer = styled(motion.div)(
  ({ theme }) => css`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 341px;
    direction: ltr !important;
  `,
)

const WuImage = styled.img(
  ({ theme }) => css`
    position: relative;
    left: 50%;
    width: auto;
    height: calc(100vh - 520px);
    min-height: 200px;
    max-height: 400px;
    transform-origin: center 81%;
    transform: translateX(-45%) translateY(calc(20% + (100vw - 360px) / 50));
  `,
)
