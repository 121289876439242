export const TRANSLATIONS_CN = {
  browser_change: '用Safari浏览器获得最佳体验',
  your_journey: '正在',
  is_loading: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;启程',
  consent: '我们与您一样希望您的孩子安全上网。请告诉我们您的出生年份，以验证您的监护人身份。',
  consent_accept_age: '确定',
  consent_accept: '我同意',
  consent_deny: '我不同意',
  consent_agree:
    '我同意录制我和/或我的孩子的视频，用于收集所有黄金碎片，制作黄金忍者修炼大作战视频。我知晓我可以随时撤回我的许可。如果有孩子出现在视频中，我确定我就是孩子的父母或监护人<a href="/legal-cn.html" target="_blank">。点击此处查看更多信息。</a>',
  consent_deny_age_page: '对不起，您需要父母或监护人的陪同才能参与体验。',
  consent_deny_consent_page:
    '很抱歉，但本体验需要我们录制并保存您的视频，以为您制作电子互动视频。请前往<a href="https://www.ninjago.com" target="_blank">Ninjago.com</a>继续您的体验之旅。',
  terms_service:
    '勾选即表示您已同意<a href="https://www.lego.com/zh-cn/page/terms-and-conditions" target="_blank">使用条例</a>、<br/><a href="https://www.lego.com/zh-cn/legal/notices-and-policies/privacy-policy/" target="_blank">隐私政策</a>和<a href="https://www.lego.com/zh-cn/cookie-policy" target="_blank">Cookie政策</a>',
  rotate_mobile: '请旋转屏幕',
  intro: {
    header: {
      ninjago: '忍者挑战',
      title: '吴大师的黄金护身符',
    },
    content: [
      '邪恶的加满都魔王把吴大师的黄金护身符粉碎成六块，藏在了世界各地不同的地方！',
      '吴大师需要你的帮助来找回这些黄金碎片，恢复黄金护身符并将忍术继续发扬光大。',
      '这就是你的挑战。',
    ],
  },
  runtime_error: '出错了',
  no_virtue_text: '切换背景，寻找剩下的3个黄金碎片',
  retake: '重拍',
  done: '继续',
  finish_early: '不，谢谢，我已经做完了。',
  finish_early_drag: '拖动以结束',
  success: '成功！',
  quest_complete: '完成挑战！',
  success_text: '继续解锁下一个黄金护身符碎片！',
  quest_complete_text:
    '祝贺你！你已经收集完所有的黄金护身符碎片，成功完成了你的挑战！<br/><br/>点击下方下载按钮以接收你的视频！',
  play_again: '再玩一次吗？',
  start_over: '从头开始，切换角色或重新拍一组照片！',
  processing: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;上传',
  processing_video: '正在加载你的视频',
  skip_sure: '你确定吗？',
  skip_text:
    '你还没收集完所有的黄金护身符碎片！吴大师还需要你的帮助！你仍然会收到你的幻影忍者挑战小视频，但它不是完整的。',
  cancel: '取消',
  tap: '下载 ',
  download_video: ' 你的视频',
  home_small_title: '忍者挑战',
  home_main_title: '吴大师的黄金护身符',
  desktop_small_title: '黄金忍者修炼大作战虚拟体验专为移动设备设计。快来乐高探索中心一起变身忍者吧！',
  desktop_main_title: 'As always, be Ninja!',
  intro_title: '吴大师的黄金护身符',
  guide_title: '找到黄金碎片！',
  guide_text: '跟屏幕上出现的忍者拍照，解锁拥有不同特质的黄金护身符碎片。',
  guide_luck: '祝你好运！',
  camera_access: '请开启相机权限，以便继续！',
  ar_instruction: '用相机对准每个场景，解锁黄金护身符碎片!',
  tap_start: '按住开始',
  virtue_texts: {
    kai: ['勇敢无穷尽，凯赋予你勇气，你的火焰熊熊燃烧，顽强战胜所有恶魔！'],
    lloyd: ['向左看看，向右看看，沉思一下 – 走为上策！'],
    nya: ['向上看看，向下看看，寻找快乐，开心起舞！'],
    jay: ['好的忍者必须诚实。\n鞠个躬，诚恳地说：\n“我发誓要做正确的事！”'],
    cole: ['掌握寇的技巧，站如松，坐如钟。\n目标黄金护身符碎片！'],
    zane: ['给别人一个机会。\n让你的朋友展示一段舞技。\n只有分享，才能进步!'],
  },
  endlink: 'C在<a href="https://www.ninjago.com" target="_blank">Ninjago.com</a>继续你的忍者大师之旅。',
  text_direction: 'ltr',
}
