import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import LanguageSelector from '../common/LanguageSelector'
import { NAVIGATIONS } from '../../constants'
import useNavigation from '../../hooks/useNavigation'
import assets from '../../constants/assets'

const { headerBG, logoNinjago, coin } = assets.images

const Header = () => {
  const [amuletVisible, setAmuletVisible] = useState(false)
  const { curViewID } = useNavigation()

  // Listen whenever view changes
  useEffect(() => {
    if (curViewID === null || curViewID === -1) return
    const { header_amulet } = NAVIGATIONS[curViewID]
    setAmuletVisible(header_amulet)
  }, [curViewID])

  return (
    <Container>
      <HeaderBackground src={headerBG} />
      <LogoNinjago src={logoNinjago} />
      {amuletVisible && <Coin src={coin} />}
    </Container>
  )
}

export default memo(Header)

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 20px 20px;
  z-index: 1009;
  direction: ltr!important;
`

const HeaderBackground = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`

const LogoNinjago = styled.img`
  position: relative;
  height: 35px;
  z-index: 1001;
  margin: 0 0 10px 0;
`


const Coin = styled.img`
  position: relative;
  height: 90px;
  z-index: 1001;
  transform: translate(0%, 30px);
`
