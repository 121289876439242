const LANG_OPTIONS = {
  US: {
    lang_code: 'en',
    label: 'US English',
  },
  JP: {
    lang_code: 'jp',
    label: '日本語',
  },
  AE: {
    lang_code: 'ae',
    label: 'عربى',
  },
  TR: {
    lang_code: 'tr',
    label: 'Türk',
  },
  DK: {
    lang_code: 'dk',
    label: 'Dansk',
  },
  DE: {
    lang_code: 'de',
    label: 'Deutsch',
  },
  CN: {
    lang_code: 'cn',
    label: '中文',
  },
}

const LANG_COUNTRIES = Object.keys(LANG_OPTIONS)

module.exports = {
  LANG_OPTIONS,
  LANG_COUNTRIES,
}
