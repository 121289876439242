import React, { memo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { topDown } from '../../style/variants'
import assets from '../../constants/assets'

const { closeIcon } = assets.images

const Modal = ({
  className,
  onClose = null,
  children,
  variants,
  initial = 'closed',
  animate = 'open',
  exit = 'closed',
  disableAnywhereClick = false,
  ...rest
}) => {
  // Listener for anywhere tap
  const handleAnywhereClick = useCallback(() => {
    if (onClose && !disableAnywhereClick) onClose()
  }, [disableAnywhereClick, onClose])

  // Listener for close button click
  const handleCloseClick = useCallback(() => {
    if (onClose) onClose()
  }, [onClose])

  // Listen when component mounted
  useEffect(() => {
    window.addEventListener('click', handleAnywhereClick)

    return () => {
      window.removeEventListener('click', handleAnywhereClick)
    }
  }, [handleAnywhereClick])

  return (
    <Container className={className} variants={variants} initial={initial} animate={animate} exit={exit} {...rest}>
      {onClose && (
        <CloseButton onClick={handleCloseClick}>
          <CloseIcon src={closeIcon} />
        </CloseButton>
      )}
      {children}
    </Container>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  variants: PropTypes.object,
  initial: PropTypes.string,
  animate: PropTypes.string,
  exit: PropTypes.string,
  disableAnywhereClick: PropTypes.bool,
}

export default memo(Modal)

const Container = styled(motion.div)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`

const CloseIcon = styled.img`
  width: 100%;
  height: 100%;
`
