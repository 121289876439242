import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import color from '../../style/color'
import assets from '../../constants/assets'
import { motion } from 'framer-motion'
import TransitionContainer from './TransitionContainer'
import CommonText from './CommonText'
import { CHALET_NEWYORK_SIXTY_NAME, KUNOICHI } from '../../style/type'
import ReactHtmlParser from 'react-html-parser'

const { labelBG1, labelBG2 } = assets.images

const FooterLabelButton = ({ className, onClick, size, children, delay, fontSize, ...rest }) => {
  return (
    <Container
      type="button"
      className={className}
      onClick={onClick}
      size={size}
      preset="fadeIn"
      duration={0.67}
      delay={delay}
      {...rest}
    >
      <TransitionContainer type="span" preset="fadeIn" duration={0.3} delay={delay + 0.15}>
        <StyledCommonText fontFamily={CHALET_NEWYORK_SIXTY_NAME} fontSize={fontSize} lowercase>
          {ReactHtmlParser(children)}
        </StyledCommonText>
      </TransitionContainer>
    </Container>
  )
}

FooterLabelButton.propTypes = {
  className: PropTypes.string,
  delay: PropTypes.number,
  onClick: PropTypes.func,
  size: PropTypes.string,
  fontSize: PropTypes.string,
  children: PropTypes.node,
}
FooterLabelButton.defaultProps = {
  fontSize: 'xxs',
}

export default FooterLabelButton

const StyledCommonText = styled(CommonText)`
  a,
  a:hover,
  a:visited,
  a:active {
    display: inline;
    text-decoration: none;
    color: ${color.blue};
  }
`

const Container = styled(TransitionContainer)`
  max-width: 300px;
  position: relative;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 15px 7px;
  margin-bottom: 8px;
  color: ${color.white};
  background: center/100% 100% no-repeat url(${(props) => (props.size === 'large' ? labelBG1 : labelBG2)});
  z-index: 1001;
  outline: none;
  border: none;
  cursor: pointer;
`
