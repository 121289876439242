import { LANG_OPTIONS, LANG_COUNTRIES } from '@lego-ninjago-ar/common/lib/config/localization'
import color from '../style/color'
import Preview from '../components/pages/Preview'
import PlayAgain from '../components/pages/PlayAgain'
import Processing from '../components/pages/Processing'
import FinishEarly from '../components/pages/FinishEarly'
import CaptureProgress from '../components/pages/CaptureProgress'
import ARView from '../components/pages/ARView'
import CameraAccess from '../components/pages/CameraAccess'
import Guide from '../components/pages/Guide'
import Intro from '../components/pages/Intro'
import Home from '../components/pages/Home'

import assets from './assets'

const {
  posKai,
  posLloyd,
  posNya,
  posJay,
  posCole,
  posZane,
  courageIcon,
  wisdomIcon,
  curiosityIcon,
  honestyIcon,
  balanceIcon,
  generosityIcon,
  kaiBg,
  lloydBg,
  nyaBg,
  zaneBg,
  coleBg,
  jayBg,
} = assets.images
const {
  sfxKai,
  sfxLloyd,
  sfxNya,
  sfxJay,
  sfxCole,
  sfxZane,
  voiceCole,
  voiceJay,
  voiceKai,
  voiceLloyd,
  voiceNya,
  voiceZane,
} = assets.audios

const IS_LDC = window.location.origin.includes('ldc') || process.env.REACT_APP_USE_LDC === 'true'
const IS_DEBUG = process.env.REACT_APP_DEBUG === 'true'
const GA_ID = process.env.REACT_APP_GA_ID

const AGE_REQUIREMENT_MS = 31556952000 * 16


const INTRO_CLIP = 'Intro'

const IDLE_CLIP = 'Idle'

const SIGNATURE_CLIP = 'Signature'

export const API_URL = process.env.REACT_APP_API_URL

export const MODEL_KAI = 'kai'
export const MODEL_LLOYD = 'lloyd'
export const MODEL_NYA = 'nya'
export const MODEL_JAY = 'jay'
export const MODEL_COLE = 'cole'
export const MODEL_ZANE = 'zane'
export const BD_LDC_1 = 'backdrop_ldc_1'
export const BD_LDC_2 = 'backdrop_ldc_2'

const MODELS = [
  {
    name: MODEL_LLOYD,
    model_bg: lloydBg,
    model_path: './assets/models/Lloyd.glb',
    particle_delay: 0,
    virtue_gradient: color.lloydGradient,
    virtue_pos: posLloyd,
    virtue_sfx: sfxLloyd,
    virtue_success_voice: voiceLloyd,
    virtue_icon: wisdomIcon,
    virtue_font_color: color.white,
  },
  {
    name: MODEL_JAY,
    model_bg: jayBg,
    model_path: './assets/models/Jay.glb',
    particle_delay: 1,
    virtue_gradient: color.jayGradient,
    virtue_pos: posJay,
    virtue_sfx: sfxJay,
    virtue_success_voice: voiceJay,
    virtue_icon: honestyIcon,
    virtue_font_color: color.white,
  },
  {
    name: MODEL_KAI,
    model_bg: kaiBg,
    model_path: './assets/models/Kai.glb',
    particle_delay: 0,
    virtue_gradient: color.kaiGradient,
    virtue_pos: posKai,
    virtue_sfx: sfxKai,
    virtue_success_voice: voiceKai,
    virtue_icon: courageIcon,
    virtue_font_color: color.white,
  },
  {
    name: MODEL_NYA,
    model_bg: nyaBg,
    model_path: './assets/models/Nya.glb',
    particle_delay: 0,
    virtue_gradient: color.nyaGradient,
    virtue_pos: posNya,
    virtue_sfx: sfxNya,
    virtue_success_voice: voiceNya,
    virtue_icon: curiosityIcon,
    virtue_font_color: color.black,
  },
  {
    name: MODEL_ZANE,
    model_bg: zaneBg,
    model_path: './assets/models/Zane.glb',
    particle_delay: 0,
    virtue_gradient: color.zaneGradient,
    virtue_pos: posZane,
    virtue_sfx: sfxZane,
    virtue_success_voice: voiceZane,
    virtue_icon: generosityIcon,
    virtue_font_color: color.black,
  },
  {
    name: MODEL_COLE,
    model_bg: coleBg,
    model_path: './assets/models/Cole.glb',
    particle_delay: 0,
    virtue_gradient: color.coleGradient,
    virtue_pos: posCole,
    virtue_sfx: sfxCole,
    virtue_success_voice: voiceCole,
    virtue_icon: balanceIcon,
    virtue_font_color: color.white,
  },
].map((model) => {
  const sfxPlayer = new Audio(model.virtue_sfx)
  sfxPlayer.load()
  const voicePlayer = new Audio(model.virtue_success_voice)
  voicePlayer.load()

  return { ...model, virtue_sfx_player: sfxPlayer, virtue_success_voice_player: voicePlayer }
})

const LDC_BACKDROPS = [
  {
    name: BD_LDC_1,
    models: MODELS.filter(
      (model) => model.name === MODEL_KAI || model.name === MODEL_LLOYD || model.name === MODEL_JAY,
    ),
  },
  {
    name: BD_LDC_2,
    models: MODELS.filter(
      (model) => model.name === MODEL_NYA || model.name === MODEL_COLE || model.name === MODEL_ZANE,
    ),
  },
]

const ENV_PATH = './assets/environments/env.hdr'

const LANG_LABELS = {
  ...LANG_COUNTRIES.reduce((prev, current) =>
    LANG_OPTIONS[prev]
      ? { ...{ [prev]: LANG_OPTIONS[prev]['label'] }, ...{ [current]: LANG_OPTIONS[current]['label'] } }
      : { ...prev, ...{ [current]: LANG_OPTIONS[current]['label'] } },
  ),
}

const HOME = 0
const INTRO = 1
const GUIDE = 2
const CAMERA_ACCESS = 3
const AR_VIEW = 4
const SUBMITTING_SEGMENT = 5
const CAPTURE_PROGRESS = 6
const FINISH_EARLY = 7
const PROCESSING = 8
const PREVIEW = 9
const PLAY_AGAIN = 10

const NAVIGATIONS = [
  {
    path: '/',
    ga_category: 'Home',
    header_language_selector: true,
    header_amulet: false,
    footer_visible: 'extended',
    footer_btn: 'beginHexagonBtn',
    footer_label_btn: 'terms_service',
    footer_label_btn_size: 'large',
    view: Home,
  },
  {
    path: '/intro',
    ga_category: 'Intro',
    header_language_selector: false,
    header_amulet: false,
    footer_visible: 'short',
    footer_btn: 'nextHexagonBtn',
    footer_label_btn: '',
    footer_label_btn_size: 'small',
    view: Intro,
  },
  {
    path: '/guide',
    ga_category: 'Guide',
    header_language_selector: false,
    header_amulet: false,
    footer_visible: 'short',
    footer_btn: 'nextHexagonBtn',
    footer_label_btn: '',
    footer_label_btn_size: 'small',
    view: Guide,
  },
  {
    path: '/camera-access',
    ga_category: 'Camera Access',
    header_language_selector: false,
    header_amulet: false,
    footer_visible: 'short',
    footer_btn: 'allowHexagonBtn',
    footer_label_btn: '',
    footer_label_btn_size: 'small',
    view: CameraAccess,
  },
  {
    path: '/ar-view',
    ga_category: 'AR View',
    header_language_selector: false,
    header_amulet: true,
    footer_visible: 'ar',
    footer_btn: 'nextHexagonBtn',
    footer_label_btn: 'retake',
    footer_label_btn_size: 'small',
    view: ARView,
  },
  {
    path: '/processing-segment',
    ga_category: 'Processing Segment',
    header_language_selector: false,
    header_amulet: false,
    footer_visible: 'short',
    footer_btn: null,
    footer_label_btn: '',
    footer_label_btn_size: 'small',
    view: Processing,
  },
  {
    path: '/capture-progress',
    ga_category: 'Capture Progress',
    header_language_selector: false,
    header_amulet: true,
    footer_visible: 'full',
    footer_btn: 'nextHexagonBtn',
    footer_label_btn: 'finish_early',
    footer_label_btn_size: 'small',
    view: CaptureProgress,
  },
  {
    path: '/skip',
    ga_category: 'Finish Early',
    header_language_selector: false,
    header_amulet: true,
    footer_visible: 'full',
    footer_btn: null,
    footer_label_btn: '',
    footer_label_btn_size: '',
    view: FinishEarly,
  },
  {
    path: '/processing',
    ga_category: 'Processing Final',
    header_language_selector: false,
    header_amulet: false,
    footer_visible: 'short',
    footer_btn: null,
    footer_label_btn: '',
    footer_label_btn_size: 'small',
    view: Processing,
  },
  {
    path: '/preview',
    ga_category: 'Preview Final',
    header_language_selector: false,
    header_amulet: false,
    footer_visible: 'full',
    footer_btn: 'downloadHexagonBtn',
    footer_label_btn: 'done',
    footer_label_btn_size: 'large',
    view: Preview,
  },
  {
    path: '/play-again',
    ga_category: 'Play Again',
    header_language_selector: false,
    header_amulet: true,
    footer_visible: 'full',
    footer_btn: 'replayHexagonBtn',
    footer_label_btn: 'endlink',
    footer_label_btn_size: 'small',
    view: PlayAgain,
  },
]

const API_ROUTES = {
  videoSegment: '/video/segment',
  video: '/video',
}

const ARROW_DOWN = 1
const ARROW_UP = 2
const ARROW_LEFT = 3
const ARROW_RIGHT = 4

export {
  AGE_REQUIREMENT_MS,
  API_ROUTES,
  IS_DEBUG,
  IS_LDC,
  INTRO_CLIP,
  IDLE_CLIP,
  SIGNATURE_CLIP,
  MODELS,
  GA_ID,
  LDC_BACKDROPS,
  ENV_PATH,
  LANG_OPTIONS,
  LANG_COUNTRIES,
  LANG_LABELS,
  NAVIGATIONS,
  HOME,
  INTRO,
  GUIDE,
  CAMERA_ACCESS,
  AR_VIEW,
  CAPTURE_PROGRESS,
  SUBMITTING_SEGMENT,
  PROCESSING,
  PREVIEW,
  PLAY_AGAIN,
  FINISH_EARLY,
  ARROW_DOWN,
  ARROW_UP,
  ARROW_LEFT,
  ARROW_RIGHT,
}
