import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import i18n from 'i18next'
import { useAppContext } from '../../context/AppContext'
import CommonText from '../common/CommonText'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import Container from '../common/ContentContainer'
import TransitionContainer from '../common/TransitionContainer'
import assets from '../../constants/assets'

const { infographic } = assets.images

const Guide = () => {
  const { t } = useAppContext()

  return (
    <Content>
      <StyledTransitionContainer preset="headline" duration={0.75} delay={0.6}>
        <StyledCommonText
          color={color.gold}
          fontFamily={KUNOICHI}
          fontSize={i18n.language === 'jp' ? 'lg' : 'xl'}
          shadow="deep-shadow"
        >
          {t('guide_title')}
        </StyledCommonText>
      </StyledTransitionContainer>
      <StyledTransitionContainer preset="slideDownUp" duration={0.5} delay={1.2}>
        <StyledCommonText color={color.black} fontSize="xs" lowercase>
          {t('guide_text')}
        </StyledCommonText>
      </StyledTransitionContainer>
      <StyledTransitionContainer preset="slideDownUp" duration={0.5} delay={1.45}>
        <InfoGraphic>
          <img src={infographic} />
        </InfoGraphic>
      </StyledTransitionContainer>
      <StyledTransitionContainer preset="headline" duration={0.75} delay={1.8}>
        <StyledCommonText color={color.gold} fontFamily={KUNOICHI} fontSize="xl" shadow="deep-shadow">
          {t('guide_luck')}
        </StyledCommonText>
      </StyledTransitionContainer>
    </Content>
  )
}

export default memo(Guide)

const Content = styled(Container)`
  justify-content: center;
  padding: 130px 0 130px 0;

  > * {
    &:nth-child(1) {
      padding-bottom: 5%;
    }
    &:nth-child(3) {
      padding-top: 2%;
    }
    &:nth-child(4) {
      padding-top: 5%;
    }
    &:nth-child(5) {
      padding-top: 7%;
    }
  }
`

const StyledTransitionContainer = styled(TransitionContainer)(
  () => css`
    width: 90%;
    position: relative;

    display: flex;
    justify-content: center;
  `,
)

const StyledCommonText = styled(CommonText)`
  position: relative;
`

const InfoGraphic = styled.div(
  () => css`
    position: relative;
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    max-height: 175px;
    padding: 5px;
    img {
      width: auto;
      height: 100%;
      opacity: 0.75;
    }
  `,
)
