export const TRANSLATIONS_DK = {
  browser_change: 'Denne oplevelse fungerer bedst i Safari',
  your_journey: 'Din rejse',
  is_loading: 'indlæses',
  consent:
    'Du vil gerne have sikkerhed for dine børn online, og det samme vil vi. Derfor sørger vi for, at en forælder/ansvarlig voksen giver deres accept for at øge sikkerheden i vores samfund. Angiv venligst dit fødeår.',
  consent_accept_age: 'bekræfte',
  consent_accept: 'Jeg giver samtykke',
  consent_deny: 'Jeg giver ikke samtykke',
  consent_agree:
    'Jeg accepterer, at videoer af mig og/eller mit barn bliver optaget og brugt til at oprette en video, der viser Ninjago-færden om at samle alle de gyldne fragmenter. Jeg forstår, at jeg når som helst kan trække mit samtykke tilbage. Hvis et barn vises i denne/disse videoer, bekræfter jeg, at jeg er forælder eller værge for det barn. For mere information, <a href="/legal-dk.html" target="_blank">klik her.</a>',
  consent_deny_age_page:
    'Vi beklager, men du skal have en forælder eller en værge til at hjælpe dig med at deltage i denne oplevelse',
  consent_deny_consent_page:
    'Vi beklager, men denne oplevelse kræver, at vi optager og gemmer videoer for at oprette din digitale takeaway. Besøg venligst Ninjago.com for at fortsætte din rejse.',
  terms_service:
    'Ved at klikke accepterer du \n the <a href="https://www.lego.com/da-dk/page/terms-and-conditions" target="_blank">Vilkårene for Brug</a>, <a href="https://www.lego.com/da-dk/legal/notices-and-policies/privacy-policy/" target="_blank">Fortrolighedspolitik</a> \n og <a href="https://www.lego.com/da-dk/cookie-policy" target="_blank">cookiepolitik</a>',
  rotate_mobile: 'Drej skærmen',
  intro: {
    header: {
      ninjago: 'Ninjafærd',
      title: 'Mester Wus\ngyldne amulet',
    },
    content: [
      'Den onde Lord Garmadon smadrede Mester Wus gyldne amulet i 6 stykker og gemte stykkerne forskellige steder rundt om i verden!',
      'Mester Wu har brug for din hjælp til at finde disse gyldne stykker og gendanne den gyldne amulet for at beskytte NINJAGO arven.',
      'Dette er din ninjafærd.',
    ],
  },
  runtime_error: 'Noget gik galt',
  no_virtue_text: 'Gå til den anden baggrund for at finde de sidste tre gyldne fragmenter',
  retake: 'Tag nyt',
  done: 'Fortsæt',
  finish_early: 'Nej tak, jeg er færdig',
  finish_early_drag: 'Træk over for <br /> at afslutte',
  success: 'Succes!',
  quest_complete: 'Din ninjafærd er udført!',
  success_text: 'Fortsæt med at låse op for det næste gyldne amuletstykke!',
  quest_complete_text:
    'Tillykke! Du har indsamlet alle de gyldne amuletstykker og fuldført din ninjafærd!<br/><br/>Klik på download-knappen nedenfor for at modtage din video!',
  play_again: 'Spil igen?',
  start_over: 'Start fra begyndelsen for at bytte roller eller tage billeder af nye poseringer!',
  processing: 'Uploader',
  processing_video: 'Afslutter din video',
  skip_sure: 'Er du sikker?',
  skip_text:
    'Du mangler stadig at indsamle alle de gyldne amuletstykker! Mester Wu har stadig brug for din hjælp! Du modtager stadig din NINJAGO færd-video, men den er ikke færdig.',
  cancel: 'Annuller',
  tap: 'Tryk ',
  download_video: ' for at downloade din video',
  home_small_title: 'Ninjafærd',
  home_main_title: 'Mester Wus gyldne amulet',
  desktop_small_title:
    'Denne virtuelle Ninjago oplevelse er designet til mobile enheder. Besøg din nærmeste LEGOLAND Park eller LEGOLAND Discovery Center for at deltage. Vær som altid en Ninja!',
  desktop_main_title: 'As always, be Ninja!',
  intro_title: "master wu's golden amulet",
  guide_title: 'Find de gyldne amuletstykker!',
  guide_text: 'Lås de gyldne amuletstykker op i hver disciplin ved at tage et billede med ninjaen på skærmen.',
  guide_luck: 'Held og lykke!',
  camera_access: 'For at fortsætte skal du give tilladelse til at bruge kameraet!',
  ar_instruction: 'Peg kameraet mod hver scene\nfor at låse op for de gyldne\namuletstykker!',
  tap_start: 'Tryk og hold nede for at begynde',
  virtue_texts: {
    kai: ['Din tapperhed har ingen ende. Du blot Kais mod skal finde, for at ondskaben overvinde!'],
    lloyd: ['Du har kigget mod højre, Du har kigget mod venstre, Mediter nu, og svæve du vil mestre!'],
    nya: ['Du har kigget op, Du har kigget ned, Hav det sjovt og dans afsted!'],
    jay: ['En god Ninja må være ærlig Buk dig og sig ”Jeg lover at gøre det, som er rigtigt!”'],
    cole: ['At mestre Ninja Coles sjæl, Rejs dig – høj som en pæl Det Gyldne Fragment er din milepæl'],
    zane: ['Giv andre en chance. Bed din ven om at danse. Kun ved at dele kan du opnå fremgang og revanche!'],
  },
  endlink: 'Fortsæt din rejse mod<br/>dygtighed på <a href="https://www.ninjago.com" target="_blank">ninjago.com</a>',
  text_direction: 'ltr',
}
