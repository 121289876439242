import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import CommonText from './CommonText'
import { useAppContext } from '../../context/AppContext'
import {
  MEDIA_RECORDER_LOADING,
  MEDIA_RECORDER_PROGRESS,
  MEDIA_RECORDER_RECORD_START,
  MEDIA_RECORDER_RESET,
} from '../../utils/AR/mediaRecorder'
import ReactHtmlParser from 'react-html-parser'

const RecordButton = ({ className, onMouseDown, onMouseUp, ...rest }) => {
  const [progress, setProgress] = useState(false)
  const [isRecording, setRecording] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isActive, setActive] = useState(false)
  const { t } = useAppContext()

  const onRecorderProgress = useCallback(({ detail: progress }) => {
    setProgress(progress)
  }, [])

  const onRecordStart = useCallback(() => {
    setRecording(true)
    setLoading(false)
    setProgress(false)
    setActive(true)
  }, [])
  const onRecordLoading = useCallback(() => {
    setLoading(true)
    setRecording(false)
    setProgress(false)
  }, [])

  const onRecordReset = useCallback(() => {
    setLoading(false)
    setRecording(false)
    setProgress(false)
    setActive(false)
  }, [])

  useEffect(() => {
    window.addEventListener(MEDIA_RECORDER_PROGRESS, onRecorderProgress)
    window.addEventListener(MEDIA_RECORDER_RECORD_START, onRecordStart)
    window.addEventListener(MEDIA_RECORDER_LOADING, onRecordLoading)
    window.addEventListener(MEDIA_RECORDER_RESET, onRecordReset)
    window.addEventListener('touchend', onMouseUp)
    window.addEventListener('touchend', onMouseUp)
    return () => {
      window.removeEventListener(MEDIA_RECORDER_PROGRESS, onRecorderProgress)
      window.removeEventListener(MEDIA_RECORDER_RECORD_START, onRecordStart)
      window.removeEventListener(MEDIA_RECORDER_LOADING, onRecordLoading)
      window.removeEventListener(MEDIA_RECORDER_RESET, onRecordReset)
      window.removeEventListener('mouseup', onMouseUp)
      window.removeEventListener('touchend', onMouseUp)
    }
  }, [onMouseDown, onMouseUp, onRecordLoading, onRecordReset, onRecordStart, onRecorderProgress])

  return (
    <Container className={className} id="recorder" {...rest}>
      <ProgressContainer viewBox="0 0 38 38" isRecording={isRecording} isLoading={isLoading}>
        <ProgressTrack r="16" cx="19" cy="19" isRecording={isRecording} isLoading={isLoading} />
        <ProgressBar id="progressBar" r="16" cx="19" cy="19" recordProgress={progress} isRecording={isRecording} />
        <LoadCircle r="16" cx="19" cy="19" isLoading={isLoading} />
      </ProgressContainer>
      <Button
        active={isActive}
        onMouseUp={onMouseUp}
        onTouchEnd={onMouseUp}
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
      />
      <StyledCommonText active={isActive} fontSize="xs" shadow="simple-shadow">
        {ReactHtmlParser(t('tap_start'))}
      </StyledCommonText>
    </Container>
  )
}

RecordButton.propTypes = {
  className: PropTypes.string,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
}

export default memo(RecordButton)

const Container = styled(motion.div)`
  position: absolute;
  width: 120px;
  height: 120px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(50% - 10px));
  z-index: 20;
  transition: 0.5s opacity;
`

const ProgressContainer = styled.svg(
  ({ isRecording, isLoading }) => css`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0.9);
    transition: 0.3s transform, 0.3s opacity;

    ${(isRecording || isLoading) &&
    css`
      opacity: 1;
      transform: scale(1);
    `};
  `,
)

const ProgressTrack = styled.circle(
  ({ isRecording, isLoading }) => css`
    fill: transparent;
    stroke-width: 3;
    stroke: white;
    ${(isRecording || isLoading) &&
    css`
      transition: 0.8s stroke;
      stroke: #fff5;
    `};
  `,
)

const ProgressBar = styled.circle(
  ({ recordProgress, isRecording }) =>
    css`
      fill: transparent;
      stroke-width: 3;
      opacity: 0;
      transition: none;
      transform-origin: 50% 50%;
      stroke: white;
      transform: rotate(-90deg);
      stroke-dasharray: 100.531 100.531;
      stroke-dashoffset: 50;

      ${recordProgress &&
      css`
        stroke-dashoffset: ${recordProgress};
      `}
      ${isRecording &&
      css`
        opacity: 1;
      `};
    `,
)

const LoadCircle = styled.circle(
  ({ isLoading }) => css`
    fill: transparent;
    stroke-width: 3;
    stroke-dasharray: 25 25 25 25;
    stroke-dashoffset: 0;
    stroke: white;
    opacity: 0;
    transform-origin: 50% 50%;

    ${isLoading &&
    css`
      animation: record-button-spin 1.1s linear infinite both;
      opacity: 1;
    `};

    @keyframes record-button-spin {
      0% {
        transform: rotate(-90deg);
      }
      100% {
        transform: rotate(270deg);
      }
    }
  `,
)

const Button = styled.button(
  ({ active }) => css`
    display: block;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    position: absolute;
    background: red;
    border-radius: 50%;
    color: transparent;
    top: 0;
    left: 0;
    transform-origin: 50% 50%;
    transform: scale(0.7);
    transition: 0.3s border-radius, 0.3s transform;
    width: 100%;
    height: 100%;
    ${active &&
    css`
      transform: scale(0.5);
      background: #fff5;
    `};
  `,
)

const StyledCommonText = styled(CommonText)(
  ({ active }) => css`
    position: absolute;
    left: 50%;
    bottom: 0;
    display: block;
    white-space: nowrap;
    transform: translate(-50%, 100%);

    ${active &&
    css`
      display: none;
    `}
  `,
)
