export const TRANSLATIONS_AE = {
  browser_change: 'يفضل استخدام هذه التجربة في Safari',
  your_journey: 'يتم تحميل',
  is_loading: 'رحلتك',
  consent:
    'تريد أن يكون أطفالك آمنين على الإنترنت ، وكذلك نحن. من أجل التأكد من إعطاء الموافقة من قبل أحد الوالدين وللحفاظ على مجتمعنا آمنًا ، يرجى تأكيد العام الذي ولدت فيه.',
  consent_accept_age: 'موافقة',
  consent_accept: 'انا موافق',
  consent_deny: 'انا غير موافق',
  consent_agree:
    'أوافق على تسجيل مقاطع الفيديو الخاصة بي و / أو لطفلي واستخدامها لإنشاء مقطع فيديو يُظهر مهمة Ninjago لجمع كل القطع الذهبية. أفهم أنه يمكنني سحب موافقتي في أي وقت. إذا ظهر طفل في مقاطع الفيديو، فأنا أؤكد أنني أحد الوالدين أو الوصي على ذلك الطفل. لمزيد من المعلومات،  <a href="/legal-ae.html" target="_blank">أضغط هنا.</a>',
  consent_deny_age_page: 'للأسف ، أنت بحاجة إلى أن يكون معك أحد الوالدين أو وصي ليساعدك على المشاركة في هذه التجربة',
  consent_deny_consent_page:
    'نأسف ، لكن هذه التجربة تتطلب منا تسجيل مقاطع الفيديو وحفظها لإنشاء الوجبات الجاهزة الرقمية الخاصة بك. يرجى زيارة Ninjago.com لمواصلة رحلتك.',
  terms_service:
    'بالضغط ، أنت توافق على <a href="https://www.lego.com/en-ae/page/terms-and-conditions" target="_blank">شروط الاستخدام</a><br/> و<a href="https://www.lego.com/en-ae/legal/notices-and-policies/privacy-policy/" target="_blank">سياسة الخصوصية</a><br/> و<a href="https://www.lego.com/en-ae/cookie-policy" target="_blank">سياسة ملفات تعريف الارتباط</a>',
  rotate_mobile: 'قم بتدوير جهازك',
  intro: {
    header: {
      ninjago: 'مهمة NINJAGO',
      title: 'التميمة الذهبية\nماستر وو',
    },
    content: [
      'لقد حطم الشرير جارمادون تميمة ماستر وو الذهبية إلى 6 قطع وأخفاهم في العديد من الأماكن حول العالم!',
      'ماستر وو بحاجة إلى مساعدتك من أجل استعادة هذه القطع الذهبية واسترداد التميمة الذهبية لحماية ميراث NINJAGO.',
      'هذه مهمتك.',
    ],
  },
  runtime_error: 'هناك خطأ ما',
  no_virtue_text: 'انتقل إلى الخلفية الأخرى للعثور على آخر 3 قطع ذهبية',
  retake: 'إعادة التقاط',
  done: 'مواصلة',
  finish_early: 'لا، شكرًا. لقد انتهيت.',
  finish_early_drag: 'اسحب حتى الطرف المقابل',
  success: 'تم إنجاز المهمة!',
  quest_complete: 'تم إنجاز المهمة!',
  success_text: 'استمر في فك لغز القطعة الذهبية التالية!',
  quest_complete_text:
    'تهانينا! لقد قمت بتجميع قطع التميمة الذهبية وأنجزت مهمتك بنجاح!</br/><br>انقر على زر "تنزيل" أدناه لاستلام الفيديو! ',
  play_again: 'هل تريد اللعب مرة أخرى؟',
  start_over: 'ابدأ من جديد لتبديل الأدوار والتقاط مجموعة جديدة من الوضعيات!',
  processing: 'تحميل',
  processing_video: 'الانتهاء من مشاهدة الفيديو الخاص بك',
  skip_sure: 'هل أنتَ متأكد؟',
  skip_text:
    'لا يزال يتعين عليك جمع كل قطع التميمة الذهبية! ما زال ماستر وو بحاجة إلى مساعدتك! سوف تستلم فيديو مهمة  NINJAGO، لكن لن يكون مكتملاً.',
  cancel: 'إلغاء',
  tap: 'انقر ',
  download_video: '  لتحميل الفيديو',
  home_small_title: 'مهمة NINJAGO',
  home_main_title: 'التميمة الذهبية\nماستر وو',
  desktop_small_title:
    'تم تصميم تجربة مهمة Ninjago الافتراضية للأجهزة المحمولة. قم بزيارة أقرب حديقة Legoland Park أو Legoland Discovery Center للمشاركة.',
  desktop_main_title: 'كالعادة ، كن نينجا!',
  intro_title: 'التميمة الذهبية\nماستر وو',
  guide_title: 'العثور على القطع الذهبية!',
  guide_text:
    'قم بفك لغز القطع الذهبية بمعرفة ترتيب مواضعها الصحيحة وفق كل صفة بها عبر التقاط صورة باستخدام محارب النينجا الذي يظهر على شاشتك!',
  guide_luck: 'حظًا موفقًا!',
  camera_access: 'يُرجى السماح بالوصول إلى الكاميرا الخاصة بك للمتابعة!',
  ar_instruction: 'قم بتوجيه الكاميرا ناحية كل مشهد لفك لغز القطع الذهبية بمعرفة ترتيب مواضعها الصحيحة!',
  tap_start: 'انقر مع الاستمرار للبدء',
  virtue_texts: {
    kai: ['شجاعتك لن تتوقف \n اجعل شجاعة كاي إلهامك \n اقضِ على الشر كله بالنار التي داخلك'],
    lloyd: ['لقد نظرت إلى اليمين, لقد نظرت إلى اليسار ,حان وقت التأمل ... حلّق بعيداً'],
    nya: ['لقد نظرت للأعلى,لقد نظرت للأسفل, حان الوقت لبعض من المرح والرقص! '],
    jay: ['النينجا الحقيقي لابد أن يكون صريحاً انحنِ و أنت تقول, "أقسم  أن افعل كل ما هو صحيحاً!"'],
    cole: ['لكي تتقن مهارات كول,قفْ مستقيماً  كالعمود القطعة الذهبية هي الهدف'],
    zane: ['امنح شخص آخر الفرصة. اطلب من صديقك أن يقوم برقصة  فقط بالمشاركة يمكنك الصعود! \n'],
  },
  endlink: 'واصل رحلتك لحل اللغز في <a href="https://www.ninjago.com" target="_blank">Ninjago.com</a>',
  text_direction: 'rtl',
}
