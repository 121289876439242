import React, { useCallback, useEffect, useState } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import useOrientationChange from 'use-orientation-change'
import { isMobile, isIOS, isChromium, isChrome } from 'react-device-detect'
import { useAppContext } from '../context/AppContext'
import Layout from './Layout'
import MobileLandscape from './pages/MobileLandscape'
import DesktopLandingPage from './pages/DesktopLandingPage'
import AssetLoading from './pages/AssetLoading'
import ARViewer from '../utils/AR/arViewer'
import GlobalStyle from '../style/global'
import { Reset } from 'styled-reset'
import { IS_DEBUG, NAVIGATIONS, IS_LDC, CAMERA_ACCESS } from '../constants'
import { AnimatePresence, motion } from 'framer-motion'
import { downTop } from '../style/variants'
import styled from 'styled-components'

import analytics from '../utils/ga'
import { ACTION_CONFIRM_SLIDER, CATEGORY_MODAL_CONSENT } from '../constants/analytics'
import { preloadImages, preloadAudios } from '../utils/preloader'
import assets from '../constants/assets'
import ConsentModal from './common/ConsentModal'
import DeniedConsent from './pages/DeniedConsent'
import AgeGateModal from './common/AgeGateModal'
import DeniedAgeConsent from './pages/DeniedAgeGate'
import useNavigation from '../hooks/useNavigation'
import LanguageSelector from './common/LanguageSelector'

const imageAssets = Object.values(assets.images)
const audioAssets = Object.values(assets.audios)

const App = () => {
  const [languageSelectorVisible, setLanguageSelectorVisible] = useState(false)

  const [browserChange, setBrowserChange] = useState(false)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const orientation = useOrientationChange()

  const { userConsent, setUserConsent, arViewer, deniedConsent, setDeniedConsent, t } = useAppContext()

  const preloadAssets = useCallback(async () => {
    try {
      await preloadImages(imageAssets)
      await preloadAudios(audioAssets)
      await arViewer.current.loadAssets()

      setLoading(false)
    } catch (error) {
      console.error(`Asset loading failed: ${error}`)
    }
  }, [arViewer])

  const { curViewID } = useNavigation()

  const handleConsentAccept = useCallback(() => {
    setUserConsent(userConsent + 1)
    analytics.trackEvent({ eventCategory: CATEGORY_MODAL_CONSENT, action: ACTION_CONFIRM_SLIDER })
  }, [setUserConsent, userConsent])

  const handleConsentDeny = useCallback(() => {
    setDeniedConsent(true)
  }, [setDeniedConsent])

  useEffect(() => {
    if (curViewID === null || curViewID === -1) return
    const { header_language_selector, header_amulet } = NAVIGATIONS[curViewID]
    setLanguageSelectorVisible(header_language_selector)
  }, [curViewID])

  useEffect(() => {
    analytics.trackPageView({ location: location.href, path: location.pathname, title: document.title })
  }, [location.href, location.pathname])

  useEffect(() => {
    if (isMobile && isIOS && (isChrome || isChromium)) {
      setBrowserChange(true)
      return
    }

    if (!arViewer.current) arViewer.current = new ARViewer(IS_LDC)
    preloadAssets()
  }, [arViewer, preloadAssets])

  return (
    <>
      <Reset />
      <GlobalStyle direction={t('text_direction')} />
      {loading ? (
        <AssetLoading browserChange={browserChange} />
      ) : (
        <>
          {isMobile && (
            <>
              {!deniedConsent ? (
                <>
                  <Layout>
                    <AnimatePresence exitBeforeEnter>
                      <Switch location={location} key={location.pathname}>
                        {NAVIGATIONS.map(({ path, view: View }) => (
                          <Route key={`route_${path}`} exact path={path} component={View} />
                        ))}
                      </Switch>
                    </AnimatePresence>
                  </Layout>
                  {orientation === 'landscape' && !IS_DEBUG && <MobileLandscape />}
                  <AnimatePresence exitBeforeEnter>
                    {userConsent === 0 && !deniedConsent && (
                      <ModalContainer
                        key="ageModal"
                        variants={downTop}
                        initial="closed"
                        animate={{
                          ...downTop.open,
                          transition: {
                            delay: 3,
                          },
                        }}
                        exit="closed"
                      >
                        <AgeGateModal handleConsentAccept={handleConsentAccept} handleConsentDeny={handleConsentDeny} />
                      </ModalContainer>
                    )}
                    {userConsent === 1 && !deniedConsent && curViewID >= CAMERA_ACCESS && (
                      <ModalContainer
                        key="consentModal"
                        variants={downTop}
                        initial="closed"
                        animate={{
                          ...downTop.open,
                          transition: {
                            delay: 0.65,
                          },
                        }}
                        exit="closed"
                      >
                        <ConsentModal handleConsentAccept={handleConsentAccept} handleConsentDeny={handleConsentDeny} />
                      </ModalContainer>
                    )}
                  </AnimatePresence>
                </>
              ) : (
                <>
                  {userConsent === 0 && <DeniedAgeConsent />}
                  {userConsent === 1 && <DeniedConsent />}
                </>
              )}
            </>
          )}
          {!isMobile && (
            <AnimatePresence exitBeforeEnter>
              <DesktopLandingPage />
            </AnimatePresence>
          )}
          {languageSelectorVisible && <StyledLanguageSelector />}
        </>
      )}
    </>
  )
}

App.propTypes = {}

export default App

const ModalContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  pointer-events: none;
`
const StyledLanguageSelector = styled(LanguageSelector)`
  position: absolute;
  z-index: 9999999;
  top: 25px;
  right: 20px;
`
