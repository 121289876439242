import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import TransitionParent from './TransitionParent'
import * as variants from '../../style/variants'
import { fragmentZoom } from '../../style/variants'
import { clamp } from 'lodash/number'
import assets from '../../constants/assets'

const { amuletBg, amuletFull, amulet1, amulet2, amulet3, amulet4, amulet5, amulet6 } = assets.images

const fragments = [amulet1, amulet2, amulet3, amulet4, amulet5, amulet6]

const Amulet = ({ className, totalProgress, showProgress, staticProgress }) => {
  //const { totalProgress } = useCapture()

  return (
    <Container className={className}>
      <AmuletImage variants={variants.amuletBackground} initial="initial" animate="animate" exit="exit">
        <img src={showProgress ? amuletBg : amuletFull} />
      </AmuletImage>
      {showProgress && (
        <>
          {Array.from(Array(clamp(totalProgress - 1, 0, 999)).keys()).map((i) => (
            <Fragment
              type="div"
              key={`fragments_${i}`}
              variants={variants.fragmentZoom}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <img src={fragments[i]} />
            </Fragment>
          ))}
          <Fragment
            type="div"
            key={`fragments_${totalProgress}`}
            variants={staticProgress ? variants.fragmentZoom : variants.amuletFragment}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <img src={fragments[totalProgress - 1]} />
          </Fragment>
        </>
      )}
    </Container>
  )
}

Amulet.propTypes = {
  className: PropTypes.string,
  totalProgress: PropTypes.number,
  showProgress: PropTypes.bool,
  staticProgress: PropTypes.bool,
}

export default Amulet

const Container = styled(motion.div)(
  ({ theme }) => css`
    display: flex;
    position: relative;
    height: 25vh;
    justify-content: center;

    margin: 2% 0 4%;

    @media (min-height: 635px) {
      height: 31vh;
      margin: 2% 0 3%;
    }

    @media (min-height: 664px) {
      height: 33vh;
    }
  `,
)

const AmuletImage = styled(motion.div)(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: auto;
      height: 100%;
    }
  `,
)

const StyledTransitionParent = styled(TransitionParent)(
  ({ theme }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
  `,
)

const Fragment = styled(motion.div)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  height: 100%;
  > img {
    width: 100%;
    height: auto;
  }
`
