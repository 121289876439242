import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import CommonText from '../common/CommonText'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import Container from '../common/ContentContainer'
import Amulet from '../common/Amulet'
import { motion } from 'framer-motion'
import * as variants from '../../style/variants'
import useCapture from '../../hooks/useCapture'
import { MODELS, LDC_BACKDROPS, IS_LDC } from '../../constants'
import Background from '../common/Background'
import useNavigation from '../../hooks/useNavigation'
import ReactHtmlParser from 'react-html-parser'

const CaptureProgress = () => {
  const { t, setFooterLabelBtnVisible, setNavBtnVisible } = useAppContext()
  const [isComplete, setIsComplete] = useState(false)
  const [isNextBackdrop, setIsNextBackdrop] = useState(false)

  useEffect(() => {
    setNavBtnVisible(true)
    setFooterLabelBtnVisible(true)
  }, [setFooterLabelBtnVisible, setNavBtnVisible])
  const { totalProgress, models } = useCapture()

  useEffect(() => {
    setIsComplete(totalProgress === MODELS.length)

    if (IS_LDC && totalProgress >= 3 && totalProgress <= 5) {
      let tempIsNextBackdrop = false
      for (let i = 0; i < LDC_BACKDROPS.length; i++) {
        for (let j = 0; j < LDC_BACKDROPS[i].models.length; j++) {
          const completed = models.some((model) => model.name === LDC_BACKDROPS[i].models[j].name)

          if (completed) {
            tempIsNextBackdrop = true
          } else {
            tempIsNextBackdrop = false
          }
        }

        if (tempIsNextBackdrop) {
          setIsNextBackdrop(true)
          break
        }
      }
    }
  }, [models, totalProgress])

  const currentModel = useMemo(() => {
    const currentName = models[models.length - 1]?.name
    const model = MODELS.find(({ name }) => currentName === name)

    if (model) {
      const { virtue_success_voice_player: voicePlayer } = model
      voicePlayer.pause()
      voicePlayer.currentTime = 0
      voicePlayer.muted = false

      setTimeout(async () => {
        try {
          await voicePlayer.play()
        } catch (error) {
          console.log(error)
        }
      }, 1500)
    }

    return model
  }, [models])

  const { headline, body } = useMemo(() => {
    return {
      headline: isComplete ? 'quest_complete' : 'success',
      body: isComplete ? 'quest_complete_text' : isNextBackdrop ? 'no_virtue_text' : 'success_text',
    }
  }, [isComplete, isNextBackdrop])

  return (
    <Content>
      {currentModel && !isComplete && <Background background={currentModel.model_bg} preset="fadeIn" duration={1} />}
      <HeadlineText
        as={motion.h2}
        color={color.gold}
        fontFamily={KUNOICHI}
        fontSize="xl"
        shadow="deep-shadow"
        key="cg1"
        variants={variants.headline}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {t(headline)}
      </HeadlineText>
      <StyledAmulet showProgress totalProgress={totalProgress} />
      <DescriptionLabel>
        <CommonText
          fontSize={isComplete ? 'xxs' : 'sm'}
          shadow="none"
          lowercase
          key="cg2"
          variants={variants.text}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          {ReactHtmlParser(t(body))}
        </CommonText>
      </DescriptionLabel>
    </Content>
  )
}

export default memo(CaptureProgress)

const Content = styled(Container)`
  justify-content: center;
`

const HeadlineText = styled(CommonText)`
  position: absolute;
  width: 100%;
  padding: 0 20px;
  bottom: calc((${window.innerHeight}px - 310px) / 2 + 180px + 12.5vh);

  @media (min-height: 635px) {
    bottom: calc((${window.innerHeight}px - 310px) / 2 + 180px + 15.5vh);
  }

  @media (min-height: 664px) {
    bottom: calc((${window.innerHeight}px - 310px) / 2 + 180px + 16.5vh);
  }
`

const StyledAmulet = styled(Amulet)`
  margin: 0;
`

const DescriptionLabel = styled.div`
  position: absolute;
  top: calc((${window.innerHeight}px - 310px) / 2 + 130px + 12.5vh);
  bottom: 140px;
  width: 90vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;

  @media (min-height: 635px) {
    top: calc((${window.innerHeight}px - 310px) / 2 + 130px + 15.5vh);
  }

  @media (min-height: 664px) {
    top: calc((${window.innerHeight}px - 310px) / 2 + 130px + 16.5vh);
  }
`
