export const simple = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
  transition: {
    duration: 0.25,
  },
}

export const downTop = {
  open: {
    y: 0,
  },
  closed: {
    y: '120%',
  },
}

export const topDown = {
  open: {
    y: 0,
  },
  closed: {
    y: '-120%',
  },
}

export const sectionContainer = {
  enter: { transition: { staggerChildren: 0.1 } },
  exit: { transition: { staggerChildren: 0.1 } },
}

export const amuletPulse = {
  initial: {
    scale: 1,
  },
  enter: {
    scale: 1.1,
    transition: {
      type: 'anticipate',
      repeat: Infinity,
      duration: 0.6,
      repeatType: 'mirror',
    },
  },
  exit: {
    opacity: 0,
    scale: 1.2,
    transition: {
      duration: 0.5,
    },
  },
}
export const amuletBackground = {
  initial: {
    opacity: 0,
    scale: 0.7,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1.2,
      type: 'spring',
      bounce: 0,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.7,
  },
}

export const fragmentZoom = {
  initial: {
    opacity: 0,
    scale: 0.7,
    x: '-50%',
    y: '-50%',
  },
  animate: {
    opacity: 1,
    scale: 1,
    x: '-50%',
    y: '-50%',
    transition: {
      duration: 1.2,
      type: 'spring',
      bounce: 0,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.7,
    x: '-50%',
    y: '-50%',
  },
}

export const amuletFragment = {
  initial: {
    opacity: 0,
    scale: 1.2,
    x: '-50%',
    y: '-50%',
  },
  animate: {
    opacity: 1,
    scale: 1,
    x: '-50%',
    y: '-50%',
    transition: {
      duration: 0.33,
      delay: 0.6,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.7,
    x: '-50%',
    y: '-50%',
  },
}

export const headline = {
  initial: {
    scale: 1.5,
    opacity: 0,
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.75,
      delay: 1.2,
    },
  },
  exit: {
    scale: 1,
    y: -20,
    opacity: 0,
  },
}

export const text = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.65,
      delay: 1.6,
    },
  },
  exit: {
    opacity: 0,
  },
}

const OFFSET_Y = 20

export const scale = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.9 },
}

export const hexagonButton = {
  initial: { opacity: 1, scale: 0, pointerEvents: 'none' },
  animate: { opacity: 1, scale: 1, pointerEvents: 'auto' },
  exit: { opacity: 0, scale: 0.8, pointerEvents: 'none' },
}

export const slideUpDown = {
  initial: { opacity: 0, y: -OFFSET_Y },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: OFFSET_Y },
}

export const slideDownUp = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 },
}

export const slideViewportX = {
  initial: { opacity: 0, x: '-100vw' },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: '100vw' },
}

export const slideX = {
  initial: { opacity: 0, x: '100%' },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: '100%' },
}

export const fadeIn = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

export const pulse = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { repeat: Infinity, duration: 0.67, repeatType: 'mirror', delay: 1.2 } },
  exit: { opacity: 0 },
}

export const pulseScale = {
  initial: { opacity: 1, scale: 1 },
  animate: { opacity: 1, scale: 0.9, transition: { repeat: Infinity, repeatType: 'mirror' } },
  exit: { opacity: 0, scale: 0.8, pointerEvents: 'none' },
}

export const fadeOut = {
  initial: { opacity: 1 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

export const slideDown = {
  initial: { y: '-50%' },
  animate: { y: 0 },
  exit: { y: 0 },
}

export const backgrounds = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
}

export const hexPosition = {
  home: {
    top: -10,
    transition: {
      type: 'tween',
    },
  },
  full: {
    top: 0,
    transition: {
      type: 'tween',
    },
  },
  short: {
    top: 30,
    transition: {
      type: 'tween',
    },
  },
  ar: {
    y: Math.min(-(window.innerHeight - ((window.innerWidth / 4) * 5 + 150)), 0),
    transition: {
      type: 'tween',
    },
  },
}

export const footer = {
  hidden: {
    y: 150,
  },
  full: {
    y: 0,
    transition: {
      type: 'tween',
    },
  },
  short: {
    y: 40,
    transition: {
      type: 'tween',
    },
  },
  ar: {
    y: Math.min(-(window.innerHeight - ((window.innerWidth / 4) * 5 + 160)), 0),
    transition: {
      type: 'tween',
    },
  },
  extended: {
    y: -225,
    transition: {
      type: 'ease',
      bounce: 0,
      ease: 'anticipate',
      duration: 0.75,
    },
  },
}

export const wu = {
  initial: {
    x: '-180%',
  },
  enter: {
    x: 0,
    transition: {
      delay: 1,
      type: 'tween',
      duration: 0.5,
      ease: 'anticipate',
    },
  },
  exit: {
    x: '180%',
  },
}

export const stroke = {
  initial: {
    clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
  },
  enter: {
    clipPath: 'polygon(0% 0%, 100% 0, 100% 100%, 0 100%)',
    transition: {
      delay: 1.5,
      type: 'tween',
      duration: 0.5,
      ease: 'anticipate',
    },
  },
  exit: {
    clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
  },
}
