import * as THREE from 'three'
import { loadGLTF, loadHDR } from './loadAssetManager'
import FireParticleSystem from './particles/fireParticleSystem'
import WaterParticleSystem from './particles/waterParticleSystem'
import HandGlowParticleSystem from './particles/handGlowParticleSystem'
import LightningParticleSystem from './particles/lightningParticleSystem'
import RockParticleSystem from './particles/rockParticleSystem'
import IceParticleSystem from './particles/iceParticleSystem'
import SnowParticleSystem from './particles/snowParticleSystem'
import EnergyBallParticleSystem from './particles/energyBallParticleSystem'
import MediaRecorder from './mediaRecorder'
import wait from '../wait'
import { ENV_PATH, IDLE_CLIP, INTRO_CLIP, MODELS, LDC_BACKDROPS, SIGNATURE_CLIP } from '../../constants'

window.THREE = THREE

class ARViewer {
  _onCameraReady = null
  _onTargetDetect = null
  _onRuntimeError = null

  set onCameraReady(val) {
    this._onCameraReady = val
  }

  set onTargetDetect(val) {
    this._onTargetDetect = val
  }

  set onRuntimeError(val) {
    this._onRuntimeError = val
  }

  constructor(isLDC) {
    this.isLDC = isLDC
    this.clock = new THREE.Clock()
    this.models = MODELS
    this.ldcBackdrops = LDC_BACKDROPS
    this.currentModelIndex = -1
    this.started = false
    this.playingAnimation = false
    this.assetReady = false
    this.scanStart = false
    this.arStart = false
    this.modelContainer = new THREE.Object3D()
    this.fireParticleSystem = new FireParticleSystem()
    this.leftWaterParticleSystem = new WaterParticleSystem()
    this.rightWaterParticleSystem = new WaterParticleSystem()
    this.energyBallParticleSystem = new EnergyBallParticleSystem()
    this.lightningParticleSystem = new LightningParticleSystem()
    this.leftHandGlowParticleSystem = new HandGlowParticleSystem()
    this.rightHandGlowParticleSystem = new HandGlowParticleSystem()
    this.rockParticleSystem = new RockParticleSystem()
    this.snowParticleSystem = new SnowParticleSystem()
    this.iceParticleSystem = new IceParticleSystem()
    this.gltfs = []
    this.fireParticleTextures = []
    this.waterParticleTextures = []
    this.energyBallParticleTextures = []
    this.lightParticleTextures = []
    this.glowParticleTextures = []
    this.rockParticleGLTFs = []
    this.snowParticleTextures = []
    this.iceParticleGLTFs = []
  }

  /**
   * Initialize
   */
  init = (canvas) => {
    this.canvas = canvas
    this.canvas.width = this.canvas.offsetWidth
    this.canvas.height = this.canvas.offsetHeight

    if (window.XRExtras) {
      this.onLoading()
    } else {
      window.addEventListener('xrextrasloaded', this.onLoading)
    }
  }

  /**
   * Listener when xrextra is being loaded
   */
  onLoading = () => {
    if (window.XR8) {
      this.onXRLoaded()
    } else {
      window.XRExtras.Loading.showLoading({ onxrloaded: this.onXRLoaded })
    }
  }

  /**
   * Listener when xr loaded
   */
  onXRLoaded = () => {
    this.XR8 = window.XR8
    this.XRExtras = window.XRExtras

    this.MediaRecorder = new MediaRecorder(this)
    this.XRExtras.MediaRecorder.initMediaPreview() // Adds media preview and share
    this.XRExtras.MediaRecorder.configure({
      maxDurationMs: 4000,
      enableEndCard: false,
      coverImageUrl: '',
      endCardCallToAction: '',
      shortLink: '',
      includeSceneAudio: false,
      fileNamePrefix: 'ninjago-virtue-',
    })

    // Recorder customization
    const previewContainer = document.querySelector('#previewContainer')
    if (previewContainer) {
      const previewTopBar = previewContainer.getElementsByClassName('top-bar')[0]
      const previewBox = previewTopBar.getElementsByClassName('preview-box')[0]
      const previewImageView = document.querySelector('#imagePreview')
      const previewCloseBtn = document.querySelector('#closePreviewButton')
      const previewVideoView = document.querySelector('#videoPreview')
      const previewMuteBtn = document.querySelector('#toggleMuteButton')
      const previewBottomBar = previewContainer.getElementsByClassName('bottom-bar')[0]
      previewContainer.setAttribute(
        'style',
        `position: absolute; top: 100px; bottom: ${window.innerHeight - ((window.innerWidth / 4) * 5 + 40)}px;`,
      )
      previewBox.setAttribute(
        'style',
        'width: calc(100% - 20px); height: calc(100% - 20px); display: flex; justify-content: center;',
      )
      previewVideoView.setAttribute('style', 'width: 100%; height: 100%; object-fit: cover;')
      previewCloseBtn.setAttribute('style', 'position: absolute; padding: 0; left: 15px; top: 10px; width: 35px;')
      previewImageView.style.display = 'none'
      previewMuteBtn.style.display = 'none'
      previewBottomBar.style.display = 'none'
    }

    this.XR8.XrController.configure({
      disableWorldTracking: true,
      imageTargets: this.isLDC
        ? this.ldcBackdrops.map((backdrop) => backdrop.name)
        : this.models.map((model) => model.name),
    })
    this.XR8.addCameraPipelineModules([
      // Add camera pipeline modules.
      // Existing pipeline modules.
      this.XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
      this.XR8.XrController.pipelineModule(), // Enables SLAM tracking.
      this.XRExtras.AlmostThere.pipelineModule(), // Detects unsupported browsers and gives hints.
      this.XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
      // Custom pipeline modules.
      this.imageTargetPipelineModule(), // Draws a frame around detected image targets.
    ])

    // Open the camera and start running the camera run loop.
    this.XR8.run({ canvas: this.canvas })

    // Loading screen customization
    const loadContainer = document.querySelector('#loadingContainer')
    if (loadContainer) {
      loadContainer.style.top = '60px'
      loadContainer.style.bottom = `${window.innerHeight - ((window.innerWidth / 4) * 5 + 60)}px`
      const loadImage = document.querySelector('#loadImage')
      loadImage.style.display = 'none'
      const loadBackground = document.querySelector('#loadBackground')
      loadBackground.style.background = 'black'
      const loadImageContainer = document.querySelector('#loadImageContainer')
      loadImageContainer.style.display = 'none'
      const requestingCameraPermissions = document.querySelector('#requestingCameraPermissions')
      requestingCameraPermissions.style.display = 'none'
      const requestingCameraIcon = document.querySelector('#requestingCameraIcon')
      requestingCameraIcon.style.display = 'none'
    }
  }

  /**
   * Initialize imagetarget custom pipeline
   */
  imageTargetPipelineModule = () => {
    return {
      name: 'lego-ninjago-ar',
      onStart: this.onStart,
      onUpdate: this.onUpdate,
      onCanvasSizeChange: this.onCanvasSizeChange,
      onRender: this.onRender,
      onException: this.onException,
      xrScene: () => {
        return {
          scene: this.scene,
          camera: this.camera,
          renderer: this.render,
        }
      },
      listeners: [
        { event: 'reality.imagefound', process: this.showTarget },
        { event: 'reality.imageupdated', process: this.showTarget },
        { event: 'reality.imagelost', process: this.hideTarget },
      ],
    }
  }

  /**
   *   Grab a handle to the threejs scene and set the camera position on pipeline startup.
   */
  onStart = ({ canvas, canvasWidth, canvasHeight, GLctx }) => {
    this.started = true

    this.canvas = canvas
    this.canvasWidth = canvasWidth
    this.canvasHeight = canvasHeight
    this.glContext = GLctx

    this._onCameraReady()
    this.rendererSetup()
    this.sceneSetup()
    this.cameraSetup()
    this.lightSetup()
    this.initAssets()

    // Sync the xr controller's 6DoF position and camera paremeters with our scene.
    this.XR8.XrController.updateCameraProjectionMatrix({
      origin: this.camera.position,
      facing: this.camera.quaternion,
    })
  }

  /**
   * Tick
   */
  onUpdate = ({ processCpuResult }) => {
    if (!processCpuResult.reality) {
      return
    }

    const { rotation, position, intrinsics } = processCpuResult.reality

    for (let i = 0; i < 16; i++) {
      this.camera.projectionMatrix.elements[i] = intrinsics[i]
    }

    // Fix for broken raycasting in r103 and higher. Related to https://github.com/mrdoob/three.js/pull/15996
    // Note: camera.projectionMatrixInverse wasn't introduced until r96 so check before calling getInverse()
    if (this.camera.projectionMatrixInverse) {
      this.camera.projectionMatrixInverse.copy(this.camera.projectionMatrix).invert()
    }
    if (rotation) {
      this.camera.setRotationFromQuaternion(rotation)
    }
    if (position) {
      this.camera.position.set(position.x, position.y, position.z)
    }

    const delta = this.clock.getDelta()

    if (!this.assetReady) return

    if (this.currentModelIndex !== -1) {
      this.models[this.currentModelIndex]['mixer']?.update(delta)
      this.models[this.currentModelIndex]['particles'].forEach((particle) => particle?.update(delta))
    }
  }

  /**
   * Listener for canvas size change
   */
  onCanvasSizeChange = ({ canvasWidth, canvasHeight }) => {
    this.renderer.setSize(canvasWidth, canvasHeight)
  }

  /**
   * Render
   */
  onRender = () => {
    this.renderer.clearDepth() // important! clear the depth buffer
    this.renderer.render(this.scene, this.camera)
  }

  /**
   * Exception
   */
  onException = (error) => {
    // Only handle errors while running, not at startup.
    if (!this.started) return

    this._onRuntimeError(error)

    // Stop camera processing.
    this.XR8.pause()
    this.XR8.stop()
  }

  /**
   * Setup renderer
   */
  rendererSetup = () => {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      context: this.glContext,
      alpha: false,
      antialias: true,
    })
    this.renderer.autoClear = false
    this.renderer.outputEncoding = THREE.sRGBEncoding
    this.renderer.localClippingEnabled = true
    this.renderer.setClearColor(0xffffff, 0)
    this.renderer.setSize(this.canvasWidth, this.canvasHeight)
  }

  /**
   * Setup scene
   */
  sceneSetup = () => {
    this.scene = new THREE.Scene()
  }

  /**
   * Setup lights
   */
  lightSetup = () => {
    // Ambient light
    const ambLight = new THREE.AmbientLight(0x404040, 3)
    this.scene.add(ambLight)
    // Directional light
    const dirLight = new THREE.DirectionalLight(0xffffff, 1)
    this.scene.add(dirLight)
  }

  /**
   * Setup camera
   */
  cameraSetup = () => {
    this.camera = new THREE.PerspectiveCamera(
      60.0 /* initial field of view; will get set based on device info later. */,
      this.canvasWidth / this.canvasHeight,
      0.01,
      1000.0,
    )
    this.camera.position.set(0, 3, 0)

    this.scene.add(this.camera)
  }

  /**
   * Load assets
   */
  loadAssets = async () => {
    const gltfLoadPromises = this.models.map((model) => loadGLTF(model.model_path))
    const fireParticleLoadPromises = this.fireParticleSystem.loadAssets()
    const waterParticleLoadPromises = this.leftWaterParticleSystem.loadAssets()
    const energyBallParticleLoadPromises = this.energyBallParticleSystem.loadAssets()
    const lightningParticleLoadPromises = this.lightningParticleSystem.loadAssets()
    const handGlowParticleLoadPromises = this.leftHandGlowParticleSystem.loadAssets()
    const rockParticleLoadPromises = this.rockParticleSystem.loadAssets()
    const snowParticleLoadPromises = this.snowParticleSystem.loadAssets()
    const iceParticleLoadPromises = this.iceParticleSystem.loadAssets()

    try {
      this.gltfs = await Promise.all(gltfLoadPromises)
      this.fireParticleTextures = await Promise.all(fireParticleLoadPromises)
      this.waterParticleTextures = await Promise.all(waterParticleLoadPromises)
      this.energyBallParticleTextures = await Promise.all(energyBallParticleLoadPromises)
      this.lightParticleTextures = await Promise.all(lightningParticleLoadPromises)
      this.glowParticleTextures = await Promise.all(handGlowParticleLoadPromises)
      this.rockParticleGLTFs = await Promise.all(rockParticleLoadPromises)
      this.snowParticleTextures = await Promise.all(snowParticleLoadPromises)
      this.iceParticleGLTFs = await Promise.all(iceParticleLoadPromises)

      this.assetReady = true
    } catch (error) {
      alert('Asset loading failed')
      console.error(`Asset loading failed: ${error}`)
    }
  }

  /**
   * Load assets
   */
  initAssets = async () => {
    if (!this.assetReady) return

    const pmremGenerator = new THREE.PMREMGenerator(this.renderer)
    pmremGenerator.compileEquirectangularShader()

    try {
      const envMap = await loadHDR(ENV_PATH, pmremGenerator)
      // Static environment
      this.scene.environment = envMap
    } catch (error) {
      alert('Envmap loading failed')
      console.error(`Envmap loading failed: ${error}`)
    }

    this.fireParticleSystem.particleTextures = this.fireParticleTextures
    this.leftWaterParticleSystem.particleTextures = this.waterParticleTextures
    this.rightWaterParticleSystem.particleTextures = this.waterParticleTextures
    this.energyBallParticleSystem.particleTextures = this.energyBallParticleTextures
    this.lightningParticleSystem.particleTextures = this.lightParticleTextures
    this.leftHandGlowParticleSystem.particleTextures = this.glowParticleTextures
    this.rightHandGlowParticleSystem.particleTextures = this.glowParticleTextures
    this.rockParticleSystem.particleGLTFs = this.rockParticleGLTFs
    this.snowParticleSystem.particleTextures = this.snowParticleTextures
    this.iceParticleSystem.particleGLTFs = this.iceParticleGLTFs

    this.gltfs.forEach((gltf, index) => {
      const model = gltf.scene

      // Hide 3D model until image target is detected.
      model.visible = false

      // Init animation clips
      const mixer = new THREE.AnimationMixer(model)
      mixer.addEventListener('finished', () => {
        if (this.MediaRecorder.status === 'recording') return

        this.playAnimation(IDLE_CLIP)
      })
      const clips = gltf.animations
      const introClipAction = mixer.clipAction(THREE.AnimationClip.findByName(clips, INTRO_CLIP))
      introClipAction.loop = THREE.LoopOnce
      const idleClipAction = mixer.clipAction(THREE.AnimationClip.findByName(clips, IDLE_CLIP))
      idleClipAction.loop = THREE.LoopRepeat
      const signatureClipAction = mixer.clipAction(THREE.AnimationClip.findByName(clips, SIGNATURE_CLIP))
      signatureClipAction.loop = THREE.LoopOnce
      signatureClipAction.clampWhenFinished = true
      signatureClipAction.enabled = true

      const clipActions = {
        [INTRO_CLIP]: introClipAction,
        [IDLE_CLIP]: idleClipAction,
        [SIGNATURE_CLIP]: signatureClipAction,
      }

      this.models[index] = {
        ...this.models[index],
        model,
        particles: [],
        mixer,
        clipActions,
      }

      model.position.set(0.15, -1, 0)
      model.scale.set(1.3, 1.3, 1.3)
      this.modelContainer.add(model)
    })

    this.scene.add(this.modelContainer)
  }

  /**
   * Add particles
   */
  addParticles = async () => {
    if (this.currentModelIndex === -1) return

    const currentModel = this.models[this.currentModelIndex]
    let particles = []
    const particleDelay = currentModel['particle_delay']

    if (particleDelay) await wait(particleDelay * 1000)

    // Destructure partial models
    const rightHand = currentModel.model.getObjectByName('modelWrist_R')
    const leftHand = currentModel.model.getObjectByName('modelWrist_L')
    const root = currentModel.model.getObjectByName('modelRoot_M')

    switch (currentModel.name) {
      case 'kai':
        this.fireParticleSystem.init({
          scene: this.scene,
          camera: this.camera,
          modelContainer: this.modelContainer,
          source: leftHand,
          deltaPos: new THREE.Vector3(0.05, 0, 0),
          destWorldPos: new THREE.Vector3(this.camera.position.x, this.camera.position.y, this.camera.position.z + 0.5),
        })

        particles = [this.fireParticleSystem]
        break
      case 'lloyd':
        this.energyBallParticleSystem.init({
          scene: this.scene,
          camera: this.camera,
          modelContainer: this.modelContainer,
          source: root,
          deltaPos: new THREE.Vector3(0, -0.2, 0),
        })

        particles = [this.energyBallParticleSystem]
        break
      case 'nya':
        this.leftWaterParticleSystem.init({
          scene: this.scene,
          camera: this.camera,
          modelContainer: this.modelContainer,
          source: leftHand,
          deltaPos: new THREE.Vector3(0.05, 0, 0),
          destWorldPos: new THREE.Vector3(
            this.camera.position.x,
            this.camera.position.y,
            this.camera.position.z + 0.75,
          ),
        })
        this.rightWaterParticleSystem.init({
          scene: this.scene,
          camera: this.camera,
          modelContainer: this.modelContainer,
          source: rightHand,
          deltaPos: new THREE.Vector3(-0.05, 0, 0),
          destWorldPos: new THREE.Vector3(
            this.camera.position.x,
            this.camera.position.y,
            this.camera.position.z + 0.75,
          ),
        })

        particles = [this.leftWaterParticleSystem, this.rightWaterParticleSystem]
        break
      case 'jay':
        this.lightningParticleSystem.init({
          scene: this.scene,
          camera: this.camera,
          modelContainer: this.modelContainer,
          character: currentModel.model,
          source: root,
        })
        this.leftHandGlowParticleSystem.init({
          scene: this.scene,
          camera: this.camera,
          source: leftHand,
          deltaPos: new THREE.Vector3(0.05, 0, 0),
        })
        this.rightHandGlowParticleSystem.init({
          scene: this.scene,
          camera: this.camera,
          source: rightHand,
          deltaPos: new THREE.Vector3(-0.05, 0, 0),
        })

        particles = [this.lightningParticleSystem, this.leftHandGlowParticleSystem, this.rightHandGlowParticleSystem]
        break
      case 'cole':
        this.rockParticleSystem.init({
          scene: this.scene,
          camera: this.camera,
          modelContainer: this.modelContainer,
          character: currentModel.model,
        })

        particles = [this.rockParticleSystem]
        break
      case 'zane':
        this.snowParticleSystem.init({
          scene: this.scene,
          camera: this.camera,
          character: currentModel.model,
        })
        this.iceParticleSystem.init({
          scene: this.scene,
          camera: this.camera,
          modelContainer: this.modelContainer,
          character: currentModel.model,
        })

        particles = [this.snowParticleSystem, this.iceParticleSystem]
        break
    }

    this.models[this.currentModelIndex] = {
      ...currentModel,
      particles,
    }
  }

  /**
   * Dispose particles
   */
  disposeParticles = () => {
    if (this.currentModelIndex === -1 || !this.models[this.currentModelIndex]?.particles) return

    this.models[this.currentModelIndex].particles.forEach((particle) => particle.dispose())
    this.models[this.currentModelIndex].particles = []
  }

  /**
   * Play animation
   * @param clipName clip name
   */
  playAnimation = (clipName) => {
    if (this.currentModelIndex === -1) return
    if (this.currentClip) this.currentClip.stop()

    this.currentClip = this.models[this.currentModelIndex]['clipActions'][clipName]
    this.currentClip.play()
  }

  /**
   * Play sfx
   * @param play flag for indicating to play sfx
   */
  playSFX = (play) => {
    const currentSFX = this.models[this.currentModelIndex]?.virtue_sfx_player
    const successSFX = this.models[this.currentModelIndex]?.virtue_success_voice_player

    if (play) {
      try {
        successSFX.muted = true
        successSFX.play()
        currentSFX.play()
      } catch (error) {
        console.log(error)
      }
    } else if (currentSFX) {
      currentSFX.pause()
      currentSFX.currentTime = 0
      successSFX.pause()
      successSFX.currentTime = 0
    }
  }

  /**
   * Enable scan
   */
  enableScan = (enable) => {
    this.enableAR(false)
    this.scanStart = enable
  }

  /**
   * Enable AR
   */
  enableAR = (enable) => {
    this.arStart = enable
  }

  /**
   * Change active model index
   */
  setActiveModelIndex = (modelIndex) => {
    this.disableCurrentModel()
    this.currentModelIndex = modelIndex
    this.playingAnimation = false
  }

  disableCurrentModel = () => {
    if (!this.models[this.currentModelIndex]) return

    this.modelContainer.visible = false
    this.models[this.currentModelIndex].model.visible = false
  }

  /**
   * Places content over image target
   * @param detail target detail
   */
  showTarget = ({ detail }) => {
    if ((!this.scanStart && !this.arStart) || !this.assetReady) return

    this._onTargetDetect(detail.name)

    if (!this.arStart || !this.models[this.currentModelIndex]) return

    this.modelContainer.position.copy(detail.position)
    this.modelContainer.quaternion.copy(detail.rotation)
    this.modelContainer.scale.set(detail.scale, detail.scale, detail.scale)
    this.modelContainer.visible = true
    this.models[this.currentModelIndex].model.visible = true
    // TODO
    // this.models[this.currentModelIndex]['particles'].forEach((particle) => particle.show(true))

    // Play intro animation
    if (this.playingAnimation) return
    this.playingAnimation = true
    this.playAnimation(INTRO_CLIP)
  }

  /**
   * Hides the image frame when the target is no longer detected.
   * @param detail target detail
   */
  hideTarget = ({ detail }) => {
    if (!this.models[this.currentModelIndex]) return

    this.disableCurrentModel()
    // TODO
    // this.models[this.currentModelIndex]['particles'].forEach((particle) => particle.show(false))
  }

  /**
   *  Record button clicked
   */
  clickRecordBtn = () => {
    if (!this.MediaRecorder) return

    this.MediaRecorder.clickRecordBtn()
  }

  /**
   * Record button released
   */
  releaseRecordBtn = () => {
    if (!this.MediaRecorder) return

    this.MediaRecorder.releaseRecordBtn()
  }

  /**
   * Remove media preview
   */
  closeMediaPreview = () => {
    if (!this.MediaRecorder) return

    this.MediaRecorder.closePreview()

    if (this.XR8.isPaused()) this.XR8.resume()
  }

  /**
   * Reset
   */
  reset = () => {
    this.disableCurrentModel()
    this.currentModelIndex = -1
    this.playingAnimation = false
    this.enableScan(false)
    this.playSFX(false)

    this.closeMediaPreview()
  }

  /**
   * Dispose
   */
  dispose = () => {
    this.reset()

    this.models = MODELS

    if (this.XR8) {
      this.XR8.pause()
      this.XR8.stop()
    }

    window.removeEventListener('xrextrasloaded', this.onLoading)
  }
}

export default ARViewer
