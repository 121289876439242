import React, { memo, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import CommonText from '../common/CommonText'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import Container from '../common/ContentContainer'
import Amulet from '../common/Amulet'
import DragButton from '../common/DragButton'
import { Link, useHistory } from 'react-router-dom'
import { CAPTURE_PROGRESS, FINISH_EARLY, NAVIGATIONS, PLAY_AGAIN, PROCESSING } from '../../constants'
import useNavigation from '../../hooks/useNavigation'
import useCapture from '../../hooks/useCapture'
import * as variants from '../../style/variants'
import { motion } from 'framer-motion'
import TransitionContainer from '../common/TransitionContainer'
import { headline } from '../../style/variants'
import analytics from '../../utils/ga'
import { ACTION_CONFIRM_SLIDER } from '../../constants/analytics'

const FinishEarly = () => {
  const { t } = useAppContext()

  const history = useHistory()
  const { goTo } = useNavigation()
  const { totalProgress } = useCapture()

  const handleSubmit = useCallback(() => {
    goTo(history, PROCESSING)
    analytics.trackEvent({ eventCategory: NAVIGATIONS[FINISH_EARLY].ga_category, action: ACTION_CONFIRM_SLIDER })
  }, [goTo, history])
  return (
    <Content>
      <StyledAmulet totalProgress={totalProgress} showProgress />
      <Overlay>
        <CommonText
          color={color.gold}
          fontFamily={KUNOICHI}
          fontSize="xl"
          shadow="deep-shadow"
          type="h2"
          as={TransitionContainer}
          preset="headline"
          duration={0.4}
          delay={0.5}
        >
          {t('skip_sure')}
        </CommonText>
        <CommonText
          fontSize="sm"
          shadow="deep-shadow"
          lowercase
          as={TransitionContainer}
          type="p"
          duration={0.4}
          preset="slideDownUp"
          delay={0.8}
        >
          {t('skip_text')}
        </CommonText>
        <DragButton onSubmit={handleSubmit} delay={1.1} label="finish_early_drag" />
        <Link to={NAVIGATIONS[CAPTURE_PROGRESS].path}>
          <CommonText
            fontSize="sm"
            lowercase
            shadow="deep-shadow"
            preset="slideDownUp"
            type="label"
            as={TransitionContainer}
            duration={0.4}
            delay={1.4}
          >
            {t('cancel')}
          </CommonText>
        </Link>
      </Overlay>
    </Content>
  )
}

export default memo(FinishEarly)

const Content = styled(Container)`
  justify-content: center;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 48%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: flex-start;

  align-items: center;
  flex-direction: column;
  ${CommonText}:nth-child(2) {
    margin-top: 10px;
    max-width: 80%;
  }

  > div:nth-child(3) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
`

const StyledAmulet = styled(Amulet)`
  opacity: 0.16;
`
