import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import color from '../../style/color'
import CommonText from './CommonText'
import TransitionContainer from './TransitionContainer'
import { slideDownUp, slideUpDown } from '../../style/variants'
import { useAppContext } from '../../context/AppContext'
import ReactHtmlParser from 'react-html-parser'

const textVariants = {
  show: {
    opacity: 1,
  },
  hide: {
    opacity: 0,
  },
}

const ColorButton = ({ onSubmit, label, buttonColor = color.red, disabled, ...props }) => {
  const { t } = useAppContext()

  return (
    <Container {...props} onClick={!disabled ? onSubmit : undefined}>
      <ButtonPlate buttonColor={buttonColor} disabled={disabled}>
        <Text as={motion.div} fontSize="xs" disabled={disabled}>
          {ReactHtmlParser(t(label))}
        </Text>
      </ButtonPlate>
    </Container>
  )
}

ColorButton.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  disabled: PropTypes.bool,
}

export default ColorButton

const Text = styled(CommonText)(
  ({ disabled }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    width: 100%;
    z-index: 8;
    transition: opacity 0.3s;
    opacity: ${!disabled ? 1 : 0.3};

    span {
      text-transform: none;
      font-size: 11px;
    }
  `,
)

const Container = styled.div(
  () => css`
    max-width: 300px;
    width: 100%;
    height: 60px;
    position: relative;
  `,
)
const ButtonPlate = styled(motion.div)(
  ({ buttonColor, disabled }) => css`
    width: 100%;
    height: 100%;
    position: relative;
    background: ${!disabled ? buttonColor : color.gray};
    transition: background 0.3s;
    border-radius: 4px;
  `,
)
