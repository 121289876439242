import styled from 'styled-components'
import color from '../../style/color'
import { SANS_SERIF } from '../../style/type'
import { motion } from 'framer-motion'

const CommonText = styled(motion.p)`
  position: relative;
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : SANS_SERIF)};
  color: ${({ color: propsColor }) => (propsColor ? propsColor : color.white)};
  text-transform: ${({ lowercase }) => (lowercase ? 'auto' : 'uppercase')};
  text-shadow: ${({ shadow }) => {
    let shadowCSS

    switch (shadow) {
      case 'deep-shadow':
        shadowCSS =
          '-8px 7px 1px #000, -6px 7px 1px #000, -4px 6px 1px #000, -2px 5px 1px #000, 0px 4px 1px #000, 1px 3px 1px #000, 2px 2px 1px #000, 3px 2px 1px #000, 4px 1px 1px #000, -10px 7px 1px #000, -10px 6px 1px #000, -10px 5px 1px #000, -9px 4px 1px #000, -8px 3px 1px #000, -7px 2px 1px #000, -5px 1px 1px #000, -4px 0px 1px #000, -3px -1px 1px #000, 2px -2px 1px #000, 1px -3px 1px #000, 0px -4px 1px #000, 1px -5px 1px #000'
        break
      case 'shallow-shadow':
        shadowCSS =
          '-4px 3px 1px #000, -3px 4px 1px #000, -2px 3px 1px #000, -1px 3px 1px #000, 0px 2px 1px #000, 1px 2px 1px #000, 2px 2px 1px #000, 3px 2px 1px #000, 4px 1px 1px #000, -5px 2px 1px #000, -5px 3px 1px #000, -5px 3px 1px #000, -4px 2px 1px #000, -4px 1px 1px #000, -3px 1px 1px #000, -3px 1px 1px #000, -2px 0px 1px #000, -3px -1px 1px #000, 2px -2px 1px #000, 1px -3px 1px #000, 0px -2px 1px #000, 1px -2px 1px #000'
        break
      case 'simple-shadow':
        shadowCSS = '0px 0px 5px #000, 0px 0px 7px #000'
        break
      default:
        shadowCSS = 'none'
        break
    }

    return shadowCSS
  }};
  font-size: ${({ fontSize }) => (fontSize ? `var(--font-size-${fontSize})` : 'var(--font-size-xl)')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'bold')};
  line-height: 120%;
  white-space: pre-line;
`

export default CommonText
