import React, { createContext, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import useNavigation from '../hooks/useNavigation'
import '../translations/i18n'
import breakpoints from '../constants/breakpoints'
import { modelsReducer } from '../hooks/useCapture'

export const AppContext = createContext(null)

const AppProvider = ({ children }) => {
  const arViewer = useRef()
  const [navBtnVisible, setNavBtnVisible] = useState(true)
  const [customNavBtn, setCustomNavBtn] = useState(null)
  const [footerLabelBtnVisible, setFooterLabelBtnVisible] = useState(true)

  //Nav State
  const [prevViewID, setPrevViewID] = useState(null)
  const [curViewID, setCurViewID] = useState(null)
  const [nextViewID, setNextViewID] = useState(null)

  //Capture State

  const { modelState, consentState, userVideo } = useMemo(() => {
    let modelState = []
    let consentState = false
    let userVideo = false
    try {
      const sessionModels = JSON.parse(sessionStorage.getItem('sessionProgress'))
      if (sessionModels) modelState = sessionModels
    } catch (e) {
      console.warn('sessionModels parse error bad sessionStorate json')
    }
    try {
      const sessionConsent = JSON.parse(sessionStorage.getItem('consentState'))
      if (sessionConsent) consentState = sessionConsent
    } catch (e) {
      console.warn('sessionConsent parse error bad sessionStorate json')
    }

    try {
      const sessionVideo = JSON.parse(localStorage.getItem('userVideo'))
      if (sessionVideo) userVideo = sessionVideo
    } catch (e) {
      console.warn('sessionVideo parse error bad sessionStorate json')
    }

    return { modelState, consentState, userVideo }
  }, [])

  const [userConsent, setUserConsent] = useState(consentState || 0)
  const [deniedConsent, setDeniedConsent] = useState(false)
  const [models, dispatch] = useReducer(modelsReducer, modelState)
  const [userFinalVideoSrc, setUserFinalVideoSrc] = useState(userVideo)

  const [retake, setRetake] = useState()
  const [currentSegment, setCurrentSegment] = useState()

  useEffect(() => {
    sessionStorage.setItem('sessionProgress', JSON.stringify(models))
  }, [models, models.length])

  useEffect(() => {
    sessionStorage.setItem('consentState', JSON.stringify(userConsent))
  }, [userConsent])

  useEffect(() => {
    localStorage.setItem('userVideo', JSON.stringify(userFinalVideoSrc))
  }, [userFinalVideoSrc])

  // Localization
  const {
    t,
    i18n: { language },
  } = useTranslation()
  // Breakpoints
  const isMedium = useMediaQuery({ minWidth: breakpoints.md })
  const isLarge = useMediaQuery({ minWidth: breakpoints.lg })
  const isXLarge = useMediaQuery({ minWidth: breakpoints.xl })
  const isXXLarge = useMediaQuery({ minWidth: breakpoints.xxl })

  const value = useMemo(
    () => ({
      captureState: {
        models,
        dispatch,
        retake,
        setRetake,
        currentSegment,
        setCurrentSegment,
        userFinalVideoSrc,
        setUserFinalVideoSrc,
      },
      media: {
        isMedium,
        isLarge,
        isXLarge,
        isXXLarge,
      },
      navigation: {
        curViewID,
        nextViewID,
        setPrevViewID,
        setCurViewID,
        setNextViewID,
      },
      navBtnVisible,
      arViewer,
      t,
      currentLanguage: language,
      prevViewID,
      setNavBtnVisible,
      customNavBtn,
      setCustomNavBtn,
      footerLabelBtnVisible,
      setFooterLabelBtnVisible,
      userConsent,
      setUserConsent,
      deniedConsent,
      setDeniedConsent,
    }),
    [
      models,
      retake,
      currentSegment,
      userFinalVideoSrc,
      isMedium,
      isLarge,
      isXLarge,
      isXXLarge,
      curViewID,
      nextViewID,
      navBtnVisible,
      t,
      prevViewID,
      customNavBtn,
      footerLabelBtnVisible,
      userConsent,
      deniedConsent,
    ],
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

AppProvider.propTypes = {
  children: PropTypes.node,
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider
