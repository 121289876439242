const preloadImages = async (srcArr) => {
  const promises = await srcArr.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image()

      img.src = src
      img.onload = resolve()
      img.onerror = reject()
    })
  })

  return Promise.all(promises)
}

const preloadAudios = async (srcArr) => {
  const promises = await srcArr.map((src) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(src)

      audio.load()
      audio.oncanplaythrough = resolve()
      audio.onerror = reject()
    })
  })

  return Promise.all(promises)
}

export { preloadImages, preloadAudios }
