import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import CommonText from '../common/CommonText'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import Container from '../common/ContentContainer'
import { INTRO } from '../../constants'
import Scroll from './intro/Scroll'

const Intro = () => {
  const { t } = useAppContext()

  const {
    content,
    header: { ninjago, title },
  } = t('intro', { returnObjects: true })

  return (
    <Content key={`section_${INTRO}`}>
      <Scroll>
        <CommonText fontFamily={KUNOICHI} color={color.red} fontSize="sm" shadow="shallow-shadow">
          {ninjago}
        </CommonText>
        <CommonText fontFamily={KUNOICHI} color={color.gold} fontSize="lg" shadow="shallow-shadow">
          {title}
        </CommonText>
        {content.map((text) => (
          <ScrollText key={text} color="#000" fontFamily={KUNOICHI} fontSize="lg">
            {text}
          </ScrollText>
        ))}
      </Scroll>
    </Content>
  )
}

export default memo(Intro)

const ScrollText = styled(CommonText)(({ theme }) => css``)

const Content = styled(Container)`
  justify-content: space-around;
  padding: 100px 0 100px 0;

  ${ScrollText} {
    padding: 0 15px;
  }
`
