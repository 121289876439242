import React from 'react'
import { createGlobalStyle, css } from 'styled-components'
import { AppContext, useAppContext } from '../context/AppContext'
import { SANS_SERIF } from './type'
import fonts from '../style/fonts'
import vars from '../style/vars'

const GlobalStyle = createGlobalStyle`
  ${({ direction = 'ltr' }) => css`
    ${fonts};
    ${vars};

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
      box-sizing: border-box;
    }

    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
      display: block;
    }

    body {
      font-family: ${SANS_SERIF}, sans-serif;
      overflow: hidden;
      direction: ${direction};
    }

    html,
    body,
    #root {
      width: 100%;
      height: 100%;
      user-select: none;
    }

    a,
    a:hover,
    a:visited,
    a:active {
      color: inherit;
      text-decoration: none;
      display: block;
    }

    * {
      -webkit-tap-highlight-color: transparent;
    }

    sup {
      position: relative;
      display: inline;
      font-size: 6px;
      top: -3px;
    }

    .txt-small {
      font-size: 8px;
    }
  `}
`

export default ({ direction }) => (
  <AppContext.Consumer>
    {() => {
      return <GlobalStyle direction={direction} />
    }}
  </AppContext.Consumer>
)
