import React, { memo } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import CommonText from '../common/CommonText'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import assets from '../../constants/assets'

const { rotateBg, rotateIcon } = assets.images

const MobileLandscape = () => {
  const { t } = useAppContext()

  return (
    <Container>
      <RotateIcon src={rotateIcon}></RotateIcon>
      <CommonText color={color.gold} fontFamily={KUNOICHI} fontSize="lg" shadow="deep-shadow">
        {t('rotate_mobile')}
      </CommonText>
    </Container>
  )
}

export default memo(MobileLandscape)

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: center / cover no-repeat url(${rotateBg});
`
const RotateIcon = styled.img`
  width: 50px;
  height: auto;
  margin: 0 0 10px 0;
`
