import * as THREE from 'three'
import LinearSpline from './linearSpline'
import PointParticleSystem from './pointParticleSystem'

class HandGlowParticleSystem extends PointParticleSystem {
  constructor() {
    super()
    this.particlePaths = ['./assets/textures/lightning_hand_glow.png']
  }

  /**
   * Initialize splines
   */
  initSplines = () => {
    this.alphaSpline = new LinearSpline((t, a, b) => {
      return a + t * (b - a)
    })
    this.alphaSpline.addPoint(0.0, 0.0)
    this.alphaSpline.addPoint(0.1, 1.0)
    this.alphaSpline.addPoint(0.6, 1.0)
    this.alphaSpline.addPoint(1.0, 0.0)

    this.colourSpline = new LinearSpline((t, a, b) => {
      const c = a.clone()
      return c.lerp(b, t)
    })
    this.colourSpline.addPoint(0.0, new THREE.Color(0xffffff))
    this.colourSpline.addPoint(0.2, new THREE.Color(0xffffff))

    this.sizeSpline = new LinearSpline((t, a, b) => {
      return a + t * (b - a)
    })
    this.sizeSpline.addPoint(0.0, 1.0)
    this.sizeSpline.addPoint(1.0, 5.0)
  }

  /**
   * Add particles
   * @param timeElapsed time consumed for frame rendering
   */
  addParticles = (timeElapsed) => {
    if (!this.gdfsghk) {
      this.gdfsghk = 0.0
    }
    this.gdfsghk += timeElapsed
    const n = Math.floor(this.gdfsghk * 5.0)
    this.gdfsghk -= n / 75.0

    for (let i = 0; i < n; i++) {
      const life = (Math.random() * 0.2 + 0.2) * 2.0

      this.particles.push({
        index: Math.floor(Math.random(this.particlePaths.length)),
        position: new THREE.Vector3(
          (Math.random() * 2 - 1) * 0.01,
          (Math.random() * 2 - 1) * 0.01,
          (Math.random() * 2 - 1) * 0.01,
        ),
        size: (Math.random() * 0.5 + 0.5) * 0.1,
        colour: new THREE.Color(),
        alpha: 1.0,
        life: life,
        maxLife: life,
        rotation: Math.random() * 2.0 * Math.PI,
        velocity: new THREE.Vector3(Math.random() * 0.01, Math.random() * 0.01, Math.random() * 0.01),
      })
    }
  }
}

export default HandGlowParticleSystem
