import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import CommonText from '../common/CommonText'
import { motion } from 'framer-motion'
import color from '../../style/color'
import { KUNOICHI } from '../../style/type'
import * as variants from '../../style/variants'

const EllipsesText = ({ children, ...rest }) => {
  return (
    <TextContainer {...rest}>
      <StyledCommonText
        color={color.gold}
        fontFamily={KUNOICHI}
        fontSize="xl"
        shadow="deep-shadow"
        as={motion.h2}
        key="p1"
        variants={variants.headline}
        initial="initial"
        animate={{ ...variants.headline.animate, transition: { type: 'tween', duration: 0.75, delay: 0.2 } }}
        exit="initial"
        textAlign="left"
      >
        {children}
        <Ellipses />
      </StyledCommonText>
    </TextContainer>
  )
}

EllipsesText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(EllipsesText)

const TextContainer = styled(motion.div)`
  max-width: 80vw;
`
const StyledCommonText = styled(CommonText)`
  width: 160px;
  white-space: nowrap;
`

const ellipse = keyframes`
  to {
    width: 26px;
  }
`

const Ellipses = styled.span`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ${ellipse} steps(4, end) 900ms infinite;
    animation: ${ellipse} steps(4, end) 900ms infinite;
    content: "\\2026"; /* ascii code for the ellipsis character */
    width: 0;
`
