export const FALLBACK = `-apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial,
    sans-serif`

export const LEGORegular_NAME = 'LEGORegular'
export const SANS_SERIF = `${LEGORegular_NAME}, ${FALLBACK}`

export const KUNOICHI_NAME = 'Kunoichi'
export const KUNOICHI = `${KUNOICHI_NAME}, ${SANS_SERIF}`

export const CHALET_NEWYORK_SIXTY_NAME = 'ChaletNewYorkSixty'
export const LEGO_REGULAR = `${CHALET_NEWYORK_SIXTY_NAME}, ${SANS_SERIF}`

// px
const fontSizes = {
  xxs: 10,
  xs: 13,
  sm: 16,
  md: 18,
  lg: 24,
  xl: 32,
  xxl: 48,
  xxxl: 58,
}

export const sizeKeys = Object.keys(fontSizes)
export const sizeValues = Object.values(fontSizes)

export default fontSizes
