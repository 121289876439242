import ChaletNewYorkSixty from '../assets/fonts/Chalet-NewYorkSixty.otf'
import Kunoichi from '../assets/fonts/Kunoichi.otf'
import LEGORegular from '../assets/fonts/LEGO Chalet 60 Regular.otf'
import { css } from 'styled-components'

const fonts = css`
  @font-face {
    font-family: 'ChaletNewYorkSixty';
    src: url(${ChaletNewYorkSixty}) format('opentype');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Kunoichi';
    src: url(${Kunoichi}) format('opentype');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'LEGORegular';
    src: url(${LEGORegular}) format('opentype');
    font-style: normal;
    font-weight: 400;
  }
`

export default fonts
