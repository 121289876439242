import styled from 'styled-components'
import PropTypes from 'prop-types'
import TransitionContainer from './TransitionContainer'
import { motion } from 'framer-motion'
import { sectionContainer } from '../../style/variants'

const ContentContainer = ({ children, ...props }) => {
  return (
    <Container variants={sectionContainer} initial="initial" animate="enter" exit="exit" {...props}>
      {children}
    </Container>
  )
}
ContentContainer.propTypes = {
  children: PropTypes.node,
}

const Container = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 130px 0 180px 0;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default ContentContainer
