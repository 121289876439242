import React, { memo, useEffect } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import EllipsesText from '../common/EllipsesText'
import Container from '../common/ContentContainer'
import useNavigation from '../../hooks/useNavigation'
import { CAPTURE_PROGRESS, PREVIEW, PROCESSING, SUBMITTING_SEGMENT } from '../../constants'
import { useHistory } from 'react-router-dom'
import useCapture from '../../hooks/useCapture'
import Amulet from '../common/Amulet'
import { motion } from 'framer-motion'
import { amuletPulse } from '../../style/variants'
import ReactHtmlParser from 'react-html-parser'

const Processing = () => {
  const { t } = useAppContext()
  const history = useHistory()
  const { goTo, curViewID } = useNavigation()
  const {
    submitSegment,
    createVideo,
    requestState: { loading, error, complete },
    models,
  } = useCapture()

  useEffect(() => {
    if (!loading && !complete && !error) {
      switch (curViewID) {
        case SUBMITTING_SEGMENT:
          submitSegment()
          break
        case PROCESSING:
          createVideo()
          break
      }
    }
  }, [complete, createVideo, curViewID, error, loading, submitSegment])

  useEffect(() => {
    if (complete && !loading) {
      switch (curViewID) {
        case SUBMITTING_SEGMENT:
          goTo(history, CAPTURE_PROGRESS)
          break
        case PROCESSING:
          goTo(history, PREVIEW)
          break
      }
    } else if (error) {
      /*
       * TODO
       *  error behavior?
       * */
    }
  }, [complete, curViewID, error, goTo, history, loading])

  return (
    <Content>
      <EllipsesText>{ReactHtmlParser(t('processing'))}</EllipsesText>
      <AmuletContainer variants={amuletPulse} initial="initial" animate="enter" exit="exit">
        <Amulet showProgress totalProgress={models.length} staticProgress />
      </AmuletContainer>
    </Content>
  )
}

export default memo(Processing)

const AmuletContainer = styled(motion.div)`
  margin: 2% 0 4%;

  @media (min-height: 635px) {
    margin: 5% 0 7%;
  }

  > * {
    margin: 0 !important;
  }
`

const Content = styled(Container)`
  justify-content: center;
`
