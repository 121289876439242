import { useCallback, useState } from 'react'
import { NAVIGATIONS } from '../constants'
import { AppContext, useAppContext } from '../context/AppContext'

const useNavigation = () => {
  const {
    navigation: { prevViewID, setPrevViewID, curViewID, setCurViewID, nextViewID, setNextViewID },
  } = useAppContext()

  // Initialize current view status
  const initView = useCallback(
    (initialViewID) => {
      setPrevViewID(initialViewID - 1)
      setCurViewID(initialViewID)
      setNextViewID(initialViewID + 1)
    },
    [setCurViewID, setNextViewID, setPrevViewID],
  )

  // Go to next view
  const goTo = useCallback(
    (history, viewID) => {
      const { path } = NAVIGATIONS[viewID]
      history.push(path)

      setPrevViewID(viewID - 1)
      setCurViewID(viewID)
      setNextViewID(viewID + 1)
    },
    [setCurViewID, setNextViewID, setPrevViewID],
  )

  // Go to next view
  const goToNext = useCallback(
    (history) => {
      const { path } = NAVIGATIONS[nextViewID]
      history.push(path)

      setPrevViewID(curViewID)
      setCurViewID(nextViewID)
      setNextViewID(nextViewID + 1)
    },
    [curViewID, nextViewID, setCurViewID, setNextViewID, setPrevViewID],
  )

  return { prevViewID, curViewID, nextViewID, goToNext, goTo, initView }
}

export default useNavigation
