import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { pulse } from '../../style/variants'
import { ARROW_DOWN, ARROW_UP, ARROW_LEFT, ARROW_RIGHT } from '../../constants/index'
import assets from '../../constants/assets'

const { arrowHintIcon } = assets.images

const PulseArrow = ({ show = true, direction = ARROW_DOWN, stroke = true, colorful = true, ...rest }) => {
  return (
    <Container variants={pulse} initial="initial" animate={show ? 'animate' : 'exit'} direction={direction} {...rest}>
      <ArrowHintIcon src={arrowHintIcon} stroke={stroke ? 1 : 0} colorful={colorful ? 1 : 0} />
      <ArrowHintIcon src={arrowHintIcon} stroke={stroke ? 1 : 0} colorful={colorful ? 1 : 0} />
    </Container>
  )
}

PulseArrow.propTypes = {
  show: PropTypes.bool,
  direction: PropTypes.number,
  stroke: PropTypes.bool,
  colorful: PropTypes.bool,
}

export default memo(PulseArrow)

const Container = styled(motion.div)(
  () => css`
    position: absolute;
    width: 50px;
    height: auto;
    display: flex;
    justify-content: center;
    pointer-events: none;
    transform: rotate(
      ${({ direction }) => {
        let rotation
        switch (direction) {
          case ARROW_DOWN:
            rotation = '90deg'
            break
          case ARROW_UP:
            rotation = '-90deg'
            break
          case ARROW_LEFT:
            rotation = '180deg'
            break
          case ARROW_RIGHT:
            rotation = '0deg'
            break
        }

        return rotation
      }}
    );
  `,
)

const ArrowHintIcon = styled.img`
  width: 14px;
  margin: 2.5px;
  filter: ${({ stroke }) =>
      stroke
        ? 'drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black) drop-shadow(1px -1px 0 black) drop-shadow(-1px 1px 0 black)'
        : ''}
    ${({ colorful }) => (colorful ? ' saturate(3)' : ' saturate(0) brightness(10)')};
`
