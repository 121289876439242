import styled, { css } from 'styled-components'
import TransitionContainer from './TransitionContainer'

const Background = styled(TransitionContainer)(
  ({ background }) => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    background-image: url('${background}');
  `,
)

export default Background
