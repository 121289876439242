export const TRANSLATIONS_DE = {
  browser_change: 'Nutzt dieses Erlebnis am besten über Safari.',
  your_journey: 'Die Reise',
  is_loading: 'wird geladen',
  consent:
    'Du möchtest, dass deine Kinder online sicher sind, das möchten wir auch. Sag uns bitte in welchem Jahr du geboren bist.',
  consent_accept_age: 'Bestätigen',
  consent_accept: 'Zustimmen',
  consent_deny: 'Ablehnen',
  consent_agree:
    'Ich bin damit einverstanden, dass Aufnahmen von mir und/oder meinem Kind gemacht werden, um ein Video zu erstellen, welches uns beim Sammeln der goldenen Fragmente in der NINJAGO Mission zeigt. Ich verstehe, dass ich meine Zustimmung jederzeit widerrufen kann. Wenn ein Kind in diesem Video zu sehen ist, bestätige ich, dass ich ein Elternteil oder der Erziehungsberechtigte für dieses Kind bin. Für weitere Informationen <a href="/legal-de.html" target="_blank"> hier klicken.</a>',
  consent_deny_age_page:
    'Es tut uns leid, du musst ein Elternteil oder Erziehungsberechtigen bei dir haben, der dir bei diesem Erlebnis hilft.',
  consent_deny_consent_page:
    'Es tut uns leid, aber für dieses Erlebnis müssen wir Videos aufnehmen und speichern, um euer digitales Erlebnis zu erstellen. Bitte besucht die Website Ninjago.com, um eure Reise fortzusetzen.',
  terms_service:
    '<span class="txt-small">Durch Klicken erklärt du dich mit den <br/><a href="https://www.lego.com/de-de/page/terms-and-conditions" target="_blank">Nutzungsbedingungen</a>,<br/>der <a href="https://www.lego.com/de-de/legal/notices-and-policies/privacy-policy/" target="_blank">Datenschutzrichtlinie</a> und<br/>der <a href="https://www.lego.com/de-de/cookie-policy" target="_blank">Cookie-Richtlinie einverstanden.</a></span>',
  rotate_mobile: 'Dreht das Smartphone',
  intro: {
    header: {
      ninjago: 'NINJAGO® Mission:',
      title: "Meister Wu's goldenes Amulett",
    },
    content: [
      'Der böse Lord Garmadon hat Meister Wu’s goldenes Amulett in 6 Stücke zerschmettert und diese an verschiedensten Orten auf der ganzen Welt versteckt.',
      'Meister Wu benötigt eure Hilfe, um die Fragmente seines goldenen Amuletts zu finden und das NINJAGO® Vermächtnis zu schützen.',
      'Dies ist eure Mission.',
    ],
  },
  runtime_error: 'Etwas ist schief gelaufen',
  no_virtue_text: 'Geht zu einem anderen Hintergrund, um die letzten 3 goldenen Fragmente zu finden.',
  retake: 'Wiederholen',
  done: 'Weiter',
  finish_early: 'Nein, danke. Wir sind fertig.',
  finish_early_drag: 'Zum Beenden mit<br/>dem Finger ziehen.',
  success: 'Erfolg!',
  quest_complete: 'Die Mission ist beendet!',
  success_text: 'Fahrt fort, um das nächste goldene Fragment zu entsperren.',
  quest_complete_text:
    'Glückwunsch! Ihr habt alle Fragmente des goldenen Amuletts gesammelt und eure Mission erfolgreich abgeschlossen.<br/>Klickt auf die Schaltfläche „Herunterladen“, um euer Video zu erhalten.',
  play_again: 'Erneut spielen?',
  start_over: 'Beginnt von vorne, um die Rollen zu wechseln oder neue Posen aufzunehmen.',
  processing: 'Hochladen',
  processing_video: 'Das Video wird fertiggestellt.',
  skip_sure: 'Seid ihr sicher?',
  skip_text:
    'Ihr habt noch nicht alle Fragmente des goldenen Amuletts gesammelt. Meister Wu braucht noch immer eure Hilfe. Ihr werdet zwar ein Video der NINJAGO® Mission erhalten, es wird jedoch nicht vollständig sein.',
  cancel: 'Abbrechen',
  tap: 'Tippen ',
  download_video: ' um das Video herunterzuladen',
  home_small_title: 'NINJAGO® Mission:',
  home_main_title: "Meister Wu's goldenes Amulett",
  desktop_small_title:
    'Die NINJAGO Mission, ein virtuelles Erlebnis, wurde für Smartphones entwickelt. Besucht das LEGOLAND oder das LEGOLAND Discovery Center in eurer Nähe, um daran teilzunehmen. ',
  desktop_main_title: 'Wie immer, sei ein Ninja!',
  intro_title: "Meister Wu's goldenes Amulett",
  guide_title: 'Findet die goldenen Fragmente!',
  guide_text:
    'Entsperrt die goldenen Fragmente für jede Eigenschaft, indem ihr ein Foto mit dem abgebildeten Ninja macht.',
  guide_luck: 'Viel Glück!',
  camera_access: 'Bitte erlaubt den Zugriff auf die Kamera, um fortzufahren.',
  ar_instruction: 'Richtet die Kamera auf die einzelnen Szenen, um die goldenen Fragmente zu entsperren.',
  tap_start: 'Mit dem Finger tippen und<br/>gedrückt halten, um zu beginnen.',
  virtue_texts: {
    kai: ['Tapferkeit ist dein höchstes Gut. Sei beflügelt von Kais Mut: Bekämpfe das Böse mit Feuersglut!'],
    lloyd: [
      'Erst nach links sollst den Blick du heben, dann sogleich nach rechts daneben: Nun meditiere und beginne zu schweben',
    ],
    nya: ['Ganz nach oben geht dein Blick, dann nach unten noch ein Stück: Nun hab Spaß und tanz wie verrückt! '],
    jay: ['Als guter Ninja sei stets aufrichtig, Verbeuge dich tief und sprich: „Das Richtige zu tun gelobe ich!“'],
    cole: [
      'Wer Coles Geschicklichkeit meistern will, der steh kerzengerade und still: Das Goldene Fragment sei dein Ziel!',
    ],
    zane: [
      'Nun ist einmal jemand anders dran: Frag deine Freunde, wer tanzen kann. Nur was du teilst, bringt dich voran!',
    ],
  },
  endlink:
    'Setzt eure NINJAGO<sup>®</sup> Reise auf <br/><a href="https://www.ninjago.com" target="_blank">ninjago.com</a> fort.',
  text_direction: 'ltr',
}
